/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkmark': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" _fill="#00C64F"/>'
  }
})
