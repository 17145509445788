import Login from "@/pages/Access/Login"
import Registration from "@/pages/Access/Registration"
import ForgottenPassword from "@/pages/Access/ForgottenPassword"
import ChangePassword from "@/pages/Access/ChangePassword"
import * as routers from "@/utils/routers"
import Vue from "vue"
import Components from "@/components/Components"

const getView = (viewName) => () => import(`@/pages${viewName}`)
const LAYOUT = {
  MAIN: "main",
  BLANK: "blank",
  NONE: false,
}
const DEBUG = process.env.NODE_ENV === "development"

export const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: (to, from, next) => {
      const isGuest = !localStorage.getItem("auth_token_default")
      if (!isGuest) {
        return next("/dashboard")
      }
      return next()
    },
    meta: {
      title: {
        en: "Log in",
      },
      layout: LAYOUT.BLANK,
    },
  },
  {
    path: "/login/:social_provider",
    name: "login",
    component: Login,
    meta: {
      title: {
        en: "Log in with google",
      },
      layout: LAYOUT.BLANK,
    },
  },
  {
    path: "/registration",
    name: "registration",
    component: Registration,
    beforeEnter: (to, from, next) => {
      const isGuest = !localStorage.getItem("auth_token_default")
      if (!isGuest) {
        return next("/dashboard")
      }
      return next()
    },
    meta: {
      title: {
        en: "Registration",
      },
      layout: LAYOUT.BLANK,
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgottenPassword,
    beforeEnter: (to, from, next) => {
      const isGuest = !localStorage.getItem("auth_token_default")
      if (!isGuest) {
        return next("/dashboard")
      }
      return next()
    },
    meta: {
      title: {
        en: "Forgotten password",
      },
      layout: LAYOUT.BLANK,
    },
  },
  {
    path: "/change-password",
    name: "change-password",
    beforeEnter: (to, from, next) => {
      const isGuest = !localStorage.getItem("auth_token_default")
      if (!isGuest) {
        return next("/dashboard")
      }
      return next()
    },
    component: ChangePassword,
    meta: {
      title: {
        en: "Change password",
      },
      layout: LAYOUT.BLANK,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: getView("/Dashboard/Index"),
    meta: {
      title: {
        en: "Dashboard",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/support",
    name: "support",
    component: getView("/Support"),
    meta: {
      title: {
        en: "Support",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/support/create",
    name: "createSupportTicket",
    component: getView("/CreateSupportTicket"),
    meta: {
      title: {
        en: "Create support ticket",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/organization",
    name: "organizations",
    component: getView("/Organization/Organizations"),
    meta: {
      title: {
        en: "Business accounts list",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/organization/add",
    name: "createOrganization",
    component: getView("/Organization/CreateOrganization"),
    meta: {
      title: {
        en: "Create business accounts",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/organization/detail/:id",
    name: "detailOrganization",
    component: getView("/Organization/Detail"),
    meta: {
      title: {
        en: "Business account detail",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/organization/:id",
    name: "editOrganization",
    component: getView("/Organization/EditOrganization"),
    meta: {
      title: {
        en: "Edit business account",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/knowledge-base",
    name: "knowledgeBase",
    component: getView("/KnowledgeBase"),
    meta: {
      title: {
        en: "Dashboard",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/getting-started",
    name: "gettingStarted",
    component: getView("/PageNotFound"),
    meta: {
      title: {
        en: "Dashboard",
      },
      auth: true,
    },
  },
  {
    path: "/account",
    name: "account",
    component: getView("/PageNotFound"),
    meta: {
      title: {
        en: "Dashboard",
      },
      auth: true,
    },
  },
  {
    path: "/marketplace",
    name: "marketplace",
    component: getView("/Marketplace/Marketplace"),
    meta: {
      title: {
        en: "Marketplace",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/marketplace-sales",
    name: "NetworkSaleCommon",
    component: getView("/NetworkSales/NetworkSaleCommon.vue"),
    meta: {
      title: {
        en: "Sales",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/network-sales",
    name: "NetworkSalePrivate",
    component: getView("/NetworkSales/NetworkSalePrivate.vue"),
    meta: {
      title: {
        en: "My auctions",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/network-sale/:id",
    name: "NetworkSalePrivateView",
    component: getView("/NetworkSales/NetworkSalePrivateView.vue"),
    meta: {
      title: {
        en: "Auction view",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/network-sales/create_old",
    name: "createNetworkSale_old",
    component: getView("/NetworkSales/CreateNetworkSale"),
    meta: {
      title: {
        en: "Create auction",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/network-sales/create",
    name: "createNetworkSale",
    component: getView("/Resources/CreateNetworkSale"),
    meta: {
      title: {
        en: "Create auction",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/network-sales/:id/edit",
    name: "editNetworkSale",
    component: getView("/Resources/CreateNetworkSale"),
    meta: {
      title: {
        en: "Edit auction",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/network-sales-bid/create/:id",
    name: "createSaleBid",
    component: getView("/SaleBids/CreateSaleBid"),
    meta: {
      title: {
        en: "Create sale bid",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "network-sales-bid/:id",
    name: "viewSaleBid",
    component: getView("/SaleBids/SaleBidView"),
    meta: {
      title: {
        en: "View sale bid",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/network-sales-bids",
    name: "saleBidsList",
    component: getView("/SaleBids/SaleBids"),
    meta: {
      title: {
        en: "Create sale bid",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/sales-offers",
    name: "SaleOfferListPublic",
    component: getView("/SaleOffer/SaleOfferListPublic"),
    meta: {
      title: {
        en: "Sale offer list",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/my-sales-offers",
    name: "SaleOfferListPrivate",
    component: getView("/SaleOffer/SaleOfferListPrivate"),
    meta: {
      title: {
        en: "Sale offer list",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/sales-offers/:id",
    name: "SaleOfferView",
    component: getView("/SaleOffer/SaleOfferView"),
    meta: {
      title: {
        en: "Auction view",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/sale-offer/create",
    name: "createSaleOffer",
    component: getView("/SaleOffer/CreateSaleOffer"),
    meta: {
      title: {
        en: "Create auction",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/reports",
    name: "reports",
    component: getView("/Billing/Reports"),
    meta: {
      title: {
        en: "Reports",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/reports/generate",
    name: "generateReport",
    component: getView("/Billing/ReportsGenerate"),
    meta: {
      title: {
        en: "Reports/Generate report",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/reports/:id",
    name: "reportDetails",
    component: getView("/Billing/ReportDetails"),
    meta: {
      title: {
        en: "Reports/Report detail",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/marketplace/networks",
    name: "marketplace_networks",
    component: getView("/Marketplace/MarketplaceNetworks"),
    meta: {
      title: {
        en: "Marketplace|Networks",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/inetnums",
    name: "inetnums",
    component: getView("/Resources/Inetnums"),
    meta: {
      title: {
        en: "Dashboard",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/reserves",
    name: "reserves",
    component: getView("/Resources/Reserves"),
    meta: {
      title: {
        en: "Dashboard",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/utilization",
    name: "utilization",
    component: getView("/Resources/Utilization"),
    meta: {
      title: {
        en: "Utilization",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/utilization/:id",
    name: "utilizationDetail",
    component: getView("/Resources/UtilizationDetail"),
    meta: {
      title: {
        en: "View utilization",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/inetnums/create",
    name: "createInetnum",
    component: getView("/Resources/CreateInetnum"),
    meta: {
      title: {
        en: "Create IP-block",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/inetnums/:id",
    name: "inetnumDetail",
    component: getView("/Resources/InetnumDetails"),
    meta: {
      title: {
        en: "IP-block detail",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/inetnum/:id/:inetnum",
    name: "inetnumOffer",
    component: getView("/Resources/InetnumOffer"),
    meta: {
      title: {
        en: "IP-block offer",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/inetnums/:id/edits",
    name: "editInetnum",
    component: getView("/Resources/CreateInetnum"),
    meta: {
      title: {
        en: "Update inetnum",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/inetnums/:ip/whois",
    name: "whoisInetnum",
    component: getView("/Resources/WhoisInetnum"),
    meta: {
      title: {
        en: "WHOIS",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/contracts",
    name: "contracts",
    component: getView("/Contracts/Contracts"),
    meta: {
      title: {
        en: "Contracts",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/contracts-lease-out",
    name: "contracts",
    component: getView("/Contracts/ContractsLeaseOut"),
    meta: {
      title: {
        en: "Contracts lease out",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/abused_blocks",
    name: "abused_block",
    component: getView("/Resources/AbuseBlock"),
    meta: {
      title: {
        en: "Abuse complaints",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/contracts/add",
    name: "createContract",
    component: getView("/Contracts/CreateContract"),
    meta: {
      title: {
        en: "Create contract",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/contracts/:id",
    name: "contractDetail",
    component: getView("/Contracts/ViewContract"),
    meta: {
      title: {
        en: "View contract",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/contracts/:id/request",
    name: "requestContract",
    component: getView("/Contracts/ContractRequest"),
    meta: {
      title: {
        en: "Request contract",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/contracts/:id/request-list",
    name: "contractRequestList",
    component: getView("/Contracts/ContractRequestList"),
    meta: {
      title: {
        en: "Request contract",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/contracts/:contractId/:netaddress",
    name: "contractNetAddress",
    component: getView("/Contracts/ContractNetAddress"),
    meta: {
      title: {
        en: "Request contract",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/billing",
    name: "billing",
    component: getView("/Billing/Billing"),
    meta: {
      title: {
        en: "Billing",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/invoices",
    name: "BillingInvoices",
    component: getView("/Billing/Invoices"),
    meta: {
      title: {
        en: "Billing invoices",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/payouts-methods",
    name: "payouts",
    component: getView("/Billing/Payouts"),
    meta: {
      title: {
        en: "Payout methods",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/payouts/create",
    name: "createPayout",
    component: getView("/Billing/CreatePayout"),
    meta: {
      title: {
        en: "Payouts/Create payout",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/payouts/create-crypto",
    name: "createCryptoPayout",
    component: getView("/Billing/CreateCryptoPayout"),
    meta: {
      title: {
        en: "Payouts/Create payout",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/payouts-requests",
    name: "payoutsRequests",
    component: getView("/Billing/PayoutRequests"),
    meta: {
      title: {
        en: "Payouts/Requests lists",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/payouts-requests/create/:id",
    name: "payoutsRequestsCreate",
    component: getView("/Billing/PayoutRequestCreate"),
    meta: {
      title: {
        en: "Payouts/Create payout",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/payouts-requests/:id",
    name: "payoutsRequestsDetail",
    component: getView("/Billing/PayoutRequestCreate"),
    meta: {
      title: {
        en: "Payouts/Requests detail",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/billing/create-card",
    name: "createCardBillingMethod",
    component: getView("/Billing/CreateCardBilling"),
    meta: {
      title: {
        en: "Create card billing",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/billing/create-giro",
    name: "createGiroBillingMethod",
    component: getView("/Billing/CreateGiroBilling"),
    meta: {
      title: {
        en: "Create giro billing",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/billing/create-sepa",
    name: "createSepaBillingMethod",
    component: getView("/Billing/CreateSepaBilling"),
    meta: {
      title: {
        en: "Create giro billing",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/billing/create-ideal",
    name: "createIdealBillingMethod",
    component: getView("/Billing/CreateIDEALBilling"),
    meta: {
      title: {
        en: "Create giro billing",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/my-offers",
    name: "myOffers",
    component: getView("/Billing/MyOffersNew"),
    meta: {
      title: {
        en: "My Offers",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/received-offers",
    name: "receivedOffers",
    component: getView("/Billing/ReceivedOffers"),
    meta: {
      title: {
        en: "Received Offers",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/offers/:id/details",
    name: "offerDetails",
    component: getView("/Billing/OfferDetails"),
    beforeEnter: (to, from, next) => {
      return routers.saveGuestToken(to, from, next)
    },
    meta: {
      title: {
        en: "Offer details",
      },
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/offers/:id/accept",
    name: "offerAccept",
    component: getView("/Billing/OfferAccept"),
    beforeEnter: async (to, from, next) => {
      const isGuest = !localStorage.getItem("auth_token_default")
      const query = to.query
      if (!isGuest && query.token) {
        try {
          await Vue.axios.post(`/offer/assign/${to.params.id}?token=${query.token}`)
        } catch (e) {
          console.log(e)
        }
      }

      return routers.saveGuestToken(to, from, next)
    },
    meta: {
      title: {
        en: "Accept Offer",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/marketplace/order",
    name: "confirmPayment",
    component: getView("/Marketplace/ConfirmPayment"),
    meta: {
      title: {
        en: "Confirm payment",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/asn",
    name: "asn",
    component: getView("/Resources/Asn"),
    meta: {
      title: {
        en: "Dashboard",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/asn/create",
    name: "createAsn",
    component: getView("/Resources/CreateAsn"),
    meta: {
      title: {
        en: "Create asn",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/asn/:id/edits",
    name: "editAsn",
    component: getView("/Resources/CreateAsn"),
    meta: {
      title: {
        en: "Update asn",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/asn/:id",
    name: "asnDetail",
    component: getView("/Resources/AsnDetails"),
    meta: {
      title: {
        en: "Dashboard",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/invite-workspace/:id",
    name: "accept-invite",
    component: getView("/AcceptInvitation"),
    meta: {
      title: {
        en: "Accept Invitation",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/routes",
    name: "routes",
    component: getView("/PageNotFound"),
    meta: {
      title: {
        en: "Dashboard",
      },
      auth: true,
    },
  },
  {
    path: "/maintainers",
    name: "maintainers",
    component: getView("/PageNotFound"),
    meta: {
      title: {
        en: "Dashboard",
      },
      auth: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: getView("/Settings"),
    meta: {
      title: {
        en: "Settings",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/assignments",
    name: "Assignments",
    component: getView("/Assigment/List"),
    meta: {
      title: {
        en: "Assignments",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/assignments/create",
    name: "AssignmentsCreate",
    component: getView("/Assigment/Create"),
    meta: {
      title: {
        en: "Assignment create",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/assignments/:id",
    name: "AssignmentsDetail",
    component: getView("/Assigment/Detail"),
    meta: {
      title: {
        en: "Assignment detail",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/reverse-dns-request",
    name: "ReverseRequestDns",
    component: getView("/ReverseRequestDns/List"),
    meta: {
      title: {
        en: "Reverse DNS requests",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "/reverse-dns-request/:id",
    name: "ReverseRequestDnsDetail",
    component: getView("/ReverseRequestDns/Detail"),
    meta: {
      title: {
        en: "Reverse DNS request detail",
      },
      auth: true,
      layout: LAYOUT.MAIN,
    },
  },
  {
    path: "*",
    name: "notFound",
    component: getView("/PageNotFound"),
    meta: {
      title: "404, Page does not exist",
    },
  },
]

if (DEBUG) {
  routes.push({
    path: "/development/ui-libraries",
    name: "Ui-libraries",
    component: Components,
    meta: {
      title: "Ui libraries, for development purpose",
    },
  })
}
