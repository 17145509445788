import Vue from "vue"
const confirmationModal = {
  opened: false,
  configuration: {
    confirmData: {},
    headerText: null,
    description: null,
    isCancelButton: null,
    commentEnabled: false,
  },
  confirmation: () => {},
}
const tfaModal = {
  opened: false,
  configuration: {},
  tfaCallback: () => {},
}
export default {
  namespaced: true,
  state: {
    variables: {
      VUE_APP_GOOGLE_PLACE_KEY: null,
      VUE_APP_GOOGLE_CLIENT_ID: null,
      VUE_APP_STRIPE_PUBLISHABLE_KEY: null,
      VUE_APP_FACEBOOK_CLIENT_ID: null,
    },
    confirmationModal: confirmationModal,
    tfaModal: tfaModal,
    rentListOpened: false,
    loadingOrganisations: false,
  },
  getters: {
    getVariable: (state) => (type) => {
      return state.variables[type]
    },
    getVariables: (state) => {
      return state.variables
    },
    rentListOpened: (state) => {
      return state.rentListOpened
    },
    getLoadingOrganisations: (state) => {
      return state.loadingOrganisations
    },
  },
  mutations: {
    closeRentListOpened(state) {
      state.rentListOpened = false
    },
    openRentList(state) {
      state.rentListOpened = true
    },
    closeRentList(state) {
      state.rentListOpened = false
    },
    saveVariables(state, variables) {
      state.variables = variables
    },
    /**
     * Use to open/close confirmation modal
     * @param state
     * @param {
     *          {
     *            options:
     *            {
     *              confirmData: {},
     *              headerText: string,
     *              description: string,
     *              isCancelButton: boolean,
     *              commentEnabled: boolean,
     *              commentRequired: boolean,
     *              confirmation: function
     *              },
     *              confirmationCallback: function }
     *          }  payload
     */
    toggleConfirmationModalOpened(state, payload) {
      state.confirmationModal.opened = !state.confirmationModal.opened
      state.confirmationModal.configuration = state.confirmationModal.opened
        ? payload.options
        : null
      state.confirmationModal.confirmation = payload.confirmationCallback
    },
    /**
     * Commit for close modal
     * @param state
     */
    closeConfirmationModal(state) {
      state.confirmationModal = confirmationModal
    },
    openTFA(state, payload) {
      state.tfaModal.opened = true
      state.tfaModal.configuration = state.tfaModal.opened ? payload.options : null
      state.tfaModal.tfaCallback = payload.tfaCallback
    },
    closeTFAModal(state) {
      state.tfaModal.opened = false
      state.tfaModal = tfaModal
    },
    setLoadingOrganisations(state, payload) {
      state.loadingOrganisations = payload.state
    },
  },
  actions: {
    async loadVariables(app) {
      const response = await Vue.axios.get(`/shared/variables`)
      if (response.data) {
        return app.commit("saveVariables", response.data)
      }
    },
    /**
     * Toggle the confirm modal with options
     * @param commit
     * @param {{confirmData: {}, headerText: string, description: string, isCancelButton: boolean, commentEnabled: boolean, commentRequired: boolean, confirmation: function}} confirmationOptions: Object with configuration
     * @param { ()=>{} } confirmation: callback for confirmation
     */
    toggleConfirmationModalOpened({ commit }, { confirmationOptions, confirmation }) {
      const payload = {
        ...{
          options: confirmationOptions,
        },
        ...{
          confirmationCallback: confirmation,
        },
      }
      commit("toggleConfirmationModalOpened", payload)
    },
    openTFA({ commit }, { tfaOptions, tfaCallback }) {
      const payload = {
        ...{
          options: tfaOptions,
        },
        ...{
          tfaCallback: tfaCallback,
        },
      }
      commit("openTFA", payload)
    },
    /**
     * Close modal
     * @param commit
     * @param confirmationOptions
     */
    closeTFAModal({ commit }) {
      commit("closeTFAModal")
    },
    openRentList({ commit }) {
      commit("openRentList")
    },
    closeRentList({ commit }) {
      commit("closeRentList")
    },
    setLoadingOrganisation({ commit }, { state }) {
      commit("setLoadingOrganisations", { state: state })
    },
  },
}
