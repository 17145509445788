<template>
  <div>
    <top-loading></top-loading>
    <div class="box-panel">
      <div class="box-panel__layout">
        <div class="box-panel__wrapper">
          <router-view class="box-panel__content" />
        </div>
        <Copy class="box-panel__copy" />
      </div>
    </div>
  </div>
</template>

<script>
import Copy from "@/components/shared/Copy"
import TopLoading from "@/components/shared/TopLoading"

export default {
  name: "BlankLayout",
  components: {
    Copy,
    TopLoading,
  },
  watch: {
    $route(to, from) {
      if (
        to.name === "login" &&
        ["registration", "forgot-password", "change-password"].includes(from.name)
      ) {
        return
      }
      this.$store.dispatch("alerts/clearAlerts", { type: "auth" })
    },
  },
  mounted() {
    this.$intercom.shutdown()
    this.$intercom.boot({
      // user_id: this.userId,
      // email: newValue.users_list[0],
      name: "guest",
    })
  },
  beforeDestroy() {
    this.$store.dispatch("alerts/clearAlerts", { type: "auth" })
  },
}
</script>
