export default {
  computed: {
    user() {
      return this.$auth.user() || {}
    },
  },
  watch: {
    currentOrganization: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.$intercom.shutdown()
          this.$intercom.boot({
            // user_id: this.userId,
            name: newValue.name,
            email: this.user.email,
          })
        } else {
          this.$intercom.shutdown()
          this.$intercom.boot({
            // user_id: this.userId,
            name: "Guest",
            email: this.user.email,
          })
        }
      },
    },
  },
}
