<template>
  <div
    ref="trigger"
    class="tooltip"
    :class="{ [`tooltip--theme-${theme}`]: theme }"
    @mouseenter="setPosition"
  >
    <slot />
    <div
      ref="content"
      :class="{ [`tooltip__content__max-width`]: maxWidth }"
      class="tooltip__content"
      :style="styles"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    positionY: {
      type: String,
      default: "bottom",
    },
    positionX: {
      type: String,
      default: "right",
    },
    offset: {
      type: Number,
      default: 0,
    },
    offsetX: {
      type: Number,
      default: 0,
    },
    theme: {
      type: String,
      default: "dark",
      validator(theme) {
        return ["white", "dark"].includes(theme)
      },
    },
    maxWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      x: 0,
      y: 0,
    }
  },
  computed: {
    styles() {
      const offsetX = this.offsetX * (this.positionX === "right" ? -1 : 1) || 0
      const offsetY = this.offset * (this.positionY === "top" ? -1 : 1) || 0
      return {
        transform: offsetX || offsetY ? `translate(${offsetX}px, ${offsetY}px)` : null,
        top: `${this.y}px`,
        left: `${this.x}px`,
      }
    },
  },
  mounted() {
    this.setPosition()
    window.addEventListener("scroll", this.setPosition)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.setPosition)
  },
  methods: {
    setPosition() {
      const box = this.$refs.trigger.getBoundingClientRect()
      const contentWidth = this.$refs.content.offsetWidth
      const contentHeight = this.$refs.content.offsetHeight
      const left = this.positionX === "left" ? box.left : box.right - contentWidth
      const top =
        this.positionY === "top"
          ? box.top - contentHeight
          : this.positionY === "center"
          ? box.top - box.height / 2
          : box.top + box.height
      this.x = left
      this.y = top
    },
  },
}
</script>

<style scoped lang="scss">
.tooltip {
  position: relative;
  display: inline-block;
  &:hover {
    .tooltip__content {
      opacity: 1;
    }
  }
  &--theme-white {
    .tooltip__content {
      background: var(--text-white);
      color: var(--text-color);
      box-shadow: 0px 2px 8px rgba(37, 84, 255, 0.08), 0px 0px 2px rgba(37, 84, 255, 0.04),
        0px 0px 6px rgba(37, 84, 255, 0.04);
    }
  }
  &__content {
    position: fixed;
    background: rgba(102, 102, 102, 0.9);
    opacity: 0;
    z-index: 1;
    pointer-events: none;
    transition: var(--transition-fast);
    white-space: nowrap;
    color: var(--text-white);
    border-radius: 4px;
    padding: 7px 5px;
    font-size: 12px;
    letter-spacing: 0.4px;
    &__max-width {
      max-width: 250px;
      display: block;
      white-space: normal;
      line-height: 1.3;
    }
  }
}
</style>
