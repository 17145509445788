<template>
  <li class="sidebar-item" :class="{ 'sidebar-item--disabled': disabled }">
    <app-accordion v-model="isExpanded" :disabled="!nestedLinks">
      <template #header>
        <component
          :is="to ? 'router-link' : 'span'"
          :to="to"
          class="sidebar-item__link sidebar-link"
          :class="{ 'sidebar-link--active': !isExpanded && hasActiveChildren }"
        >
          <span class="sidebar-item__icon">
            <svgicon :name="icon" />
          </span>
          <span class="sidebar-item__label">
            {{ label }}
          </span>
          <span
            v-if="nestedLinks"
            class="sidebar-item__expand"
            :class="[isExpanded && 'sidebar-item__expand--active']"
          >
            <svgicon name="expand_more" />
          </span>
        </component>
      </template>
      <ul v-if="nestedLinks" class="sidebar-item__nested">
        <li
          v-for="nestedLink in nestedLinks"
          :key="nestedLink.label"
          class="sidebar-nested-item"
          :class="nestedLink.disabled && 'sidebar-item--disabled'"
        >
          <router-link :to="`${nestedLink.to}`" class="sidebar-nested-item__link">
            <span>
              {{ nestedLink.label }}
            </span>
          </router-link>
        </li>
      </ul>
    </app-accordion>
  </li>
</template>

<script>
import AppAccordion from "@/components/ui/Accordion"

export default {
  components: { AppAccordion },
  props: {
    to: {
      type: [String, Object],
      default: "",
    },
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    nestedLinks: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  computed: {
    hasActiveChildren() {
      if (!this.nestedLinks || !this.nestedLinks.length) {
        return false
      }
      return this.nestedLinks.some((link) => {
        const currentPath = this.$route.path
        const linkPath = this.$router.resolve(link.to).href
        return currentPath.startsWith(linkPath)
      })
    },
  },
}
</script>

<style scoped lang="scss">
$icon-not-active: rgba(144, 144, 217, 0.8);
.sidebar-item {
  font-family: var(--font-secondary);
  &:not(:last-child) {
    margin-bottom: 7px;
  }
  &__link {
    padding: 7px 9px;
    display: flex;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
    transition: var(--transition-fast);
  }
  &__icon {
    font-size: 24px;
    margin-right: 18px;
    color: $icon-not-active;
  }
  &__label {
    flex: 1 1 auto;
    color: var(--sidebar-color);
  }
  &:hover {
    .sidebar-item__expand {
      color: var(--icons-color);
    }
  }
  &__expand {
    font-size: 23px;
    transition: var(--transition-fast);
    color: $icon-not-active;
    &--active {
      transform: rotate(90deg);
      color: var(--icons-color);
    }
  }
  &__nested {
    margin-top: 2px;
  }
}
.sidebar-nested-item {
  &__link {
    position: relative;
    padding: 10px 5px 10px 52px;
    text-decoration: none;
    color: var(--sidebar-color);
    display: inline-block;
    width: 100%;
    border: none;
    &:hover,
    &.router-link-active {
      color: var(--sidebar-active-color);
      &:after {
        background: #252546;
      }
    }
    & > span {
      position: relative;
      z-index: 1;
    }
    &:after {
      background: transparent;
      transition: var(--transition-fast);
      position: absolute;
      top: -2.5px;
      left: 0;
      width: 100%;
      height: calc(100% + 5px);
      content: "";
      z-index: 0;
      pointer-events: none;
      border-radius: 4px;
    }
  }
}
.sidebar-item--disabled {
  opacity: 0.5;
  position: relative;
  &:after {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
  }
}
.sidebar-link {
  width: 100%;
  border-radius: 4px;
  border: none;
  &:hover,
  &--active,
  &.router-link-active {
    background: #252546;
  }
  &--active,
  &.router-link-active {
    .sidebar-item__label {
      color: var(--sidebar-active-color);
    }
  }
}
</style>
