export default {
  bind(el, binding, vnode) {
    let interval = null
    let startTime
    const handler = () => vnode.context[binding.expression].apply()
    const clear = () => {
      if (Date.now() - startTime < 100) {
        handler()
      }
      clearInterval(interval)
      interval = null
    }

    el.addEventListener("mousedown", (e) => {
      if (e.button !== 0) return
      startTime = Date.now()
      window.addEventListener("mouseup", clear, {
        once: true,
      })
      clearInterval(interval)
      interval = setInterval(handler, 100)
    })
  },
}
