/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'default_avatar': {
    width: 20,
    height: 21,
    viewBox: '0 0 20 21',
    data: '<path pid="0" d="M10 .5C4.48.5 0 4.98 0 10.5s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2a7.2 7.2 0 01-6-3.22c.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08a7.2 7.2 0 01-6 3.22z" _fill="#1E1E3F"/>'
  }
})
