<template>
  <div class="logo" :class="[theme && `logo--theme-${theme}`]">
    <span class="logo__text"> Interlir </span>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    theme: {
      type: String,
      default: "dark",
    },
  },
}
</script>

<style scoped lang="scss">
.logo {
  text-align: center;
  font-family: var(--font-secondary);
  font-size: 18px;
  color: var(--light-violet);
  font-weight: 700;
  &--theme-light {
    color: var(--text-white);
  }
  &__text {
    text-transform: uppercase;
  }
}
</style>