/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
    'reset-filter': {
        width: 64,
        height: 64,
        viewBox: '0 0 64 64',
        data: '<polygon fill="none" stroke="#FFFFFF" stroke-miterlimit="10" stroke-width="4" points="24.5 10 61.5 10 61.5 54 24.5 54 2.5 32 24.5 10"></polygon><line x1="29.5" x2="49.5" y1="22" y2="42" fill="none" stroke="#FFFFFF" stroke-miterlimit="10" stroke-width="4"></line><line x1="49.5" x2="29.5" y1="22" y2="42" fill="none" stroke="#FFFFFF" stroke-miterlimit="10" stroke-width="4"></line>'
    }
})
