<template>
  <li class="subnavigation-item" v-on="$listeners">
    <component :is="to ? 'router-link' : 'div'" :to="to" class="subnavigation-item__link">
      <div class="subnavigation-item__icon">
        <svgicon :name="icon" />
      </div>
      <div class="subnavigation-item__label">
        {{ label }}
      </div>
    </component>
    <div v-if="$slots.append" class="subnavigation-item__append">
      <slot name="append" />
    </div>
  </li>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    to: {
      type: [String, Object],
      default: "",
    },
    icon: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.subnavigation-item {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 5px 0 12px;
  &:not(:last-child) {
    margin-bottom: 5px;
  }
  &__icon {
    font-size: 24px;
    margin-right: 12px;
    color: var(--icons-color);
  }
  &__link {
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    color: var(--text-grey);
    font-size: 13px;
  }
}
</style>