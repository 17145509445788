import Vue from "vue"

import auth from "@websanova/vue-auth/dist/v2/vue-auth.esm.js"
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js"
import driverRouterVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js"
import driverOAuth2Google from "@websanova/vue-auth/dist/drivers/oauth2/google.esm.js"
import driverOAuth2Facebook from "@websanova/vue-auth/dist/drivers/oauth2/facebook.esm.js"

const tokenDefaultName = "access_token"

Vue.use(auth, {
  tokenDefaultName: tokenDefaultName,
  tokenStore: ["localStorage"],
  plugins: {
    http: Vue.axios, // Axios
    router: Vue.router,
  },
  drivers: {
    auth: {
      request: function (req, token) {
        const refresh = req.url.indexOf("refreshToken") > -1
        token = token.split(";")
        if (!refresh) {
          token = token[0]
          req.headers["Authorization"] = "Bearer " + token
        } else {
          token = token[1]
          req.data = { refresh_token: token }
        }
      },
      response: function (res) {
        if (!res.data) {
          return
        }
        if (res.data[tokenDefaultName]) {
          return res.data[tokenDefaultName] + ";" + res.data[tokenDefaultName]
        }
      },
    },
    http: driverHttpAxios,
    router: driverRouterVueRouter,
    oauth2: {
      google: driverOAuth2Google,
      facebook: driverOAuth2Facebook,
    },
  },

  options: {
    parseUserData: (data) => {
      return data
    },
    loginData: {
      url: "/auth/jwt/login",
      fetchUser: true,
      staySignedIn: true,
    },
    fetchData: {
      url: "/users/me",
      method: "GET",
    },
    refreshData: {
      url: "/auth/jwt/refresh",
      method: "POST",
    },
    authRedirect(from) {
      sessionStorage.setItem("redirect_to", from.fullPath)
      return "/login"
    },
  },
})
