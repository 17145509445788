<template>
  <div class="code-input">
    <div class="code-input--container">
      <input
        v-for="(input, index) in codeInputs"
        v-bind="$attrs"
        :id="'code' + (index + 1)"
        :key="index"
        :ref="'code' + (index + 1)"
        type="text"
        maxlength="1"
        :value="input"
        :class="{ error, filled: filledIn.includes(index) }"
        @input="handleInput(index, $event)"
        @keyup="handleKeyup(index, $event)"
        @keydown="handleKeydown(index, $event)"
        @paste="handlePaste"
      />
    </div>
    <div v-if="error" class="code-input--error">
      <validation-error :error="error"></validation-error>
    </div>
  </div>
</template>

<script>
import ValidationError from "@/components/ui/forms/ValidationError"

export default {
  components: { ValidationError },
  props: {
    codeLength: {
      type: Number,
      required: false,
      default: 6,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      codeInputs: Array(this.codeLength),
      currentInputIndex: 0,
      value: "",
      filledIn: [],
    }
  },
  watch: {
    value: {
      handler: function (value) {
        if (value.length === this.codeLength) {
          this.$emit("input", this.value)
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$refs["code1"][0].focus()
  },
  methods: {
    isFilled: function (index) {
      console.log(this.codeInputs[index], "index filled in")
      console.log(index, "index filled in")
      return true
    },
    handlePaste(event) {
      console.log(event, "on paste event")
    },
    handleInput(index, event) {
      const value = event.target.value
      // Allow only numbers
      this.codeInputs[index] = value.replace(/[^0-9]/g, "")

      if (value !== "" && index < this.codeLength - 1) {
        this.currentInputIndex = index + 1
        this.$refs["code" + (index + 2)][0].focus()
      }
      this.value = this.codeInputs.join("")
    },
    handleKeydown(index, event) {
      const eventKey = event.key
      // Prevent typing letters
      if (event.key && event.key.length === 1 && !event.key.match(/[0-9]/)) {
        event.preventDefault()
      }
      const leftRightNavigation = eventKey === "ArrowLeft" || eventKey === "ArrowRight"
      if (leftRightNavigation) {
        const config = {
          ArrowLeft: index > 0 ? index - 1 : false,
          ArrowRight: index < this.codeLength - 1 ? index + 1 : false,
        }
        if (config[eventKey] !== false) {
          this.currentInputIndex = config[eventKey]
          return this.$refs["code" + (this.currentInputIndex + 1)][0].focus()
        }
        return event
      }
    },
    handleKeyup(index, event) {
      const eventKey = event.key
      const isDeletion = eventKey === "Backspace" || eventKey === "Delete"
      const notLastInput = (this.codeInputs[index] === "" || !this.codeInputs[index]) && index > 0
      if (isDeletion && notLastInput) {
        this.currentInputIndex = index - 1
        this.$refs["code" + index][0].focus()
      }
      this.filledIn = Object.keys(this.codeInputs)
        .filter((key) => {
          return !!this.codeInputs[key]
        })
        .map((item) => parseInt(item))
    },
  },
}
</script>

<style lang="scss" scoped>
.code-input {
  &--error {
    margin-top: 10px;
    text-align: center;
  }
  &--container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;

    input[type="text"] {
      transition: none;
      width: 40px;
      height: 40px;
      font-size: 20px;
      text-align: center;
      border: none;
      background: transparent;
      border-bottom: 1px solid var(--icons-color);
      margin: 0 8px;
      outline: none; /* Remove the default focus outline */
      &:focus,
      &.filled {
        border-color: var(--primary-color);
      }
      &.error {
        border-color: var(--color-red);
      }
    }
  }
}
</style>
