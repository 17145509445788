/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-back': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" _fill="#65658E"/>'
  }
})
