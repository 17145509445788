<template>
  <div class="loader">
    <div v-if="type === 'default'" class="loader__default" :style="styles">
      <svg :viewBox="`${viewBoxSize} ${viewBoxSize} ${2 * viewBoxSize} ${2 * viewBoxSize}`">
        <circle
          fill="none"
          :cx="2 * viewBoxSize"
          :cy="2 * viewBoxSize"
          :r="normalizedSize"
          :style="circleStyles"
        />
      </svg>
    </div>
    <div v-else class="loader__icon">
      <svgicon name="spinner" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    type: {
      type: String,
      default: "default",
    },
    size: {
      type: [Number, String],
      default: 35,
    },
    width: {
      type: [Number, String],
      default: 2.25,
    },
  },
  computed: {
    circumference() {
      return 2 * Math.PI * this.normalizedSize
    },
    normalizedSize() {
      return Number.parseInt(this.size)
    },
    styles() {
      return {
        height: `${this.normalizedSize}px`,
        width: `${this.normalizedSize}px`,
      }
    },
    circleStyles() {
      const offset = this.circumference * 0.25
      return {
        strokeWidth: `${this.width}px`,
        strokeDasharray: `${this.circumference - offset}px`,
        strokeDashoffset: `${-offset}px`,
      }
    },
    viewBoxSize() {
      return this.normalizedSize / (1 - Number(this.width) / this.normalizedSize)
    },
  },
}
</script>

<style scoped lang="scss">
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  &__default {
    position: relative;
    svg {
      animation: progress-circular-rotate 700ms linear infinite;
      transform-origin: center center;
      transition: all 0.2s ease-in-out;
      width: 100%;
      height: 100%;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
    }
    circle {
      stroke-linecap: round;
      stroke: var(--primary-color);
    }
  }
  &__icon {
    animation: progress-circular-rotate 700ms linear infinite;
    display: flex;
  }
}
@keyframes progress-circular-rotate {
  to {
    transform: rotate(1turn);
  }
}
</style>
