export default {
  async created() {
    this.provider = this.$route.params["social_provider"]
    this.isAuthResponse = !!this.provider
    console.log(this.provider, "Provider")
    if (this.isAuthResponse) {
      this.loading = true
      await this.authSocial()
    }
  },
  data() {
    return {
      isAuthResponse: false,
      provider: "",
    }
  },
  methods: {
    async authSocial() {
      const code = this.$route.query.code
      const state = this.$route.query.state
      const error = this.$route.query.error
      const payload = {
        code: code,
        state: state,
        redirect_url: window.location.href.split("?")[0],
      }
      if (error) {
        payload["error"] = error
      }
      console.log(payload)
      if (code && state) {
        const params = new URLSearchParams(payload).toString()
        const redirect = sessionStorage.getItem("redirect_to") || "/dashboard"
        const callbacks = {
          google: `/auth/oauth/google/callback?${params}`,
          facebook: `/auth/oauth/facebook/callback?${params}`,
        }
        console.log(this.$route.query.code)
        console.log(this.provider)
        try {
          await this.$auth.oauth2(this.provider, {
            state: this.$route.query.code,
            url: callbacks[this.provider],
            method: "GET",
            code: true,
            redirect,
          })
          this.$alert({
            message: this.$gettext("You have successfully logged in."),
            type: "info",
            timer: 5000,
          })
        } catch (e) {
          alert(e)
          this.$alert({
            message: this.$gettext("Sorry, something goes badly. Try again later"),
            type: "error",
            timer: 2000,
          })
        }
      }
    },
  },
}
