/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 25,
    height: 24,
    viewBox: '0 0 25 24',
    data: '<path pid="0" d="M16 14h-.79l-.28-.27A6.471 6.471 0 0016.5 9.5 6.5 6.5 0 1010 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.99 19 16 14zm-6 0c-2.49 0-4.5-2.01-4.5-4.5S7.51 5 10 5s4.5 2.01 4.5 4.5S12.49 14 10 14z" _fill="#9090D9"/>'
  }
})
