/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'upload': {
    width: 52,
    height: 52,
    viewBox: '0 0 52 52',
    data: '<g opacity=".7"><rect pid="0" width="52" height="52" rx="26"/><path pid="1" d="M19.5 34.667h13v-13h8.667L26 6.5 10.834 21.667H19.5v13zM10.834 39h30.333v4.333H10.833V39z" _fill="#2554FF"/></g>'
  }
})
