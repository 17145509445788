/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'columns': {
    width: 31,
    height: 24,
    viewBox: '0 0 31 24',
    data: '<svg width="31" height="24" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
        '<path d="M10.9431 1H2.37421C1.84839 1 1.42212 1.32833 1.42212 1.73333V22.2667C1.42212 22.6717 1.84839 23 2.37421 23H10.9431M10.9431 1V23M10.9431 1H20.464M10.9431 23H20.464M20.464 1H29.0328C29.5587 1 29.9849 1.32833 29.9849 1.73333V22.2667C29.9849 22.6717 29.5587 23 29.0328 23H20.464M20.464 1V23" stroke="#2A2A4B" stroke-width="2"/>\n' +
        '</svg>'
  }
})
