import Vue from "vue"
import store from "@/store"

const plugin = {}

plugin.install = function () {
  /**
   *
   * @param {{confirmData: {}, headerText: string, description: string, isCancelButton: boolean, commentEnabled: boolean, commentRequired: boolean, confirmation: function}} confirmationOptions: Object with configuration
   * @param { ()=>{} } confirmation: callback for confirmation
   */
  const confirmation = async function (confirmationOptions, confirmation) {
    if (store.state.core.confirmationModal.opened) {
      return false
    }
    return await store.dispatch("core/toggleConfirmationModalOpened", {
      confirmationOptions: confirmationOptions,
      confirmation: confirmation,
    })
  }
  Vue.confirmation = confirmation
  Vue.prototype.$confirmation = confirmation
}
Vue.use(plugin)

export default plugin
