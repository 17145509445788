<template>
  <div id="app" style="padding: 200px">
    <h1>Components libraries</h1>
    <AvailableNetworks
      v-model="value"
      :multiple="true"
      :required="true"
      :search-nested="true"
      organization-uid="someuid"
    />
  </div>
</template>

<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css"
import AvailableNetworks from "@/components/ui/AvailableNetworks"

export default {
  name: "Components",
  components: { AvailableNetworks },
  data() {
    return {
      // define the default value
      value: null,
      // define options
    }
  },
}
</script>

<style scoped>

</style>
