<template>
  <aside class="sidebar">
    <div class="sidebar__logo">
      <app-logo theme="light" />
    </div>
    <nav class="sidebar-navigation">
      <ul>
        <SidebarItem
          v-for="link in items"
          :key="link.label"
          :to="link.to"
          :disabled="link.disabled"
          :icon="link.icon"
          :label="link.label"
          :nested-links="link.items"
        />
        <div class="sidebar-navigation__delimiter" />
        <SidebarItem to="/settings" icon="settings" :label="$t('Settings')" />
      </ul>
    </nav>
  </aside>
</template>

<script>
import SidebarItem from "./SidebarItem"
import AppLogo from "@/components/shared/Logo"

export default {
  components: { SidebarItem, AppLogo },
  computed: {
    items() {
      return [
        {
          label: this.$t("Dashboard"),
          to: "/dashboard",
          icon: "dashboard",
        },
        {
          label: this.$t("Marketplace"),
          icon: "store",
          items: [
            {
              label: this.$t("Rent"),
              to: "/marketplace",
            },
            {
              label: this.$t("For sale"),
              to: "/sales-offers",
            },
            {
              label: this.$t("Auctions"),
              to: "/marketplace-sales",
            },
          ],
        },
        {
          label: this.$t("My resources"),
          icon: "business_center",
          items: [
            {
              label: this.$t("IP-blocks"),
              to: "/inetnums",
            },
            {
              label: this.$t("My reserves"),
              to: "/reserves",
            },
            {
              label: this.$t("Leased IP-blocks"),
              to: "/contracts",
            },
            {
              label: this.$t("For sale"),
              to: "/my-sales-offers",
            },
            {
              label: this.$t("ASN"),
              to: "/asn",
            },
            {
              label: this.$t("Assignments"),
              to: "/assignments",
            },
            {
              label: this.$t("Reverse DNS"),
              to: "/reverse-dns-request",
            },
            {
              label: this.$t("Supplier's contracts"),
              to: "/contracts-lease-out",
            },
            {
              label: this.$t("Abuse complaints"),
              to: "/abused_blocks",
            },
            // {
            //   label: this.$t("Utilization"),
            //   to: "/utilization",
            // },
          ],
        },
        {
          label: this.$t("Auction"),
          icon: "auction",
          items: [
            {
              label: this.$t("My auctions"),
              to: "/network-sales",
            },
            {
              label: this.$t("My bids"),
              to: "/network-sales-bids",
            },
          ],
        },
        {
          label: this.$t("Billing"),
          icon: "payment",
          items: [
            {
              label: this.$t("Invoices"),
              to: "/invoices",
            },
            {
              label: this.$t("Payment methods"),
              to: "/billing",
            },
            {
              label: this.$t("Payouts"),
              to: "/payouts-requests",
            },
            {
              label: this.$t("Payout methods"),
              to: "/payouts-methods",
            },
            {
              label: this.$t("Reports"),
              to: "/reports",
            },
          ],
        },
        {
          label: this.$t("Offers"),
          icon: "paid",
          items: [
            {
              label: this.$t("My"),
              to: "/my-offers",
            },
            {
              label: this.$t("Incoming"),
              to: "/received-offers",
            },
          ],
        },
        {
          label: this.$t("Support"),
          icon: "help",
          to: "/support",
        },
        {
          label: this.$t("Knowledge base"),
          to: "/knowledge-base",
          icon: "school",
        },
      ]
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/scss/_settings";
.sidebar {
  background: var(--text-color);
  height: 100%;
  padding: 24px 8px;
  width: $--sidebar-width;
  overflow: auto;
  &__logo {
    margin-bottom: 59px;
    display: flex;
    padding-left: 20px;
    @media (min-width: #{$--mobile-max-width}) {
      display: none;
    }
  }
}
.sidebar-navigation {
  &__delimiter {
    width: 100%;
    height: 1px;
    background: var(--border-color);
    margin-top: 40px;
    margin-bottom: 40px;
    opacity: 0.3;
  }
}
</style>
