/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pdf': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 0h12.745L22.5 6.729V22.5A1.5 1.5 0 0121 24H3a1.5 1.5 0 01-1.5-1.5v-21A1.5 1.5 0 013 0z" _fill="#2554FF"/><path pid="1" d="M22.478 6.75H17.25a1.5 1.5 0 01-1.5-1.5V.015l6.728 6.735z" _fill="#0C36D3"/><path pid="2" d="M16.874 11.372c.25 0 .374-.219.374-.431 0-.22-.128-.432-.374-.432h-1.431c-.28 0-.436.232-.436.487v3.517c0 .314.178.488.42.488.24 0 .42-.174.42-.488v-.965h.865c.268 0 .402-.22.402-.438 0-.214-.134-.426-.402-.426h-.866v-1.312h1.028zm-4.837-.863H10.99a.463.463 0 00-.486.484v3.523c0 .36.29.472.498.472H12.1c1.301 0 2.16-.856 2.16-2.176 0-1.397-.81-2.303-2.223-2.303zm.05 3.61h-.638v-2.741h.575c.87 0 1.25.584 1.25 1.39 0 .755-.372 1.352-1.187 1.352zm-3.835-3.61H7.214c-.293 0-.456.194-.456.487v3.517c0 .314.187.488.439.488s.44-.174.44-.488v-1.027h.65c.802 0 1.464-.568 1.464-1.482 0-.895-.638-1.495-1.5-1.495zm-.018 2.153h-.597v-1.327h.597c.37 0 .604.288.604.663 0 .376-.235.664-.604.664z" _fill="#fff"/>'
  }
})
