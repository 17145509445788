var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input",class:{
    'input--disabled': _vm.disabled,
    'input--error': _vm.error,
    'input--verification': _vm.verification,
    'input--clear': _vm.clearStyles,
    [`input--size-${_vm.size}`]: _vm.size,
  }},[(_vm.label || _vm.$slots.label)?_c('div',{staticClass:"input__label-container"},[_c('label',{staticClass:"input__label",attrs:{"for":_vm.id}},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(_vm.label)+" ")]})],2),(_vm.$slots['label-append'])?_c('div',{staticClass:"input__label-append"},[_vm._t("label-append")],2):_vm._e()]):_vm._e(),_c('div',{staticClass:"input__container"},[_vm._t("default",null,null,{
        id: _vm.id,
        elementClass: 'input__element',
        borderClass: 'input__inherit-border',
      })],2),((_vm.error && _vm.showErrorMessage) || _vm.verification || _vm.notice)?_c('div',{staticClass:"input__notice-container"},[(_vm.verification)?_c('div',{staticClass:"input__verification"},[_c('base-spinner',{staticClass:"input__verification-icon",attrs:{"type":"dashed"}}),_c('div',{staticClass:"input__verification-message"},[_vm._v(_vm._s(_vm.$t("Verification"))+"...")])],1):(_vm.error)?_c('validation-error',{attrs:{"error":_vm.error}}):_vm._e(),(_vm.notice)?_c('div',{staticClass:"input__notice"},[_vm._t("notice",function(){return [(!_vm.error && !_vm.verification)?_c('span',[_vm._v(" "+_vm._s(_vm.notice)+" ")]):_vm._e()]})],2):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }