/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M15.4 10.8h-4.8v4.8H9v-4.8H4.2V9.2H9V4.4h1.6v4.8h4.8v1.6z" _fill="#fff"/>'
  }
})
