/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'outlined_flag': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14 6l-1-2H5v17h2v-7h5l1 2h7V6h-6zm4 8h-4l-1-2H7V6h5l1 2h5v6z" _fill="#9090D9" opacity=".7"/>'
  }
})
