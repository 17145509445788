function preventDefault(e) {
  if (e.cancelable) {
    e.preventDefault()
  }
}
const options = {
  passive: false,
}
function onMove(e) {
  if (!this.contains(e.target)) {
    return preventDefault(e)
  }
  if (!e.deltaY && (!e.touches || !e.touches[0])) {
    return
  }
  const isScrollDown = e.deltaY ? e.deltaY > 0 : this._lastY > e.touches[0].clientY
  if (e.touches) {
    this._lastY = e.touches[0].clientY
  }
  if (isScrollDown) {
    if (this.scrollTop + this.clientHeight === this.scrollHeight) {
      preventDefault(e)
    }
  } else if (this.scrollTop === 0) {
    preventDefault(e)
  }
}
function onTouchStart(e) {
  if (e.touches[0]) {
    this._lastY = e.touches[0].clientY
  }
}
const directive = {
  inserted(element, bindings) {
    if (typeof bindings.value === "undefined" || bindings.value) {
      element.addEventListener("touchmove", onMove, options)
      element.addEventListener("touchstart", onTouchStart, options)
      element.addEventListener("mousewheel", onMove, options)
    }
  },
  update(element, bindings) {
    if (typeof bindings.value === "undefined" || bindings.value) {
      element.addEventListener("touchmove", onMove, options)
      element.addEventListener("touchstart", onTouchStart, options)
      element.addEventListener("mousewheel", onMove, options)
    } else {
      element.removeEventListener("touchmove", onMove, options)
      element.removeEventListener("touchstart", onTouchStart, options)
      element.removeEventListener("mousewheel", onMove, options)
    }
  },
}

export default directive
