<template>
  <div class="access-page">
    <Logo class="access-page__logo" />
    <div class="access-page__wrapper">
      <h1 class="access-page__title">
        {{ $t("Your account login") }}
      </h1>
      <div class="access-page__notifications">
        <AlertContainer type="auth" />
      </div>
      <form class="access-page__form">
        <div class="access-page__form-line">
          <app-input
            v-model="form.username"
            :label="$t('E-mail')"
            type="email"
            :html-id="'username'"
            :error="errors.username"
          />
        </div>
        <div class="access-page__form-line">
          <app-input
            v-model="form.password"
            type="password"
            :label="$t('Password')"
            :error="errors.password"
            :html-id="'password'"
          >
            <template #label-append>
              <router-link class="access-page__link" :to="`${$utils.i18nLink('/forgot-password')}`">
                {{ $t("Forgot password?") }}
              </router-link>
            </template>
          </app-input>
          <div class="access-page__remember-me">
            <app-checkbox
              v-model="form.rememberMe"
              :value="form.rememberMe"
              :label="$t('Keep me logged in')"
            />
          </div>
        </div>

        <app-button :loading="loading" type="submit" size="wide" @click="emailLogin">
          {{ $t("Login") }}
        </app-button>

        <div class="access-page__links-description">
          {{ $t("or log in with") }}
        </div>

        <div class="access-page__links">
          <o-auth />
        </div>
        <div v-if="!fromRegistration" class="access-page__register-account">
          {{ $t("Don't have an account?") }}
          <router-link :to="`${$utils.i18nLink('/registration')}`">
            {{ $t("Register here") }}
          </router-link>
        </div>
        <div v-if="fromRegistration" class="access-page__register-account">
          <countdown ref="countdownRef" :end-time="lastSentTime">
            <template #process="{ timeObj }">
              <span>{{
                `Don't get a registration code? Resent after: ${timeObj.m}:${timeObj.s}`
              }}</span>
            </template>
            <template #finish>
              <span> Don't get registration code? </span>
              <a href="javascript:void(0)" @click="resendEmail">{{ $t("Resend email again") }}</a>
            </template>
          </countdown>
        </div>
      </form>
    </div>
    <template>
      <TFAContainer></TFAContainer>
    </template>
  </div>
</template>

<script>
import AppButton from "@/components/ui/AppButton"
import AppInput from "@/components/ui/forms/TextField"
import OAuth from "@/components/shared/OAuth"
import AppCheckbox from "@/components/ui/AppCheckbox"
import Logo from "@/components/shared/Logo"
import AlertContainer from "@/components/shared/Alert"
import accessForm from "@/mixins/accessForm"
import oauthResponse from "@/mixins/oauthResponse"
import notificationService from "@/services/notificationService"
import TFAContainer from "@/components/shared/Modals/TFAContainer.vue"

export default {
  name: "PageLogin",
  components: {
    TFAContainer,
    AppButton,
    AppInput,
    AppCheckbox,
    OAuth,
    Logo,
    AlertContainer,
  },
  mixins: [accessForm, oauthResponse],
  data() {
    return {
      loading: false,
      fromRegistration: !!this.$route.query.registered,
      lastSentTime: null,
    }
  },
  async created() {
    if (this.$route.query.token) {
      await this.confirmRegistration(this.$route.query.token)
    }
    if (this.$route.query.auth_token) {
      await this.auth(this.$route.query.auth_token)
    }
  },
  async mounted() {
    if (this.fromRegistration) {
      await this.setTimer()
    }
  },
  methods: {
    async emailLogin(tfaCode, attempt) {
      await this.$store.dispatch("loader/startLoading")
      if (!this.formValid()) {
        return
      }
      try {
        this.loading = true
        const defaultRedirect = "/dashboard"
        const redirect = sessionStorage.getItem("redirect_to") || defaultRedirect
        const data = this.$utils.addTokenToData(this.form)
        data.two_factor = tfaCode
        const resp = await this.$auth.login({
          data,
          staySignedIn: this.form.rememberMe,
          remember: this.form.rememberMe,
          redirect,
        })
        this.$alert({
          message: this.$gettext("You have successfully logged in."),
          type: "info",
          timer: 5000,
        })
        window.location.reload()
        notificationService.clearShownNotifications()
        sessionStorage.removeItem("redirect_to")
        this.$utils.clearTokenData()
      } catch (request) {
        if (request.response.status === 423 && !attempt) {
          const tfaOptions = {
            confirmData: {},
            isCancelButton: true,
            commentEnabled: false,
            commentRequired: true,
          }
          await this.$confirmTfa(tfaOptions, async (form) => {
            return await this.emailLogin(form.code, true)
          })
          return
        }
        const message =
          request.response.status === 423
            ? "Two-Factor Authentication is incorrect"
            : "Email or password is invalid"
        this.$alert({
          type: "error",
          message: this.$t(message),
          layout: "auth",
          stacked: false,
        })
      } finally {
        this.loading = false
        await this.$store.dispatch("loader/doneLoading")
      }
    },
    async confirmRegistration(token) {
      try {
        const { data } = await this.$store.dispatch("user/verify", { token })
        this.$alert({
          type: "success",
          message: this.$t(`${data.email} successfully registered`),
          layout: "auth",
          stacked: false,
        })
      } catch (e) {
        if (e.response.data["detail"] === "VERIFY_USER_ALREADY_VERIFIED") {
          this.$alert({
            type: "error",
            message: this.$t("This email address has already been verified"),
            layout: "auth",
            stacked: false,
          })
        } else {
          this.$alert({
            type: "error",
            message: this.$t("Something went wrong"),
            layout: "auth",
            stacked: false,
          })
        }
      }
    },
    async auth(token) {
      const redirect = sessionStorage.getItem("redirect_to") || "/dashboard"
      await this.$auth.logout()
      this.$auth.token(null, token, false)
      await this.$auth.fetch({})
      await this.$router.push(redirect)
      sessionStorage.removeItem("redirect_to")
    },
    async setTimer() {
      const email = this.$route.query.registered
      const data = await this.$store.dispatch("user/getEmailTimer", { email })
      const restart = this.lastSentTime === null
      this.lastSentTime = new Date().getTime() + data.seconds * 1000
      this.$refs.countdownRef.startCountdown(restart)
    },
    async resendEmail() {
      const email = this.$route.query.registered
      try {
        await this.$store.dispatch("user/resendRegistrationEmail", { email })
        this.$alert({
          type: "success",
          message: "An email has been sent success",
          layout: "auth",
          stacked: false,
        })
        await this.setTimer()
      } catch (e) {
        this.$alert({
          type: "error",
          message: "Sorry, but an email can not be sent.",
          layout: "auth",
          stacked: false,
        })
      }
    },
  },
}
</script>
