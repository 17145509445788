<template>
  <div class="copyright">
    {{ new Date().getFullYear() }} © InterLIR, GmbH. {{ $t("All rights reserved") }}
    <div class="copyright__links">
      <a target="_blank" href="https://interlir.com/terms-of-service"> {{ $t("Terms of use") }}</a>
      <a target="_blank" href="https://interlir.com/privacy-policy">{{ $t("Privacy Policy") }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Copy",
}
</script>
