/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'info': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.961 2C6.459 2 1.994 6.48 1.994 12s4.465 10 9.967 10c5.502 0 9.967-4.48 9.967-10S17.463 2 11.961 2zm.997 15h-1.994v-6h1.994v6zm0-8h-1.994V7h1.994v2z" _fill="#2554FF"/>'
  }
})
