<template>
  <div class="dropdown" :class="{ 'dropdown--stretch': stretch, 'dropdown--disabled': disabled }">
    <div ref="trigger" class="dropdown__trigger" @click="onClick">
      <slot v-bind="{ isOpened }" />
    </div>
    <app-transition v-if="dropdown">
      <div
        v-if="isOpened"
        v-click-outside="onClickOutside"
        class="dropdown__content"
        :class="contentClassName"
        :style="contentStyles"
      >
        <slot name="content" v-bind="{ close }" />
      </div>
    </app-transition>
  </div>
</template>

<script>
import AppTransition from "./Transition"

export default {
  components: { AppTransition },
  props: {
    contentStyles: {
      type: [String, Object],
      default: null,
    },
    x: {
      type: String,
      default: "right",
    },
    disabled: Boolean,
    stretch: Boolean,
    dropdown: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpened: false,
    }
  },
  computed: {
    contentClassName() {
      return {
        [`dropdown__content--align-${this.x}`]: this.x,
      }
    },
  },
  watch: {
    $route() {
      this.isOpened = false
    },
  },
  methods: {
    onClick() {
      if (this.disabled) {
        return
      }
      this.isOpened = !this.isOpened
      this.$emit("toggle", this.isOpened)
    },
    close() {
      this.isOpened = false
    },
    onClickOutside(ev) {
      if (this.$refs.trigger) {
        if (ev.target === this.$refs.trigger || this.$refs.trigger.contains(ev.target)) {
          return
        }
      }
      this.isOpened = false
    },
  },
}
</script>

<style scoped lang="scss">
.dropdown {
  position: relative;
  display: inline-block;
  &:not(.dropdown--disabled) {
    .dropdown__trigger {
      cursor: pointer;
    }
  }
  &--stretch {
    .dropdown__trigger,
    .dropdown__content {
      width: 100%;
    }
  }
  &__content {
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 15px;
    background: var(--text-white);
    border-radius: 4px;
    box-shadow: 0px 3px 31px rgba(0, 0, 0, 0.14);
    z-index: 1;
    color: var(--text-color);
    max-height: 300px;
    overflow: scroll;
    &--align-center {
      transform: translateX(50%);
      right: 50%;
    }
  }
}
</style>
