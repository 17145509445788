import required from "vuelidate/lib/validators/required"
import email from "vuelidate/lib/validators/email"
import i18n from "../i18n"

export default {
  validations: {
    form: {
      username: { required, email },
      password: { required },
    },
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
        rememberMe: true,
      },
      errors: {
        username: "",
        password: "",
      },
    }
  },

  methods: {
    cleanErrors() {
      this.errors = {
        username: "",
        password: "",
      }
    },
    formValid() {
      this.cleanErrors()
      this.$v.$touch()
      const form = this.$v.form
      if (!form.username.required) {
        this.errors.username = i18n.t("Field is required")
      }
      if (!form.password.required) {
        this.errors.password = i18n.t("Field is required")
      }
      if (form.username.required && !form.username.email) {
        this.errors.username = i18n.t("E-mail has wrong format")
      }
      return form.username.required && form.username.email && form.password.required
    },
  },
}
