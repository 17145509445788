<template>
  <div class="alert" :class="[`alert--${type}`, !status && 'alert--dense']">
    <div class="alert__icon">
      <svgicon v-if="enableIcon" :name="icon" />
    </div>
    <div class="alert__info">
      <div v-if="status" class="alert__status">
        {{ status }}
      </div>
      <div class="alert__message-box">
        <span class="alert__message" v-html="message" />
        <span class="alert__notice">
          <app-tooltip v-if="info" position-y="top">
            <svgicon name="question_circle" class="svg-question-circle" />
            <template #content>
              {{ info }}
            </template>
          </app-tooltip>
        </span>
      </div>
    </div>
    <div v-if="action" class="alert__action" @click="close">
      <app-button variant="outlined-invert" size="small" type="button" @click="action()">
        <span class="no-wrap">
          {{ actionName }}
        </span>
      </app-button>
    </div>
    <div v-if="closable" class="alert__close" @click="close">
      <svgicon name="cross-red" />
    </div>
  </div>
</template>

<script>
import AppTooltip from "@/components/ui/Tooltip"
import AppButton from "@/components/ui/AppButton.vue"

export default {
  components: { AppButton, AppTooltip },
  props: {
    type: {
      type: String,
      default: "success",
    },
    message: {
      type: String,
      required: true,
    },
    status: {
      type: [Number, String],
      default: "",
    },
    closable: {
      type: Boolean,
      default: true,
    },
    timer: {
      type: Number,
      default: 5000,
    },
    info: {
      type: String,
      default: "",
    },
    enableIcon: {
      type: Boolean,
      default: true,
    },
    action: {
      type: Function,
      default: null,
    },
    actionName: {
      type: String,
      default: null,
    },
  },
  computed: {
    icon() {
      return this.type === "success"
        ? "check_circle"
        : this.type === "warning"
        ? "error_outline"
        : this.type === "info"
        ? "info"
        : "warning_triangle"
    },
  },
  created() {
    if (this.timer) {
      this.timeout = setTimeout(() => {
        this.close()
      }, this.timer)
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  },
  methods: {
    close() {
      this.$emit("close")
    },
  },
}
</script>

<style scoped lang="scss">
.alert {
  display: flex;
  border-left: 4px solid currentColor;
  border-radius: 4px;
  padding: 15px 15px 16px 16px;
  align-items: center;
  &__icon {
    margin-right: 18px;
    font-size: 24px;
  }
  &__info {
    flex: 1 1 auto;
  }
  &__message {
    color: var(--text-color);
    font-size: 12px;
    font-weight: 600;
    &-box {
      display: flex;
      align-items: center;
    }
  }
  &__status {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.3px;
    margin-bottom: 8px;
  }
  &__notice {
    font-size: 24px;
    margin-left: 10px;
    display: flex;
  }
  &__close {
    font-size: 18px;
    cursor: pointer;
  }
  &--dense {
    padding-top: 11px;
    padding-bottom: 11px;
    .alert__icon {
      margin-right: 11px;
    }
  }
  &--success {
    background: var(--alert-success-bg);
    color: var(--alert-success);
  }
  &--info {
    background: var(--alert-info-bg);
    color: var(--primary-color);
  }
  &--warning {
    background: var(--alert-warning-bg);
    color: var(--alert-warning);
  }
  &--error {
    background: var(--alert-error-bg);
    color: var(--color-red);
  }
  &--display {
    background: var(--light-grey);
    color: var(--sidebar-color);
  }
  &.biggest {
    .alert__message {
      font-size: 14px;
      line-height: 1.3;
    }
  }
  &__action {
    font-size: 12px;
    margin-right: 20px;
  }
}
</style>
