<template>
  <div class="notification-card">
    <div class="notification-card__avatar">
      <user-avatar v-if="username" size="32" :username="username" colored />
    </div>
    <div class="notification-card__body">
      <div class="notification-card__header">
        <div class="notification-card__title text-headline6">
          {{ title }}
        </div>
        <div class="notification-card__tools">
          <div class="notification-card__remove" @click="$emit('remove', id)">
            <svgicon name="cross-red" />
          </div>
          <div class="notification-card__status">
            <app-tooltip>
              <div
                class="notification-card__indicator indicator"
                :class="!viewed && 'indicator--active'"
                @click="changeStatus"
              />
              <template #content>
                {{ viewed ? $t("Mark as unread") : $t("Mark as read") }}
              </template>
            </app-tooltip>
          </div>
        </div>
      </div>
      <div class="notification-card__info text-subtitle2 text-color-secondary">
        {{ text }}
      </div>
      <div class="notification-card__time text-body2 text-color-secondary">
        {{ receivedAgo }}
      </div>
    </div>
  </div>
</template>

<script>
import TimeAgo from "javascript-time-ago"
import UserAvatar from "@/components/ui/UserAvatar"
import AppTooltip from "@/components/ui/Tooltip"

export default {
  components: { UserAvatar, AppTooltip },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    date: {
      type: [Date, String],
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    username: {
      type: String,
      default: "",
    },
    viewed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formatter: new TimeAgo("en-US"),
    }
  },
  computed: {
    receivedAgo() {
      const normalizedDate = this.$utils.normalizeDate(this.date)
      return this.formatter.format(normalizedDate)
    },
  },
  methods: {
    changeStatus() {
      if (this.viewed) {
        this.$emit("unread", this.id)
      } else {
        this.$emit("read", this.id)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.notification-card {
  display: flex;
  border-radius: 4px;
  padding: 5px 5px 10px 5px;
  transition: var(--transition-fast);
  &:hover {
    background: var(--background-color);
    .notification-card__remove,
    .notification-card__indicator {
      opacity: 1;
    }
  }
  &__avatar {
    flex: 0 0 auto;
    margin-right: 7px;
  }
  &__body {
    flex: 1 1 100%;
    padding-top: 3px;
  }
  &__header {
    display: flex;
  }
  &__title {
    flex: 1 1 100%;
  }
  &__tools {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
  }
  &__remove {
    color: rgba(42, 42, 75, 0.4);
    font-size: 12px;
    margin-right: 10px;
    cursor: pointer;
    opacity: 0;
    transition: var(--transition-fast);
  }
  &__indicator {
    cursor: pointer;
    transition: var(--transition-fast);
  }
  &__indicator:not(.indicator--active) {
    opacity: 0;
  }
  &__info {
    margin-top: 3px;
    letter-spacing: 0.1px;
    margin-bottom: 7px;
  }
  &__time {
    letter-spacing: 0.2px;
  }
}
.indicator {
  width: 16px;
  height: 16px;
  background: rgba(37, 84, 255, 0.2);
  position: relative;
  border-radius: 50%;
  &--active {
    &:after {
      background: var(--primary-color);
    }
  }
  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    border: 1px solid var(--primary-color);
    width: 60%;
    height: 60%;
    border-radius: 50%;
  }
}
</style>