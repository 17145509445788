<template>
  <base-input
    :label="label"
    :error="error"
    :verification="verification"
    :notice="notice"
    :show-error-message="showErrorMessage"
    :size="size"
    :disabled="disabled"
    :clear-styles="clearStyles"
  >
    <template #label>
      <slot name="label" />
    </template>
    <template #default="{ id, borderClass, elementClass }">
      <div
        class="text-field__container"
        :class="{ [`text-field__container--theme-${theme}`]: theme }"
      >
        <div
          v-if="prefilledText && type !== 'textarea'"
          ref="prefilled"
          class="text-field__prefilled-text"
        >
          {{ prefilledText }}
        </div>
        <input
          v-if="type !== 'textarea'"
          :id="id"
          ref="input"
          v-mask="htmlMask"
          :class="elementClass"
          :type="htmlType"
          autocomplete="off"
          v-bind="$attrs"
          :value="value"
          :maxlength="limit"
          :placeholder="prefilledText ? '' : placeholder"
          :style="inputStyles"
          :disabled="disabled"
          v-on="listeners"
          @input.capture="onInputCapture"
          @input="onInput"
          @paste="onPaste"
          @change="onChange"
        />
        <textarea
          v-else
          :id="id"
          ref="input"
          :class="elementClass"
          v-bind="$attrs"
          :value="value"
          :maxlength="limit"
          :placeholder="placeholder"
          :disabled="disabled"
          v-on="listeners"
          @input.capture="onInputCapture"
          @input="onInput"
          @paste="onPaste"
        />
        <div
          v-if="type === 'number' && showStepper"
          class="text-field__stepper"
          :class="borderClass"
          @mousedown.prevent
        >
          <div v-repeat-click="increase" class="text-field__stepper-up" :class="borderClass">
            <span>
              <svgicon name="arrow_drop_down" />
            </span>
          </div>
          <div v-repeat-click="decrease" class="text-field__stepper-down">
            <span>
              <svgicon name="arrow_drop_down" />
            </span>
          </div>
        </div>
        <div v-if="limit && showLimit" class="text-field__limit">
          {{ value.length }}/{{ limit }}
        </div>
        <div
          v-if="type === 'password'"
          class="text-field__append-icon text-field__password-reveal"
          @click="isRevealed = !isRevealed"
        >
          <svgicon :name="isRevealed ? 'eye-show' : 'eye-hide'" />
        </div>
        <div v-if="appendText" class="text-field__append-icon text-field__append-text">
          <slot name="appendText">
            {{ appendText }}
          </slot>
        </div>
      </div>
    </template>
    <template #label-append>
      <slot name="label-append" />
    </template>
    <template #notice>
      <slot name="notice" />
    </template>
  </base-input>
</template>

<script>
import BaseInput from "./BaseInput"
const charactersRegexp = /[\^<>!@#$%&*()[\]\\'"/,.:;`|\-_=+?№]+/gi
const masks = {
  ip: {
    mask: "otk",
    tokens: {
      otk: {
        pattern: /((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}/,
      },
    },
  },
  creditCard: "#### #### #### ####",
  date: {
    mask: "d#/m#/y###",
    tokens: {
      d: {
        pattern: /0|1|2|3/,
      },
      m: {
        pattern: /0|1/,
      },
      y: {
        pattern: /2/,
      },
    },
  },
  numbers: {
    mask: "###",
  },
  price: "######",
}
export default {
  components: { BaseInput },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    theme: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    verification: {
      type: Boolean,
      default: false,
    },
    showStepper: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showErrorMessage: {
      type: Boolean,
      default: true,
    },
    showLimit: {
      type: Boolean,
      default: true,
    },
    notice: {
      type: [String, Boolean],
      default: "",
    },
    mask: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    prefilledText: {
      type: String,
      default: "",
    },
    limit: {
      type: [Number, String],
      default: null,
    },
    clearStyles: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "",
    },
    appendText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isRevealed: false,
      lastValue: this.value,
      leftOffset: 0,
      ipMaskStyle: 0,
    }
  },
  computed: {
    htmlMask() {
      if (this.mask === "ip") {
        return masks.ip[this.ipMaskStyle]
      }
      return this.mask && masks[this.mask] ? masks[this.mask] : this.mask || ""
    },
    listeners() {
      /* eslint-disable-next-line */
      const { input, paste, change, ...listeners } = this.$listeners
      return listeners
    },
    htmlType() {
      const isText =
        this.type === "alphanumeric" ||
        this.type === "text" ||
        this.type === "number" ||
        this.type === "date" ||
        (this.type === "password" && this.isRevealed)
      return isText ? "text" : this.type
    },
    inputStyles() {
      return this.leftOffset
        ? {
            paddingLeft: `${this.leftOffset + 16}px`,
          }
        : null
    },
  },
  watch: {
    prefilledText: {
      handler: function () {
        this.$nextTick(() => {
          this.setLeftOffset()
        })
      },
      immediate: true,
    },
  },
  created() {
    if (this.value && this.mask === "ip") {
      this.findIpMask(this.value)
    }
  },
  methods: {
    onInputCapture(ev) {
      if (this.mask !== "ip") {
        return
      }
      if (!ev.target.__previousValue) {
        ev.target.__previousValue = ""
      }
      const isInitialEvent = ev.data !== undefined
      if (this.mask === "ip") {
        const periods = ev.target.value.split(".")
        const isValid = periods.every((period) => !period || +period <= 255)
        if (!isValid) {
          ev.target.value = ev.target.__previousValue
          return
        }
        const normalizedValue = this.normalizeIpValue(ev.target.value)
        let isEditLastPeriod
        if (isInitialEvent) {
          isEditLastPeriod =
            ev.target.__previousValue.endsWith(".") && !ev.target.value.endsWith(".")
        }
        if (ev.data || (isInitialEvent && !isEditLastPeriod)) {
          this.findIpMask(normalizedValue)
          this.$emit("input", ev.target.value)
        }
      }
      if (isInitialEvent) {
        ev.target.__previousValue = ev.target.value
      }
    },
    onInput(ev) {
      let value = ev.target.value
      if (this.type === "number" && !this.isNumber(value)) {
        value = value.replace(/[^\d.]/, "")
        ev.target.value = value
      }
      if (this.type === "alphanumeric" && this.hasSpecialCharacters(value)) {
        value = value.replace(charactersRegexp, "")
        ev.target.value = value
      }
      this.$emit("input", value)
    },
    normalizeIpValue(value) {
      let over = ""
      return value
        .split(".")
        .map((group) => {
          const combinedGroup = over + group
          if (combinedGroup.length > 3) {
            over = combinedGroup.slice(3)
            return combinedGroup.slice(0, 3)
          } else {
            over = ""
            return combinedGroup
          }
        })
        .join(".")
    },
    setLeftOffset() {
      if (this.$refs.prefilled) {
        this.leftOffset = this.$refs.prefilled.offsetWidth
      }
    },
    onPaste(ev) {
      const text = ev.clipboardData.getData("text")
      if (this.type === "number" && !this.isNumber(text)) {
        ev.preventDefault()
      }
      if (this.type === "alphanumeric" && this.hasSpecialCharacters(text)) {
        ev.preventDefault()
      }
      if (this.mask === "ip") {
        this.findIpMask(text)
      }
      return this.$emit("paste", ev)
    },
    findIpMask(value) {
      value = value.replace(/\d/gi, "#")
      const maskId =
        masks.ip instanceof Array ? masks.ip.findIndex((mask) => mask.startsWith(value)) : null
      this.ipMaskStyle = maskId === -1 ? this.ipMaskStyle : maskId
    },
    onChange(ev) {
      if (this.type !== "number") {
        return
      }
      if (!this.isNumber(ev.target.value)) {
        this.$emit("input", "")
      }
    },
    isNumber(value) {
      return (!isNaN(+value) || isFinite(value)) && !/[\s]+/.test(value)
    },
    hasSpecialCharacters(value) {
      return charactersRegexp.test(value)
    },
    increase() {
      if (this.type !== "number") {
        return
      }
      const initialValue = parseInt(this.value) || 0
      this.$emit("input", initialValue + 1)
    },
    decrease() {
      if (this.type !== "number") {
        return
      }
      const initialValue = parseInt(this.value) || 0
      this.$emit("input", initialValue - 1)
    },
    focus() {
      if (this.$refs.input) {
        this.$refs.input.focus()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.text-field {
  &__container {
    &--theme-highlight {
      input {
        background: rgba(255, 187, 18, 0.08);
      }
    }
  }
  &__prefilled-text {
    position: absolute;
    top: 18px;
    left: 16px;
    color: var(--text-color);
    font-weight: 600;
    pointer-events: none;
  }
  &__append-icon {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 24px;
    color: var(--primary-dark-color);
  }
  &__append-text {
    font-size: 14px;
    right: 8px;
    cursor: default;
    pointer-events: none;
  }
  &__password-reveal {
    opacity: 0.5;
  }
  &__stepper {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-left-width: 1px;
    border-left-style: solid;
    &-up,
    &-down {
      height: 50%;
      display: flex;
      align-items: center;
      font-size: 24px;
      cursor: pointer;
      padding: 1px 4px;
      span {
        display: flex;
      }
    }
    &-up {
      border-bottom-width: 1px;
      border-bottom-style: solid;
      span {
        transform: rotate(180deg);
        transform-origin: center center;
      }
    }
  }
  &__limit {
    text-align: right;
    margin-top: 3px;
    font-weight: 600;
    color: var(--text-grey);
    opacity: 0.7;
  }
}
</style>
