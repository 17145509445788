import Vue from "vue"
import store from "@/store"

const actions = {
  async getRentsData(app, data) {
    return await Vue.axios.get(`/widget/renting/${data.organization_uid}`, {
      params: {
        organization_uid: data.organization_uid,
      },
    })
  },
  async getUtilizingData(app, data) {
    return await Vue.axios.get(`/widget/utilizing/${data.organization_uid}`, {
      params: {
        organization_uid: data.organization_uid,
      },
    })
  },
  async getResourcesData(app, data) {
    return await Vue.axios.get(`/widget/resources/${data.organization_uid}`, {
      params: {
        organization_uid: data.organization_uid,
      },
    })
  },
  async getWorkflowList(app, data) {
    return await Vue.axios.get(`/workflow/list`, {
      params: {
        organization_uid: data.organization_uid,
      },
    })
  },
  async getBillingList(app, data) {
    return await Vue.axios.get(`/widget/billing/${data.organization_uid}`, {
      params: {
        organization_uid: data.organization_uid,
      },
    })
  },
  async getLastIPBlocks(app, data) {
    return await Vue.axios.get(`/widget/last_inetnums`, {
      params: {
        organization_uid: data.organization_uid,
      },
    })
  },
}
export default {
  namespaced: true,
  actions,
}
