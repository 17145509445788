<template>
  <div>
    <ConfirmationModal
      :is-opened="opened"
      v-bind="confirmationAttributes"
      :loading="loading"
      @confirmed="confirmed"
      @toggle="close"
    ></ConfirmationModal>
  </div>
</template>

<script>
import ConfirmationModal from "../ConfirmationModal"

export default {
  components: { ConfirmationModal },
  data() {
    return {
      opened: false,
      confirmationAttributes: null,
      loading: false,
    }
  },
  computed: {
    confirmationOpened() {
      return this.$store.state.core.confirmationModal.opened
    },
    confirmationOptions() {
      return this.$store.state.core.confirmationModal.configuration
    },
  },
  watch: {
    confirmationOpened: function (isOpened) {
      if (isOpened === true) {
        this.opened = true
      }
    },
    confirmationOptions: function (options) {
      this.confirmationAttributes = options
    },
  },
  methods: {
    async confirmed(data) {
      const confirmation = this.$store.state.core.confirmationModal.confirmation
      console.log(confirmation, "confirmation")
      this.loading = true
      await confirmation(data)
      this.loading = false
      await this.close()
    },
    async close() {
      this.opened = false
      await this.clearStorage()
    },
    async clearStorage() {
      await this.$store.dispatch("core/toggleConfirmationModalOpened", {})
    },
  },
}
</script>

<style scoped lang="scss">
</style>
