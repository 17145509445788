<template>
  <div class="rent-item">
    <div class="rent-item--header">
      <div class="rent-item--header-title">
        {{ orderTitle }}
      </div>
      <div class="rent-item--header-supplier">
        {{ orderOverview.supplier }}
      </div>
    </div>
    <div
      v-for="(network, index) in orderOverview.networks_data"
      :key="index + 'rent-network' + network.network"
    >
      <div class="rent-item--network">
        <div class="rent-item--network-network">
          <country-flag
            v-if="getCountry(network)"
            :country="getCountry(network).iso"
            size="small"
          />
          <span
            :class="{
              'rent-item--network-network-unavail': !network.available,
            }"
          >
            {{ network.network }}
          </span>
        </div>
        <div v-if="network.available" class="rent-item--network-price">
          {{ network.price | price }}
        </div>
        <div v-else class="rent-item--network-network-alert">Unavailable</div>
        <div class="rent-item--network-remove" @click="$emit('delete', network)">
          <svgicon :name="'cross-red'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag"
import networks from "@/utils/networks"

export default {
  components: { CountryFlag },
  props: {
    orderOverview: {
      type: Object,
      required: true,
    },
    orderTitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: [],
    }
  },
  computed: {
    hasActiveChildren() {
      if (!this.nestedLinks || !this.nestedLinks.length) {
        return false
      }
      return this.nestedLinks.some((link) => {
        const currentPath = this.$route.path
        const linkPath = this.$router.resolve(link.to).href
        return currentPath.startsWith(linkPath)
      })
    },
  },
  methods: {
    getCountry(network) {
      let countryInfo = networks.getCountry(network, this.$route, "MaxMind")
      if (!countryInfo || !countryInfo.iso) {
        countryInfo = networks.getCountry(network, this.$route, "IP2Location")
      }
      if (!countryInfo || !countryInfo.iso) {
        countryInfo = networks.getCountry(network, this.$route, "DbIp")
      }
      return countryInfo
    },
  },
}
</script>

<style scoped lang="scss">
.rent-item {
  font-family: var(--font-secondary);
  color: var(--sidebar-color);
  background: #252546;
  &--header {
    background: #2e2e63;
    font-family: var(--font-secondary);
    display: flex;
    padding: 12px 15px;
    align-items: center;
    font-size: 14px;
    justify-content: center;
    letter-spacing: 1px;
    &-title {
      text-transform: uppercase;
    }
    &-supplier {
      margin-left: 5px;
    }
  }
  &--network {
    font-size: 13px;
    display: flex;
    padding: 15px 10px;
    justify-content: space-between;
    align-items: center;

    &-price {
      color: var(--sidebar-active-color);
    }
    &-remove {
      color: #9090d9;
      cursor: pointer;
    }
    &-network {
      display: flex;
      align-items: center;
      &-unavail {
        color: grey;
        font-size: 10px;
      }
      &-alert {
        color: red;
      }
    }
  }
}
</style>
