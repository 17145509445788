/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cross-red': {
    width: 10,
    height: 11,
    viewBox: '0 0 10 11',
    data: '<path pid="0" d="M9.667 1.773l-.94-.94L5 4.56 1.273.833l-.94.94L4.06 5.5.333 9.227l.94.94L5 6.44l3.727 3.727.94-.94L5.94 5.5l3.727-3.727z" _fill="#EE1B4B"/>'
  }
})
