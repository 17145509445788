import Vue from "vue"

const actions = {
  async updatePrice(app, data) {
    return await Vue.axios.post(
      `/networks/update_price/${data.organization_uid}/${data.inetnum_uid}`,
      data
    )
  },
  async updateAgreement(app, data) {
    return await Vue.axios.post(
      `/networks/update_agreement/${data.organization_uid}/${data.inetnum_uid}`,
      data
    )
  },
  async updateAgreements(app, data) {
    return await Vue.axios.post(`/networks/update_agreements/${data.organization_uid}`, data)
  },
  async downloadContract(app, data) {
    return await Vue.axios.get(
      `/networks/download_contract/${data.organization_uid}/${data.inetnum_uid}`
    )
  },
  async orderLeasingPeriodsParams(app) {
    return await Vue.axios.get(`/shared/periods`)
  },
  async networkDetail(app, data) {
    let url = `/networks/${data.organization_uid}/${data.inetnum_uid}`
    if (data.cidr) {
      url += `?cidr=${data.cidr}`
    }
    return await Vue.axios.get(url)
  },
  async updateNetwork(app, data) {
    return await Vue.axios.patch(`/networks/${data.organization_uid}/${data.inetnum_uid}`, data)
  },
  async getProperties(app) {
    return await Vue.axios.get(`/properties/list?entity=inetnum`)
  },
}
export default {
  namespaced: true,
  actions,
}
