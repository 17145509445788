<template>
  <div class="access-page">
    <router-link class="access-page__back-link" :to="`${$utils.i18nLink('/login')}`">
      <img
        class="access-page__back-link-image"
        :src="require('@/assets/svg/arrow-back.svg')"
        :alt="'Back to login'"
      />
      <span class="access-page__back-link-title"
        >{{ $t("Back to") }}
        <span class="access-page__pseudo-link">{{ $t("Login") }}</span>
      </span>
    </router-link>
    <Logo class="access-page__logo" />
    <div class="access-page__wrapper">
      <h1 class="access-page__title">
        {{ $t("Recovery password") }}
      </h1>
      <div class="access-page__description">
        {{ $t("Forgot password? Enter your email address and we will send you a new password") }}
      </div>
      <div class="access-page__notifications">
        <AlertContainer type="auth" />
      </div>
      <form class="access-page__form">
        <div class="access-page__form-line">
          <app-input
            v-model="form.email"
            type="email"
            :label="$t('E-mail')"
            :error="errors.email"
          />
        </div>

        <app-button :loading="loading" type="submit" size="wide" @click="recovery">
          {{ $t("Send code") }}
        </app-button>
      </form>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/ui/AppButton"
import AppInput from "@/components/ui/forms/TextField"
import Logo from "@/components/shared/Logo"
import AlertContainer from "@/components/shared/Alert"
import recoveryForm from "@/mixins/recoveryForm"

export default {
  name: "PageLogin",
  components: {
    AppButton,
    AppInput,
    Logo,
    AlertContainer,
  },
  mixins: [recoveryForm],
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async recovery() {
      if (!this.formValid()) {
        return
      }
      try {
        this.loading = true
        const encoded = encodeURIComponent(this.form.email)
        try {
          await this.$store.dispatch("user/recoveryPassword", this.form)
          await this.$router.replace(`/change-password?email=${encoded}`)
          this.$alert({
            type: "success",
            message: this.$gettext("Code has been sent to your email address."),
            layout: "auth",
            stacked: false,
          })
        } finally {
          this.loading = false
        }
      } catch (request) {
        this.$alert({
          type: "error",
          message: this.$t("Please, check code. Or get back to previous form to send it again"),
          layout: "auth",
          stacked: false,
        })
      }
    },
  },
}
</script>
