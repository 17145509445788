<template>
  <div :class="{ 'checkbox--checked': !!value, 'checkbox--disabled': disabled }" class="checkbox">
    <label class="checkbox__label">
      <input
        :value="value"
        :checked="value"
        :disabled="disabled"
        :readonly="readonly"
        :class="[classEl, 'checkbox__input']"
        type="checkbox"
        @input="$emit('input', $event.target.checked)"
      />

      <span class="checkbox__box" />

      <span class="checkbox__text text-subtitle1">
        <slot>
          {{ label }}
        </slot>
      </span>
    </label>
    <div v-if="error" class="checkbox__error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    classEl: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    disabled: Boolean,
    readonly: Boolean,
    error: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped lang="scss">
.checkbox {
  &--disabled {
    .checkbox__label {
      cursor: default;
    }
    opacity: 0.75;
  }
  &__error {
    color: var(--color-red);
    margin-top: 5px;
  }
}
</style>
