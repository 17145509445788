/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'question_circle': {
    width: 15,
    height: 24,
    viewBox: '0 0 15 24',
    data: '<circle pid="0" cx="7.5" cy="11.5" r="7.5" _fill="#EE1B4B"/><path pid="1" d="M6.935 13.965v-.438c0-.501.09-.918.273-1.25.182-.333.501-.677.957-1.033.542-.428.891-.76 1.046-.998.16-.237.24-.52.24-.848 0-.382-.128-.676-.384-.881-.255-.205-.622-.308-1.1-.308-.433 0-.834.062-1.203.185-.37.123-.73.27-1.08.444l-.575-1.203a6.018 6.018 0 012.974-.773c.893 0 1.602.22 2.126.657.524.437.786 1.04.786 1.811 0 .342-.05.647-.15.916-.1.264-.253.517-.458.759-.2.241-.55.556-1.046.943-.424.333-.709.609-.855.827-.141.22-.212.513-.212.882v.308h-1.34zm-.28 2.18c0-.688.334-1.032 1.004-1.032.328 0 .579.091.752.274.173.178.26.43.26.758 0 .324-.089.582-.267.773-.173.187-.421.28-.745.28-.323 0-.572-.09-.745-.273-.173-.187-.26-.447-.26-.78z" _fill="#fff"/>'
  }
})
