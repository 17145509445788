<template>
  <div class="avatar" :class="[bordered && 'avatar--bordered']" :style="styles">
    <slot>
      <img v-if="!isEmpty" :src="src" @error="error = true" />
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: "",
    },
    size: {
      type: [Number, String],
      default: 36,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: null,
    }
  },
  computed: {
    styles() {
      return {
        width: this.size + "px",
        height: this.size + "px",
      }
    },
    isEmpty() {
      return !this.src || this.error
    },
  },
}
</script>

<style scoped lang="scss">
.avatar {
  overflow: hidden;
  border-radius: 50%;
  transition: var(--transition-fast);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-white);
  &--bordered {
    border: 2px solid var(--text-white);
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>