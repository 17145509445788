/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'spinner': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M4.136 7a.636.636 0 00-.636-.636H.954a.636.636 0 000 1.272H3.5A.636.636 0 004.136 7zM13.045 6.364h-1.272a.636.636 0 000 1.272h1.272a.636.636 0 100-1.272zM7.318 3.818a.636.636 0 00.636-.636V.636a.636.636 0 00-1.272 0v2.546c0 .351.285.636.636.636zM7.318 10.182a.636.636 0 00-.636.636v2.546a.636.636 0 001.273 0v-2.546a.636.636 0 00-.637-.636zM3.268 2.05a.636.636 0 00-.9.9l1.8 1.8a.634.634 0 00.9 0 .636.636 0 000-.9l-1.8-1.8zM10.468 9.25a.636.636 0 00-.9.9l1.8 1.8a.634.634 0 00.9 0 .636.636 0 000-.9l-1.8-1.8zM4.168 9.25l-1.8 1.8a.636.636 0 10.9.9l1.8-1.8a.636.636 0 10-.9-.9z" _fill="#2554FF"/>'
  }
})
