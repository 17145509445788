export const NOTIFICATIONS = "notifications"
export const GET_NOTIFICATIONS = "getNotifications"
export const SET_NOTIFICATIONS = "setNotifications"
export const SET_UNREAD_NOTIFICATION = "setUnreadNotification"
export const SET_READ_NOTIFICATIONS = "setReadNotifications"
export const DELETE_NOTIFICATION = "deleteNotification"
export const GET_UNREAD_NOTIFICATION_COUNT = "getUnreadNotificationCount"
export const SET_UNREAD_NOTIFICATION_COUNT = "setUnreadNotificationCount"
export const UNREAD_NOTIFICATION_COUNT = "unreadNotificationCount"
export const GROUPED_NOTIFICATIONS = "groupedNotifications"
