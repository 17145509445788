<template>
  <div>
    <content-loader
      view-box="0 0 300 200"
      :speed="1"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <template v-for="i in contentLines">
        <rect
          :key="'card-rect-content-loaded' + i"
          x="0"
          :y="(i - 1) * getGap"
          rx="3"
          ry="3"
          width="10%"
          :height="height"
        />
        <rect
          :key="'card-rect-content-loaded-next' + i"
          x="15%"
          :y="(i - 1) * getGap"
          rx="3"
          ry="3"
          width="90%"
          :height="height"
        />
      </template>
    </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader"
export default {
  name: "CardPreloader",
  components: { ContentLoader },
  props: {
    contentLines: {
      type: Number,
      default: 5,
    },
    height: {
      type: Number,
      default: 12,
    },
  },
  computed: {
    getGap() {
      return this.height * 2
    },
  },
}
</script>
