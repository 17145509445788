<template>
  <div>
    <div class="access-page">
      <Logo class="access-page__logo" />
      <div class="access-page__wrapper">
        <h1 class="access-page__title">
          {{ $t("Create Account") }}
        </h1>
        <div class="access-page__description">
          {{ $t("It's free and only takes a few minutes") }}
        </div>
        <div class="access-page__notifications">
          <AlertContainer type="auth" />
        </div>
        <form class="access-page__form">
          <div class="access-page__form-line">
            <app-input
              v-model="form.email"
              :label="$t('E-mail')"
              type="email"
              :error="errors.email"
            />
          </div>
          <div class="access-page__form-line">
            <app-input
              v-model="form.password"
              :label="$t('Password')"
              type="password"
              :error="errors.password"
            />
            <div class="access-page__remember-me">
              <app-checkbox
                v-model="form.accept"
                :error="errors.accept"
                :value="form.accept"
                @input="errors.accept = ''"
              >
                {{ $t("I accept the") }}
                <a target="_blank" href="https://interlir.com/terms-of-service/">
                  {{ $t("Terms of use") }}</a
                >
                {{ $t("and") }}
                <a target="_blank" href="https://interlir.com/privacy-policy">{{
                  $t("Privacy Policy")
                }}</a>
              </app-checkbox>
            </div>
          </div>

          <app-button :loading="loading" type="submit" size="wide" @click="register">
            {{ $t("Register now") }}
          </app-button>

          <div class="access-page__links-description">
            {{ $t("or register with") }}
          </div>

          <div class="access-page__links">
            <o-auth :disabled="!form.accept" @onDisabledClicked="googleValidation" />
          </div>

          <div class="access-page__register-account">
            <div class="access-page__note">
              {{ $t("Have an account? Login") }}
              <router-link to="/login" class="access-page__route">
                {{ $t("here") }}
              </router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/ui/AppButton"
import AppInput from "@/components/ui/forms/TextField"
import AppCheckbox from "@/components/ui/AppCheckbox"
import OAuth from "@/components/shared/OAuth"
import Logo from "@/components/shared/Logo"
import AlertContainer from "@/components/shared/Alert"
import registerForm from "@/mixins/registerForm"

export default {
  name: "PageRegistration",
  components: {
    AppButton,
    AppInput,
    AppCheckbox,
    OAuth,
    Logo,
    AlertContainer,
  },
  mixins: [registerForm],
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async googleValidation() {
      this.acceptValid()
    },
    async register() {
      if (!this.formValid()) {
        console.log(this.errors)
        return
      }
      try {
        this.loading = true
        const encoded = encodeURIComponent(this.form.email)
        await this.$auth.register({
          data: this.form,
          redirect: `/login?registered=${encoded}`,
        })
        this.$alert({
          type: "success",
          message: this.$t("Please, check your mail"),
          layout: "auth",
          stacked: false,
        })
      } catch (request) {
        const data = request.response.data
        if (data["detail"] === "REGISTER_USER_ALREADY_EXISTS") {
          this.$alert({
            type: "error",
            message: this.$t("User with given email address already exist"),
            layout: "auth",
            stacked: false,
          })
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
