/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
    'download': {
        width: 52,
        height: 52,
        viewBox: '0 0 52 52',
        data: '<g opacity="0.7">\n' +
            '<rect width="52" height="52" rx="26"/>\n' +
            '<path d="M32.4998 17.3333L19.4998 17.3333L19.4998 30.3333L10.8332 30.3333L25.9998 45.5L41.1665 30.3333L32.4998 30.3333L32.4998 17.3333ZM41.1665 13L10.8332 13L10.8332 8.6667L41.1665 8.6667L41.1665 13Z" _fill="#2554FF"/>\n' +
            '</g>'
    }
})
