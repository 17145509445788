/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'chart': {
    width: 12,
    height: 13,
    viewBox: '0 0 12 13',
    data: '<path pid="0" d="M1.474 6.42h1.964v4.42H1.474V6.42zM8.35 4.455h1.965v6.386H8.35V4.455zM4.912 1.508h1.965v9.333H4.912V1.508z" _fill="#9090D9"/>'
  }
})
