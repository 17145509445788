/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
    'remove-card': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<g clip-path="url(#clip0_2_11)">\n' +
            '<path d="M13.3333 2.66667H2.66667C1.92667 2.66667 1.34 3.26 1.34 4L1.33333 12C1.33333 12.74 1.92667 13.3333 2.66667 13.3333H13.3333C14.0733 13.3333 14.6667 12.74 14.6667 12V4C14.6667 3.26 14.0733 2.66667 13.3333 2.66667ZM13.3333 12H2.66667V8H13.3333V12ZM13.3333 5.33333H2.66667V4H13.3333V5.33333Z" fill="#9090D9"/>\n' +
            '<circle cx="12.3333" cy="11.6667" r="3" fill="#9090D9"/>\n' +
            '</g>\n' +
            '<path d="M11.1834 10.5167C11.4056 10.2945 11.7659 10.2945 11.9882 10.5167L13.5976 12.1262C13.8199 12.3484 13.8199 12.7087 13.5976 12.931V12.931C13.3754 13.1532 13.0151 13.1532 12.7929 12.931L11.1834 11.3215C10.9612 11.0993 10.9612 10.739 11.1834 10.5167V10.5167Z" fill="white"/>\n' +
            '<path d="M11.1834 12.931C10.9612 12.7087 10.9612 12.3484 11.1834 12.1262L12.7929 10.5167C13.0151 10.2945 13.3754 10.2945 13.5976 10.5167V10.5167C13.8199 10.739 13.8199 11.0993 13.5976 11.3215L11.9882 12.931C11.7659 13.1532 11.4056 13.1532 11.1834 12.931V12.931Z" fill="white"/>\n' +
            '<defs>\n' +
            '<clipPath id="clip0_2_11">\n' +
            '<rect width="16" height="16" fill="white"/>\n' +
            '</clipPath>\n' +
            '</defs>\n' +
            '</svg>'
    }
})
