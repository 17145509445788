<template>
  <transition :name="name">
    <slot />
  </transition>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "fade",
      validator(transition) {
        return ["fade"].includes(transition)
      },
    },
  },
}
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>