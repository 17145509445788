<template>
  <transition-group
    name="list"
    class="alert-container"
    :class="alerts.length && 'alert-container__offset'"
    v-bind="$attrs"
    tag="div"
  >
    <app-alert
      v-for="alert in alerts"
      :key="alert.id"
      :type="alert.type"
      :message="alert.message"
      :info="alert.info"
      :closeable="alert.closeable"
      :timer="alert.timer"
      :status="alert.status"
      :action="alert.action"
      :action-name="alert.actionName"
      class="alert-container__alert"
      @close="deleteAlert(alert)"
    />
  </transition-group>
</template>

<script>
import AppAlert from "./Alert"

export default {
  components: { AppAlert },
  props: {
    type: {
      type: String,
      default: "main",
    },
  },
  computed: {
    alerts() {
      return this.$store.getters[`alerts/getAlertsByType`](this.type)
    },
  },
  methods: {
    deleteAlert(alertToDelete) {
      this.$store.dispatch("alerts/deleteAlert", {
        alertToDelete,
        type: this.type,
      })
      if (alertToDelete.onClose) {
        alertToDelete.onClose()
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/scss/_settings";
.alert-container {
  @media (max-width: $--tablet-max-width) {
    width: 100vw;
  }
  position: fixed;
  bottom: 0;
  z-index: 999999999999;
  max-width: 100%;
  padding-right: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(79.5vw - #{$--sidebar-width});
  &__offset {
    margin-bottom: 24px;
  }
  &__alert {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
.list-enter-active,
.list-leave-active,
.list-leave-to {
  transition: transform 800ms;
}
.list-enter {
  opacity: 0;
  transform: translateY(100%);
}
.list-leave-to {
  opacity: 0;
  transform: translateY(-100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
