/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'error_outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.964 15h1.994v2h-1.994v-2zm0-8h1.994v6h-1.994V7zm.987-5C6.45 2 1.994 6.48 1.994 12s4.455 10 9.957 10c5.512 0 9.977-4.48 9.977-10S17.463 2 11.951 2zm.01 18c-4.406 0-7.974-3.58-7.974-8s3.568-8 7.974-8c4.406 0 7.974 3.58 7.974 8s-3.568 8-7.974 8z" _fill="#FFBB12"/>'
  }
})
