<template>
  <span class="loader-container">
    <span class="loader">
      <img :src="loader" alt="loading" />
    </span>
  </span>
</template>

<script>
import loader from "@/assets/svg/loading.svg"

export default {
  name: "Loading",
  data() {
    return {
      loader,
    }
  },
}
</script>
<style scoped lang="scss">
.loader-container {
  width: 20px;
  height: 20px;
  position: relative;
  .loader {
    top: 0;
    left: 0;
    position: absolute;
    width: 20px;
    height: 20px;
    overflow: hidden;

    img {
      position: absolute;
      left: 50%;
      width: 50px;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  &.content-loader {
    width: 75px;
    height: 75px;
    .loader {
      width: 75px;
      height: 75px;
      position: static;
      img {
        position: absolute;
        left: 50%;
        width: 150px;
        top: 50%;
        transform: none;
      }
    }
  }
}
</style>
