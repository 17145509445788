<template>
  <div class="badge">
    <slot />
    <div v-if="count" class="badge__counter">
      <div class="badge__counter_count">
        {{ count }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style scoped lang="scss">
.badge {
  position: relative;
  display: flex;
  &__counter {
    height: 16px;
    min-width: 16px;
    position: absolute;
    top: -4px;
    left: calc(100% - 12px);
    border-radius: 50%;
    background: var(--color-red);
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 2px;
    &_count {
      z-index: 1;
      color: var(--text-white);
    }
    &:after {
      content: "";
      position: absolute;
      top: -2px;
      left: -2px;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      background: rgba(238, 27, 75, 0.5);
      z-index: 0;
      animation: pulse 800ms infinite ease-in;
      border-radius: 50%;
    }
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.75);
  }
}
</style>