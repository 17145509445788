<template>
  <header class="header">
    <div class="header__inner">
      <div class="header__left-container">
        <button class="header__menu-toggle" @click="toggleSidebar">
          <svgicon :name="isSidebarOpened ? 'cross-red' : 'menu'" />
        </button>
        <div class="header__logo">
          <router-link to="/dashboard" class="default-link">
            <app-logo theme="light" />
          </router-link>
        </div>
        <div class="header__buttons-container">
          <HeaderButtonRent v-bind="headerRent"> </HeaderButtonRent>
          <span class="header__buttons-container-dividing"></span>
          <HeaderButton v-bind="headerBuy"></HeaderButton>
          <span class="header__buttons-container-dividing"></span>
          <HeaderButton v-bind="headerMonetiseIPProps"></HeaderButton>
        </div>
      </div>
      <div class="header__right-container">
        <div class="header__organisation-notifications">
          <div class="header__organisation-notifications__organisation">
            <organization-selector />
          </div>
          <div class="header__organisation-notifications__notifications">
            <app-notifications />
          </div>
        </div>
        <div class="header__action header__user-avatar">
          <header-navigation />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import HeaderNavigation from "./HeaderNavigation"
import AppNotifications from "./Notifications/Notifications"
import OrganizationSelector from "./Organization/OrganizationSelector"
import AppLogo from "@/components/shared/Logo"
import HeaderButton from "@/components/shared/HeaderButton"
import HeaderButtonRent from "@/components/shared/HeaderButtonRent.vue"

export default {
  components: {
    HeaderButtonRent,
    HeaderNavigation,
    AppNotifications,
    OrganizationSelector,
    AppLogo,
    HeaderButton,
  },
  props: {
    isSidebarOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: "",
      isOpenedMonetiseIp: false,
      headerMonetiseIPProps: {
        buttonTitle: this.$t("MONETIZE IPS"),
        buttonIcon: "store",
        subLinks: [
          {
            to: "createInetnum",
            title: this.$t("Lease IP-block"),
          },
          {
            to: "createNetworkSale",
            title: this.$t("Sell IP-block"),
          },
        ],
      },
      headerBuy: {
        buttonTitle: this.$t("BUY IPS"),
        buttonIcon: "business_center",
        subLinks: [
          {
            to: "SaleOfferListPublic",
            title: this.$t("Buy IP-block"),
          },
          {
            to: "NetworkSaleCommon",
            title: this.$t("Auctions"),
          },
        ],
      },
      headerRent: {
        buttonTitle: this.$t("MY ORDER"),
        buttonIcon: "clock-full",
        subLinks: [],
        rootLink: { name: "marketplace" },
        showDropdown: true,
      },
    }
  },
  methods: {
    toggleSidebar() {
      this.$emit("toggle:sidebar", !this.isSidebarOpened)
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/scss/_settings";
.header {
  background: var(--text-color);
  position: relative;
  z-index: 11;
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: #{$--container-padding};
  }
  &__left-container {
    display: flex;
    align-items: center;
  }
  &__organisation-notifications {
    display: flex;
    align-items: center;
    @media (max-width: #{$--mobile-max-width}) {
      margin-left: 10px;
    }
  }
  &__organisation-notifications__notifications {
    margin-left: 25px;
  }
  &__logo {
    margin-left: 25px;
    @media (max-width: #{$--mobile-max-width}) {
      display: none;
    }
  }
  &__menu-toggle {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    font-size: 25px;
    color: var(--icons-color);
  }
  &__right-container {
    display: flex;
    align-items: center;
  }
  &__buttons-container {
    display: flex;
    background: rgba(123, 123, 189, 0.4);
    margin-left: 25px;
    &-dividing {
      width: 1px;
    }
    @media (max-width: #{$--tablet-max-width}) {
      display: none;
    }
  }
  &__action {
    padding: 0 5px 0px 15px;
  }
}
</style>
