<template>
  <app-transition>
    <div v-if="isOpened" class="modal-container">
      <app-transition>
        <div v-if="showContent" class="modal-container__overlay" />
      </app-transition>
      <app-transition>
        <div
          v-if="showContent"
          :style="modalStyles"
          class="modal"
          :class="{ 'full-height': fullHeight }"
        >
          <div class="modal__header">
            <button class="modal__close button-clear" @click="close">
              <svgicon name="cross-red" />
            </button>
            <slot name="header" />
          </div>
          <div class="modal__body">
            <slot />
          </div>
          <div v-if="locked" class="modal__locked">
            <h2>{{ lockedTitle }}</h2>
          </div>
        </div>
      </app-transition>
    </div>
  </app-transition>
</template>

<script>
import AppTransition from "./Transition"

export default {
  components: { AppTransition },
  model: {
    prop: "isOpened",
    event: "toggle",
  },
  props: {
    isOpened: {
      type: Boolean,
    },
    fullHeight: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 0,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    lockedTitle: {
      type: String,
      default: "This feature coming soon!",
    },
  },
  data() {
    return {
      showContent: false,
    }
  },
  computed: {
    modalStyles() {
      return {
        maxWidth: this.maxWidth ? `${this.maxWidth}px` : null,
      }
    },
  },
  watch: {
    isOpened() {
      if (this.isOpened) {
        this.$utils.locker.lock()
      } else {
        this.$utils.locker.unlock()
      }
      this.$nextTick(() => {
        this.showContent = this.isOpened
      })
    },
  },
  beforeDestroy() {
    this.$emit("toggle", false)
    this.$utils.locker.unlock()
  },
  methods: {
    close() {
      this.$emit("toggle", false)
    },
  },
}
</script>

<style scoped lang="scss">
.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: var(--primary-dark-color);
    opacity: 0.7;
  }
}
.modal {
  position: relative;
  z-index: 2;
  background: var(--text-white);
  max-width: 730px;
  width: 100%;
  padding: 47px 60px;
  background: var(--background-color);
  box-shadow: 0px 0px 44px rgba(42, 42, 75, 0.73);
  pointer-events: all;
  max-height: calc(100vh - 100px);
  overflow: auto;
  @media (max-width: 575px) {
    padding: 35px 0px;
  }
  &.full-height {
    height: 100%;
  }
  &__close {
    position: absolute;
    top: 26px;
    right: 29px;
    color: var(--icons-color);
    font-size: 14px;
    z-index: 2;
  }
  &__locked {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.89);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}
</style>
