<template>
  <div class="infinity-list">
    <slot />
    <div ref="autoTrigger" class="infinity-list__trigger" :style="styles">
      <ScrollObserver v-if="!isOver" @intersect="intersect" />
    </div>
    <div v-if="isLoading" class="d-flex justify-content-center">
      <AppSpinner :size="40" />
    </div>
  </div>
</template>

<script>
import ScrollObserver from "./ScrollObserver"
import AppSpinner from "@/components/ui/Spinner"

export default {
  name: "InfinityLoading",
  components: { ScrollObserver, AppSpinner },
  props: {
    isOver: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    threshold: {
      type: Number,
      default: 100,
    },
  },
  computed: {
    styles() {
      const styles = {
        bottom: `${this.threshold}px`,
      }
      return styles
    },
  },
  methods: {
    intersect() {
      this.$emit("next")
    },
  },
}
</script>

<style scoped lang="scss">
.infinity-list {
  position: relative;
  &__trigger {
    position: absolute;
    bottom: 0;
    left: 0;
    pointer-events: none;
    visibility: hidden;
  }
}
</style>
