import moment from "moment-timezone"
import Vue from "vue"

const momentPlugin = {}

momentPlugin.install = function () {
  Vue.moment = moment
  Vue.prototype.$moment = moment
}
Vue.use(momentPlugin)

export default momentPlugin
