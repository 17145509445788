<template>
  <div class="filter-preloader">
    <content-loader
      view-box="0 0 50 40"
      :speed="1"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <template v-for="i in contentLines">
        <rect
          :key="'card-rect-content-loaded-column-1' + i"
          x="0"
          :y="(i - 1) * getGap"
          rx="3"
          ry="3"
          width="15%"
          :height="height"
        />
        <rect
          :key="'card-rect-content-loaded-column-2' + i"
          x="20%"
          :y="(i - 1) * getGap"
          rx="3"
          ry="3"
          width="15%"
          :height="height"
        />
        <rect
          :key="'card-rect-content-loaded-column-3' + i"
          x="40%"
          :y="(i - 1) * getGap"
          rx="3"
          ry="3"
          width="15%"
          :height="height"
        />
        <rect
          :key="'card-rect-content-loaded-column-4' + i"
          x="60%"
          :y="(i - 1) * getGap"
          rx="3"
          ry="3"
          width="15%"
          :height="height"
        />
        <rect
          :key="'card-rect-content-loaded-column-5' + i"
          x="80%"
          :y="(i - 1) * getGap"
          rx="3"
          ry="3"
          width="15%"
          :height="height"
        />
        <rect
          :key="'card-rect-content-loaded-column-6' + i"
          x="100%"
          :y="(i - 1) * getGap"
          rx="3"
          ry="3"
          width="15%"
          :height="height"
        />
      </template>
    </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader"
export default {
  name: "LinePreloader",
  components: { ContentLoader },
  props: {
    contentLines: {
      type: Number,
      default: 5,
    },
    height: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    getGap() {
      console.log(this.componentName, this.contentLines, this.height)
      return this.height * 5
    },
  },
}
</script>
<style scoped lang="scss">
.filter-preloader {
  height: 15px;
  width: 100%;
}
</style>
