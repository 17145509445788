function getCoordsFromEvent(event) {
  if (window.TouchEvent && event instanceof window.TouchEvent) {
    if (event.touches[0]) {
      return {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY,
      }
    } else if (event.changedTouches[0]) {
      return {
        x: event.changedTouches[0].clientX,
        y: event.changedTouches[0].clientY,
      }
    }
  } else {
    return {
      x: event.clientX,
      y: event.clientY,
    }
  }
}

const map = new Map()
export function isClickInsideEl(event, element) {
  const box = element.getBoundingClientRect()
  const { x, y } = getCoordsFromEvent(event)
  const clickedInside =
    box.left <= x && box.left + box.width >= x && box.top <= y && box.top + box.height >= y
  if (clickedInside || element.contains(event.target)) {
    return true
  }
}
export default {
  /**
   * if click coordinates outside element box coordinates invoke bound function
   * @param {HTMLElement} el
   * @param {Object} bindings
   * @param {Function} bindings.value will be invoked when user clicked outside
   */
  inserted(el, bindings) {
    const listener = (e) => {
      if (!isClickInsideEl(e, el)) {
        bindings.value(e)
      }
    }
    map.set(el, listener)
    setTimeout(() => {
      window.addEventListener("touchstart", listener, true)
      window.addEventListener("click", listener, true)
    })
  },
  unbind(el) {
    const listener = map.get(el)
    if (listener) {
      window.removeEventListener("click", listener, true)
      window.removeEventListener("touchstart", listener, true)
    }
  },
}
