import Vue from "vue"
import App from "./App.vue"
import router from "@/router/router"
import VueIntercom from "vue-intercom"
import VueSSE from "vue-sse"
import "./plugins/moment"
import i18n from "@/i18n"
import "./plugins/gettext"
import store from "./store"
import "./plugins/alert"
import "./plugins/confirm"
import "./plugins/tfa"
import "./plugins/axios"
import "./plugins/auth"
import "./router/router"
import "./plugins/svgicons"
import "./scss/styles.scss"
import "./plugins/global-const"
import "./directives"
import vueAwesomeCountdown from "vue-awesome-countdown"
import { Vuelidate } from "vuelidate"
import LoadScript from "vue-plugin-load-script"
Vue.use(vueAwesomeCountdown)
Vue.use(VueSSE)
Vue.use(LoadScript)
Vue.use(Vuelidate)
Vue.use(VueIntercom, { appId: "da83sgtu" })
Vue.config.productionTip = false
Vue.loadScript("https://js.stripe.com/v3")
import "./plugins/global-components"
import "./filters"

new Vue({
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app")
