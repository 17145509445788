<template>
  <div>
    <app-modal :is-opened="isOpened" :max-width="500" v-on="$listeners">
      <template #header>
        <div class="modal-box text-center">
          <h3 class="text-headline1">
            {{ $gettext(headerText) }}
          </h3>
          <div class="description">
            <p>
              {{ $gettext(description) }}
            </p>
          </div>
        </div>
      </template>
      <template #default>
        <CodeInput :error="errors.code" :code-length="6" @input="handleCodeEntered" />
        <div class="buttons">
          <app-button :variant="'text'" @click="close">
            {{ $gettext(cancelButtonName) }}
          </app-button>
          <app-button :loading="loading" variant="primary" size="medium" @click="confirmedTfa">
            {{ $gettext(confirmButtonName) }}
          </app-button>
        </div>
      </template>
    </app-modal>
  </div>
</template>

<script>
import AppModal from "@/components/ui/AppModal"
import AppButton from "@/components/ui/AppButton"
import withCurrentOrganization from "@/mixins/withCurrentOrganization"
import CodeInput from "@/components/ui/CodeInput.vue"
import required from "vuelidate/lib/validators/required"
export default {
  validations: {
    form: {
      code: {
        required,
      },
    },
  },
  components: { CodeInput, AppModal, AppButton },
  mixins: [withCurrentOrganization],
  model: {
    prop: "isOpened",
    event: "toggle",
  },
  props: {
    confirmData: {
      type: Object,
      required: false,
      default: null,
    },
    headerText: {
      type: String,
      required: false,
      default: "2-Factor authentication required",
    },
    description: {
      type: String,
      required: false,
      default:
        "To continue, please enter the 6-digit verification code generated by your authenticator app",
    },
    confirmButtonName: {
      type: String,
      required: false,
      default: "Continue",
    },
    isCancelButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    cancelButtonName: {
      type: String,
      required: false,
      default: "Cancel",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isOpened: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        code: null,
      },
      errors: {
        code: null,
      },
    }
  },
  watch: {
    isOpened: function (newVal, oldVal) {
      this.form.code = ""
      this.errors = {
        code: null,
      }
    },
  },
  mounted() {
    console.log("mounted")
    this.form.code = ""
  },
  methods: {
    handleCodeEntered(code) {
      this.form.code = code
    },
    close() {
      return this.$emit("toggle", false)
    },
    confirmedTfa() {
      this.$v.$touch()
      const form = this.$v.form
      console.log(form.$invalid, "Form valid")
      console.log(form)
      if (form.$invalid) {
        return (this.errors = this.$utils.getClientValidationErrors(this.$v.form))
      }
      return this.$emit("confirmedTfa", { ...this.confirmData, ...{ code: this.form.code } })
    },
  },
}
</script>

<style scoped lang="scss">
.buttons {
  display: flex;
  gap: 10px; /* Adjust this value to set the desired distance between buttons */
  margin-top: 20px;
  width: 100%;
  justify-content: space-around;
}
.description {
  margin: 15px 0px;
  font-size: 16px;
  line-height: 1.2;
  p {
    margin-bottom: 15px;
  }
  .network-chip {
    background: var(--light-grey);
    padding: 3px 8px;
    border-radius: 3px;
    margin: 0px 5px 5px 0px;
    color: var(--text-color);
    font-size: 13px;
  }
}
</style>
