const SHOWN_NOTIFICATIONS_KEY = "shown_notifications"

export default {
  getShownNotifications() {
    let rawData = sessionStorage.getItem(SHOWN_NOTIFICATIONS_KEY)
    try {
      rawData = JSON.parse(rawData) || []
    } catch (e) {}
    if (Array.isArray(rawData)) {
      return rawData
    }

    return []
  },
  setShownNotifications(ids) {
    sessionStorage.setItem(SHOWN_NOTIFICATIONS_KEY, JSON.stringify(ids))
  },
  clearShownNotifications() {
    sessionStorage.removeItem(SHOWN_NOTIFICATIONS_KEY)
  },
}
