<template>
  <div>
    <content-loader
      view-box="0 0 300 200"
      :speed="1"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <template v-for="i in contentLines">
        <circle
          :key="'circle-' + i"
          cx="10%"
          :cy="'calc(' + 10 + '% + ' + 50 * (i - 1) + 'px)'"
          r="8"
        />
        <rect
          :key="'rect-1-' + i"
          x="15%"
          :y="'calc(' + 10 + '% + ' + 50 * (i - 1) + 'px)'"
          rx="2"
          ry="2"
          width="20%"
          height="2"
        />
        <rect
          :key="'rect-2-' + i"
          x="7%"
          :y="'calc(' + 20 + '% + ' + 50 * (i - 1) + 'px)'"
          rx="2"
          ry="2"
          width="70%"
          height="2"
        />
        <rect
          :key="'rect-3-' + i"
          x="15%"
          :y="'calc(' + 30 + '% + ' + 50 * (i - 1) + 'px)'"
          rx="2"
          ry="2"
          width="20%"
          height="2"
        />
        <rect
          :key="'rect-4-' + i"
          x="50%"
          :y="'calc(' + 30 + '% + ' + 50 * (i - 1) + 'px)'"
          rx="2"
          ry="2"
          width="22%"
          height="2"
        />
      </template>
    </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader"
export default {
  name: "ContentDetailLoader",
  components: { ContentLoader },
  props: {
    contentLines: {
      type: Number,
      default: 3,
    },
  },
}
</script>
