import required from "vuelidate/lib/validators/required"
import email from "vuelidate/lib/validators/email"
import i18n from "../i18n"

export default {
  validations: {
    form: {
      email: { required, email },
    },
  },
  data() {
    return {
      form: {
        email: "",
      },
      errors: {
        email: "",
      },
    }
  },

  methods: {
    cleanErrors() {
      this.errors = {
        email: "",
      }
    },
    formValid() {
      this.cleanErrors()
      this.$v.$touch()
      const form = this.$v.form
      if (!form.email.required) {
        this.errors.username = i18n.t("Field is required")
      }

      if (form.email.required && !form.email.email) {
        this.errors.email = i18n.t("E-mail has wrong format")
      }
      return form.email.required && form.email.email
    },
  },
}
