/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'expand_more': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6-6-6-1.41 1.41z" _fill="#9090D9"/>'
  }
})
