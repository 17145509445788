/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'table-edit': {
    width: 24,
    height: 25,
    viewBox: '0 0 24 25',
    data: '<path pid="0" d="M21.7 13.85l-1 1-2.05-2.05 1-1c.21-.22.56-.22.77 0l1.28 1.28c.22.21.22.56 0 .77zM12 19.44l6.07-6.06 2.05 2.05-6.06 6.07H12v-2.06zM4 2.5h14a2 2 0 012 2v4.17l-3.83 3.83H12v4.17l-1.83 1.83H4a2 2 0 01-2-2v-12a2 2 0 012-2zm0 4v4h6v-4H4zm8 0v4h6v-4h-6zm-8 6v4h6v-4H4z" _fill="#1E1E3F"/>'
  }
})
