/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'facebook': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M28 24.667A3.333 3.333 0 0124.667 28H7.333A3.333 3.333 0 014 24.667V7.333A3.333 3.333 0 017.333 4h17.334A3.333 3.333 0 0128 7.333v17.334z" _fill="#3F51B5"/><path pid="1" d="M22.912 16.666h-2.245v8.667h-3.334v-8.666h-2V14h2v-1.607c.002-2.338.973-3.727 3.728-3.727h2.272v2.667H21.81c-1.073 0-1.142.4-1.142 1.149V14h2.666l-.421 2.666z" _fill="#fff"/>'
  }
})
