<template>
  <aside class="rent-list">
    <section class="rent-list-wrapper">
      <div class="rent-list-top">
        <div class="rent-list-top--closer" @click="close">
          <svgicon :name="'cross-red'" />
        </div>
        <div class="rent-list-top--clear" @click="clearList">
          {{ $t("Clear list") }} ({{ items.length }})
        </div>
      </div>

      <header v-if="orders.length > 0" class="rent-list-header">
        <span class="rent-list-header--title">{{ "My order" }}</span>
      </header>
      <section v-if="orders.length > 0 && !isLoading" class="rent-list-content">
        <rent-item
          v-for="(itemList, index) in orders"
          :key="index + 'order-overview'"
          :order-overview="itemList"
          :order-title="`#${index + 1}`"
          @delete="deleteItem"
        />
        <div class="container">
          <a
            v-if="items.length > 20 && !expanded"
            href="#"
            class="clickable-text"
            @click="$emit('expandToggle')"
            >Show all</a
          >
          <a
            v-else-if="items.length > 20 && expanded"
            href="#"
            class="clickable-text"
            @click="$emit('expandToggle')"
            >Collapse</a
          >
        </div>
      </section>
      <section v-if="orders.length === 0 && !isLoading" class="rent-list-empty">
        <header class="rent-list-header">
          <span class="rent-list-header--title">{{ "Your order is empty" }}</span>
        </header>
        <section class="rent-list-empty--body">
          <div class="rent-list-empty--icon">
            <svgicon name="add-queue"></svgicon>
          </div>
        </section>
        <app-button :size="'small'" @click="toMarketplace">{{ $t("Open marketplace") }}</app-button>
      </section>
      <section v-if="isLoading" class="rent-list-loading">
        <loading class="content-loader"></loading>
      </section>
    </section>
    <section class="rent-list-footer">
      <ul class="rent-list-footer--summary">
        <li class="rent-list-footer--summary--item">
          <div class="rent-list-footer--summary--item--name">
            {{ "Total" }}
          </div>
          <div class="rent-list-footer--summary--item--value">{{ total | price }}</div>
        </li>
        <li class="rent-list-footer--summary--item">
          <div class="rent-list-footer--summary--item--name">
            {{ "Total VAT" }}
          </div>
          <div class="rent-list-footer--summary--item--value">{{ totalVat | price }}</div>
        </li>
      </ul>
      <div class="rent-list-footer--wrapper">
        <app-button
          v-if="items.length"
          type="submit"
          :loading="isLoading"
          :disabled="!allAvail"
          variant="primary"
          size="normal"
          @click="onSubmit"
        >
          {{ $gettext("Make order") }}
        </app-button>
      </div>
    </section>
  </aside>
</template>

<script>
import withCurrentOrganization from "@/mixins/withCurrentOrganization"
import RentItem from "@/components/shared/RentList/RentItem.vue"
import AppButton from "@/components/ui/AppButton.vue"
import is from "vue2-datepicker/locale/es/is"
import Loading from "@/components/shared/Loading.vue"

export default {
  components: { Loading, AppButton, RentItem },
  mixins: [withCurrentOrganization],
  props: {
    orders: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    total: {
      type: Number,
      required: true,
    },
    totalVat: {
      type: Number,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: false,
    },
    allAvail: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    is() {
      return is
    },
    isOpened() {
      return this.$store.getters["core/rentListOpened"]
    },
    items() {
      return this.$store.getters["marketplace/networksOrder"]
    },
  },

  methods: {
    toMarketplace() {
      this.$emit("toMarketplace")
    },
    async deleteItem(item) {
      this.$emit("deleteItem", item.network)
    },
    async close() {
      await this.$store.dispatch("core/closeRentList")
    },
    async clearList() {
      await this.$store.dispatch("marketplace/clearOrder", {
        organisation_uid: this.currentOrganization.unique_id,
      })
    },
    async onSubmit() {
      this.$emit("submit")
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/scss/_settings";
.rent-list {
  font-family: var(--font-secondary);
  letter-spacing: 0.4px;
  background: var(--text-color);
  height: calc(100vh - 60px);
  width: $--sidebar-width;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #d9dff63b;
    margin-bottom: 20px;
    &--closer {
      background: var(--text-color);
      font-size: 16px;
      color: var(--icons-color);
      width: 40px;
      height: 30px;
      justify-content: center;
      align-items: center;
      display: flex;
      cursor: pointer;
    }
    &--clear {
      color: var(--icons-color);
      border-bottom: 1px dashed;
      cursor: pointer;
      letter-spacing: 0.5px;
    }
  }
}
.rent-list-header {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--sidebar-color);
  margin-bottom: 14px;
  &--icon {
    font-size: 24px;
    color: var(--icons-color);
  }
  &--title {
    font-size: 16px;
    text-transform: uppercase;
  }
  &--clear {
    background: none;
    border: none;
  }
}
.rent-list-content {
  max-height: calc(100vh - 305px);
  overflow: auto;
  min-height: 200px;
}
.rent-list-footer {
  border-top: 1px solid #d9dff63b;
  color: #fff;
  &--wrapper {
    padding: 15px 10px 0px 10px;
    display: flex;
    justify-content: center;
    button {
      margin-bottom: 10px;
    }
  }
  &--summary {
    list-style: none;
    &--item {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px dashed #d9dff63b;
      text-transform: uppercase;
      &--name {
        color: var(--icons-color);
      }
    }
  }
}
.rent-list-empty {
  position: relative;
  top: 25%;
  padding: 40px 20px;
  text-align: center;
  &--icon {
    font-size: 80px;
    color: var(--icons-color);
    margin: 30px 0;
  }
}
.rent-list-loading {
  transform: translateX(20%);
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.clickable-text {
  text-decoration: none;
  color: white; /* Set your desired text color */
  border-bottom: 2px solid #000; /* Set your desired border color and thickness */
  cursor: pointer;
  transition: border-bottom 0.3s ease; /* Optional: Add a smooth transition effect */
}

.clickable-text:hover {
  border-bottom: 2px solid white; /* Set a different color on hover if desired */
}
</style>
