import Vue from 'vue'
import i18n from '@/i18n'
import countries from "@/assets/countries.json"

export default {
    leasedPeriodOptions: [12, 24, 36, 48, 60],

    i18nLink(url) {
        return url
    },
    getQueryParam(param, sanitize = true) {
        const url = new URL(location.href)
        const params = Object.fromEntries(url.searchParams.entries())
        let rawParam = params[param]

        if (rawParam && sanitize) {
            rawParam = window.decodeURI(rawParam).replace('%23', '#')
        }
        return rawParam
    },
    formatCurrency(value, currency = 'EUR', eur = true) {
        if (currency === "" && eur) {
            currency = 'EUR'
        }
        try {
            const currencyFormatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currency,
                maximumFractionDigits: 2
            })
            return currencyFormatter.format(value)
        } catch (e) {
            return `${currency} ${value.toFixed(2)}`
        }

    },
    normalizeDate(rawDate) {
        if (!rawDate) {
            return ''
        }
        if (typeof rawDate === 'string' && rawDate.includes('T') && !rawDate.includes('+') && !rawDate.endsWith('Z')) {
            rawDate += '+00:00'
        }
        if (!(rawDate instanceof Date)) {
            rawDate = new Date(rawDate)
        }
        return rawDate
    },

    /**
     * @param rawDate
     * @param showTime
     * @param locale
     * @param delimiter
     * @returns {string}
     */
    formatDate(rawDate, {showTime = false, locale = 'en-US', delimiter} = {}) {
        if (!rawDate) {
            return ''
        }
        const jstz = require('jstimezonedetect');
        const tz_name_support = jstz.determine();
        const tz_name = tz_name_support.name();
        const moment = Vue.moment(rawDate).tz(tz_name);
        const d = delimiter ? delimiter : '/';
        const format = showTime ? `D${d}M${d}Y HH:mm:ss` : `D${d}M${d}Y`;
        return moment.format(format);
    },
    calculateExpirationDate(startedAt, duration) {
        if (!startedAt) return ""
        const start = new Date(startedAt)
        start.setDate(Number(start.getDate()) + Number(duration))
        return start
    },
    calculatePricePerAddress(amount, mask) {
        return (amount / Math.pow(2, 32 - mask)).toFixed(3)
    },
    calculatePriceFromIPToNetwork(price, mask) {
        return (price * Math.pow(2, 32 - mask)).toFixed(3)
    },
    calculatePriceByMask(price, mask, minSplit = 24) {
        return (price && minSplit - mask) ? parseInt((Math.pow(2, minSplit - mask) * parseInt(price))) : parseInt(price)
    },
    networkSaleWorkflowFinished(workflowStatus) {
        return (
            workflowStatus === "" ||
            workflowStatus === "new" ||
            workflowStatus === "auction_details" ||
            workflowStatus === "waiting_for_approval" ||
            workflowStatus === "sign_contract" ||
            workflowStatus === "waiting_for_pandadoc"
        )
    },
    getClientValidationErrors(form) {
        const errors = {}
        Object.keys(form).forEach(key => {
            const fieldProperties = form[key]
            if (key.startsWith('$') || !fieldProperties || !fieldProperties.$error) {
                return
            }
            errors[key] = ''
            const rules = [
                'required', 'creditCard', 'idAddress', 'month', 'year', 'email', 'length', 'ibanValidate', 'boolean', 'minLength', 'nsValidation'
            ]
            rules.forEach(rule => {
                if (fieldProperties[rule] === false) {
                    errors[key] = this.errorMessageByType(rule)
                }
            })
            if (fieldProperties.$each) {
                errors[key] = {}
                const errorsEach = Object.keys(fieldProperties.$each.$iter)
                errorsEach.forEach(index => {
                    rules.forEach(rule => {
                        if (fieldProperties.$each.$iter[index][rule] === false) {
                            errors[key][index] = this.errorMessageByType(rule)
                        }
                    })
                })
                console.log("Each")
            }
        })
        return errors
    },
    getCountdownRepresentation(finishedAt) {
        const finishDate = new Date(finishedAt)
        if (finishDate.getFullYear() === 1) return 'Not set'

        const oneHour = 60 * 60
        const oneDay = oneHour * 24

        const delta = Math.floor((finishDate - new Date()) / 1000)

        if (delta <= 0) return 'Expired'

        const days = Math.floor(delta / oneDay)
        const hours = Math.floor(delta / oneHour) - (days * 24)
        const minutes = Math.floor((delta - days * oneDay - hours * oneHour) / 60)

        let result = ''

        if (days > 0) result = days.toString() + ' d'
        if (hours > 0) result += ' ' + hours + ' hr'
        if (minutes > 0) result += ' ' + minutes + ' min'

        return result
    },
    errorMessageByType(type) {
        if (type === 'required') {
            return i18n.t('This field is required')
        }
        if (type === 'email') {
            return i18n.t('This field should be valid email address')
        }
        if (type === 'phone') {
            return i18n.t(`This field should be valid phone number`)
        }
        if (type === 'numeric') {
            return i18n.t(`This field should be valid number`)
        }
        if (type === 'alphanumeric') {
            return i18n.t(`This field should contain only numbers and letters`)
        }
        if (type === 'ipAddress') {
            return i18n.t(`This field should be a valid ip address`)
        }
        if (type === 'min_split_size') {
            return i18n.t(`This field is required`)
        }
        if (type === 'creditCard') {
            return i18n.t(`This field should be a valid credit card`)
        }
        if (type === 'month') {
            return i18n.t(`This field should be a valid month`)
        }
        if (type === 'year') {
            return i18n.t(`This field should be a valid year`)
        }
        if (type === 'price') {
            return i18n.t(`This field should be valid number`)
        }
        if (type === 'leasing_period_month') {
            return i18n.t(`This field is required`)
        }
        if (type === 'length') {
            return i18n.t(`This field should be valid`)
        }
        if (type === 'valid') {
            return i18n.t(`valid`)
        }
        if (type === 'ibanValidate') {
            return i18n.t(`IBAN number is not valid`)
        }
        if (type === 'boolean') {
            return i18n.t(`You have to agree with this rule`)
        }
        if (type === 'minValue') {
            return i18n.t(`Please check if the value is correctly filed`)
        }
        if (type === 'nsValidation') {
            return i18n.t(`Please enter correct name server`)
        }
        if (type === 'minLength') {
            return i18n.t(`Please filed in at least 1 correct value`)
        }
        return ''
    },
    getServerValidationErrors(serverError) {
        if (!serverError?.response?.data?.detail) {
            return false
        }
        if (Array.isArray(serverError.response.data.detail)) {
            return serverError.response.data.detail.reduce((acc, error) => {
                const key = error.loc[1] ? error.loc[1] : 0
                acc[key] = error.msg
                return acc
            }, {})
        }
        return serverError.response.data.detail
    },
    getFirstServerErrorText(serverError, defaultError) {
        const parsed = this.getServerValidationErrors(serverError);
        const defaultMessage = defaultError ? defaultError : 'We can\'t finish your request properly, please contact us and we will solve your problem'
        if (typeof parsed === 'string') {
            return parsed;
        }
        if (!parsed) {
            return defaultMessage;
        }
        if (typeof parsed === 'object') {
            return parsed[Object.keys(parsed)[0]];
        }
    },
    generateId() {
        return '_' + Math.random().toString(36).substr(2, 9)
    },

    clearSelection() {
        if (window.getSelection) {
            window.getSelection().removeAllRanges()
        } else if (document.selection) {
            document.selection.empty()
        }
    },
    locker: new Vue({
        data() {
            return {
                lockedBy: undefined
            }
        },
        methods: {
            lock(id) {
                if (this.lockedBy) return
                this.$emit('lock', id)
                this.lockedBy = id
                document.body.style.overflow = 'hidden'
                document.body.style['-webkit-overflow-scrolling'] = 'touch'
            },
            unlock(id) {
                if (this.lockedBy && this.lockedBy !== id) return
                this.$emit('unlock', id)
                this.lockedBy = undefined
                document.body.style.overflow = ''
                document.body.style['-webkit-overflow-scrolling'] = ''
            }
        }
    }),
    addTokenToData(data) {
        const token = localStorage.getItem("token");
        const token_type = localStorage.getItem("token_type");
        if (token && token_type) {
            data['token'] = token;
            data['type'] = token_type;
        }
        return data;
    },
    clearTokenData() {
        localStorage.removeItem("token");
        localStorage.removeItem("token_type");
    },
    formatCardEnd(ends) {
        return ends.toString().padStart(4, "0");
    },
    stripeUnPaid(stripeStatus) {
        return stripeStatus !== "paid"
    },
    getInvoiceLabelByStripeStatus(stripeStatus) {
        const labelConfig = {
            open: i18n.t("Pay now"),
            paid: i18n.t("Download"),
            void: i18n.t("Show details"),
        }
        return labelConfig[stripeStatus] ? labelConfig[stripeStatus] : ""
    },
    getInvoicePaidLabelByStripeStatus(stripeStatus, paid) {
        if (stripeStatus === "void") return i18n.t("Void")
        return paid ? "Yes" : "No"
    },
    async downloadBlob(fileName, file, type = "") {
        let dataBlob
        if (!type) {
            dataBlob = await Vue.axios.get(file, {
                responseType: "blob",
            })
            dataBlob = dataBlob.data
        } else {
            dataBlob = new Blob([file], {type: type})
        }
        let objectUrl = window.URL.createObjectURL(dataBlob)
        let anchor = document.createElement("a")
        anchor.href = objectUrl
        anchor.download = fileName
        anchor.click()

        window.URL.revokeObjectURL(objectUrl)
    },
    getCountryCode(countryName) {
        const data = countries.filter((item) => {
            return item.name === countryName
        })
        return data.length ? data.pop().code : ""
    },
    getCountryName(countryCode) {
        console.log("getCountryName", countryCode)
        const data = countries.filter((item) => {
            return item.code.toLowerCase() === countryCode.toLowerCase()
        })
        return data.length ? data.pop().name : ""
    },
    wait(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms)
        });
    },
    isMobile() {
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        ) {
            return true
        } else {
            return false
        }
    },
    currentDate() {
        const today = new Date()
        const dd = String(today.getDate()).padStart(2, "0")
        const mm = String(today.getMonth() + 1).padStart(2, "0")
        const yyyy = today.getFullYear()
        return mm + "/" + dd + "/" + yyyy
    },
    isStripeError(error) {
        const response = error.response
        return response.data.detail.type === "stripe_error"
    },
    stripeErrorResponse(error) {
        const response = error.response
        const message = response.data.detail.error

        Vue.alert({
            message: `${Vue.prototype.$gettext(message)}`,
            type: "info",
            timer: false,
        })
    },
    snakeToCamel(str) {
        return str.toLowerCase().replace(/([-_][a-z])/g, group =>
            group
                .toUpperCase()
                .replace('-', '')
                .replace('_', '')
        );
    },
}
