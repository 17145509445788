import Vue from "vue"

const actions = {
  async deleteCard(app, data) {
    return await Vue.axios.delete(`/payments/cards/${data.organization_uid}/${data.card_uid}`, data)
  },
  async checkCard(app, data) {
    return await Vue.axios.get(
      `/payments/cards/check_card_status/${data.organization_uid}/${data.card_uid}`
    )
  },
}
export default {
  namespaced: true,
  actions,
}
