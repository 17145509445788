const namespaced = true
import Vue from "vue"

const actions = {
  /**
   * Recovery password
   * @param context
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  async recoveryPassword(context, payload) {
    return await Vue.axios.post("/auth/forgot-password", payload)
  },
  /**
   * Change password
   * @param context
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  async changePassword(context, payload) {
    return await Vue.axios.post("/auth/reset-password", payload)
  },
  /**
   * Resend registration email
   * @param context
   * @param payload{Object<email:string>}
   * @returns {Promise<AxiosResponse<any>>}
   */
  async resendRegistrationEmail(context, payload) {
    return await Vue.axios.post("/auth/send-registration-email", payload)
  },
  /**
   * User verify
   * @param context
   * @param payload{Object<token:string>}
   * @returns {Promise<AxiosResponse<any>>}
   */
  async verify(context, payload) {
    return await Vue.axios.post("/auth/verify", payload)
  },
  /**
   * Get time by email when we able to sent it again
   * @param context
   * @param payload{Object<email:string>}
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getEmailTimer(context, payload) {
    return await Vue.axios.get(`/auth/email-key-timer?email=${payload.email}`)
  },
  /**
   * Add sse event by type
   * @param commit
   * @param message
   * @param type
   */
  addSseEvent({ commit }, { message, type }) {
    const mutations = {
      workflow: "addSseMessageWorkflow",
      models: "addSseMessageModels",
      notification: "addSseMessageNotification",
      marketplace: "addSseMessageMarketplace",
    }
    if (mutations[type]) {
      commit(mutations[type], message)
    }
  },
}

/**
 * States for SSE events
 * @type {{sseMessageNotification: {}, sseMessageModels: {}, sseMessageMarketplace: {}, sseMessageWorkflow: {}}}
 */
const state = {
  sseMessageWorkflow: {},
  sseMessageModels: {},
  sseMessageNotification: {},
  sseMessageMarketplace: {},
}

/**
 * Getters for SSE events
 * @type {{sseMessageNotification: (function(*): function(): {}), getSseMessageWorkflow: (function(*): function(): {}), getSseMessageModels: (function(*): function(): {}), sseMessageMarketplace: (function(*): function(): {})}}
 */
const getters = {
  getSseMessageWorkflow: (state) => () => {
    return state.sseMessageWorkflow
  },
  getSseMessageModels: (state) => () => {
    return state.sseMessageModels
  },
  getSseMessageNotification: (state) => () => {
    return state.sseMessageNotification
  },
  getSseMessageMarketplace: (state) => () => {
    return state.sseMessageMarketplace
  },
}

/**
 * Mutations for sse events
 * @type {{setSseMessageNotification(*, *): void, setSseMessageMarketplace(*, *): void, setSseMessageWorkflow(*, *): void, setSseMessageModels(*, *): void}}
 */
const mutations = {
  addSseMessageWorkflow(state, message) {
    state.sseMessageWorkflow = message
  },
  addSseMessageModels(state, message) {
    state.sseMessageModels = message
  },
  addSseMessageNotification(state, message) {
    state.sseMessageNotification = message
  },
  addSseMessageMarketplace(state, message) {
    state.sseMessageMarketplace = message
  },
}

export default {
  namespaced,
  actions,
  mutations,
  state,
  getters,
}
