export function isToday (rawDate) {
  const date = new Date(rawDate)
  const today = new Date()
  return isSameDay(date, today)
}
export function isYesterday (rawDate) {
  const date = new Date(rawDate)
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  return isSameDay(date, yesterday)
}
function isSameDay (date, dateToCompare) {
  return date.getFullYear() === dateToCompare.getFullYear() && date.getMonth() === dateToCompare.getMonth() && date.getDate() === dateToCompare.getDate()
}