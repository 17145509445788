import { ORGANIZATION } from "@/store/organizations/types"
import countries from "@/assets/countries.json"

export default {
  methods: {
    toDefaultRouteOrToOrganization(route) {
      if (!this.isOrganizationExist) {
        this.$alert({
          message: this.$gettext("Please add your business account first"),
          type: "info",
          timer: 2000,
        })
        return this.$router.push({ name: "createOrganization" })
      }
      if (!this.isOrganizationActive) {
        this.$alert({
          message: this.$gettext("Please finish update business account first"),
          type: "info",
          timer: 2000,
        })
        return this.$router.push({
          name: "editOrganization",
          params: { id: this.currentOrganization.unique_id },
        })
      }
      return route ? this.$router.push(route) : null
    },
    async getOrganization() {
      if (!this.currentOrganization) {
        return null
      }
      await this.$store.dispatch("loader/startLoading")
      const response = await this.$store.dispatch(
        "organizations/getOrganization",
        this.currentOrganization.unique_id
      )
      await this.$store.dispatch("loader/doneLoading")
      return response.data
    },
    async asyncGetFormConfig() {
      const organization = await this.getOrganization()
      let country_detail = null
      countries.forEach((item) => {
        if (item.name === organization.country_name) {
          country_detail = item
        }
      })
      return {
        address: organization.address,
        country: country_detail.code,
        city: organization.city,
        zip_code: organization.zip,
        email: organization.email,
      }
    },
  },
  computed: {
    currentOrganization() {
      return this.$store.getters[`organizations/${ORGANIZATION}`]
    },
    isOrganizationExist() {
      return !!this.currentOrganization
    },
    isOrganizationActive() {
      const currentOrganization = this.currentOrganization
      return currentOrganization && currentOrganization.status === "Completed"
    },
    cantProcessNetworkOrder() {
      return !this.isOrganizationExist || !this.isOrganizationActive
    },
  },
}
