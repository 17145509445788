<template>
  <div class="data-preloader-holder">
    <app-spinner v-if="isSpinner"></app-spinner>
    <div v-else class="preloader-holder">
      <component
        :is="componentName"
        v-bind="$attrs"
        :height="height"
        :content-lines="contentLines"
      ></component>
    </div>
  </div>
</template>

<script>
import AppSpinner from "@/components/ui/Spinner"

import LinePreloader from "./LinePreloader"
import CardPreloader from "./CardPreloader"
import TablePreloader from "./TablePreloader"
import ContentDetailLoader from "./ContentDetailLoader"
import FilterPreloader from "./FilterPreloader"

export default {
  name: "DataPreloader",
  components: {
    AppSpinner,
    LinePreloader,
    CardPreloader,
    TablePreloader,
    ContentDetailLoader,
    FilterPreloader,
  },
  props: {
    isSpinner: {
      type: Boolean,
      default: true,
    },
    contentLines: {
      type: Number,
      default: 5,
    },
    height: {
      type: Number,
      default: 12,
    },
    componentName: {
      type: String,
      validator(v) {
        return [
          "LinePreloader",
          "CardPreloader",
          "TablePreloader",
          "ContentDetailLoader",
          "FilterPreloader",
        ].includes(v)
      },
      default: "LinePreloader",
    },
  },
}
</script>

<style lang="scss">
.preloader-holder {
  padding: 10px 20px;
}
</style>
