<template>
  <vue-topprogress ref="topProgress" color="#EE1B4B" :minimum="0.1" />
</template>

<script>
import { vueTopprogress } from "vue-top-progress"

export default {
  name: "TopLoading",
  components: {
    vueTopprogress,
  },
  watch: {
    "$store.state.loader.loading": function () {
      if (this.$store.state.loader.loading === true) {
        this.onDone()
        this.onStart()
      }
      if (this.$store.state.loader.loading === false) {
        this.onDone()
      }
    },
  },
  mounted() {
    if (this.$store.getters["loader/getLoading"] === true) {
      this.$store.dispatch("loader/doneLoading")
    }
  },
  methods: {
    onStart() {
      this.$refs.topProgress.start()
    },
    onDone() {
      this.$refs.topProgress.done()
    },
  },
}
</script>
