<template>
  <div ref="root">
    <slot>
      <div class="observe" />
    </slot>
  </div>
</template>

<script>
export default {
  name: "ScrollObserver",
  mounted() {
    if (!window.IntersectionObserver) {
      console.warn("Your browser doesn't support IntersectionObserver")
      return
    }
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio >= 1) {
          this.$emit("intersect")
        }
      })
    })
    observer.observe(this.$refs.root)
  },
}
</script>

<style></style>
