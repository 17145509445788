<template>
  <app-avatar
    class="user-avatar"
    :class="{
      [`user-avatar--${avatarColorType}`]: avatarColorType,
      'user-avatar--colored': colored,
    }"
    :src="src"
    :title="username"
    v-bind="$attrs"
  >
    <div v-if="!src" class="user-avatar__label text-headline6">
      {{ label }}
    </div>
  </app-avatar>
</template>

<script>
import AppAvatar from "./Avatar"

export default {
  components: { AppAvatar },
  props: {
    src: {
      type: String,
      default: "",
    },
    username: {
      type: String,
      default: "",
    },
    colored: {
      type: Boolean,
    },
  },
  computed: {
    label() {
      if (!this.username) {
        return ""
      }
      const nameParts = this.username.split("@")
      return nameParts.length > 1
        ? nameParts[0].slice(0, 1) + nameParts[1].slice(0, 1)
        : nameParts[0].slice(0, 2)
    },
    firstLabelLetter() {
      const nameParts = this.username.split("@")
      return nameParts[0].toLowerCase()
    },
    avatarColorType() {
      const typeNumber = (this.firstLabelLetter.charCodeAt(0) % 5) + 1
      return `type-${typeNumber}`
    },
  },
}
</script>

<style scoped lang="scss">
.user-avatar {
  background: red;
  &__image {
    max-width: 100%;
    object-fit: cover;
  }
  &__label {
    text-transform: uppercase;
    text-align: center;
  }
  background: var(--primary-color);
  color: var(--text-white);
  &--type-1 {
    background: #1a5163;
  }
  &--type-2 {
    background: #e8dbdb;
    color: var(--text-color);
  }
  &--type-3 {
    background: #f5564e;
  }
  &--type-4 {
    background: #5c6bc0;
  }
  &--type-5 {
    background: #fab95b;
  }
  &--colored {
    &.user-avatar--type-1 {
      background: var(--alert-info-bg);
      color: var(--primary-color);
    }
    &.user-avatar--type-2 {
      background: var(--alert-warning-bg);
      color: var(--alert-warning);
    }
    &.user-avatar--type-3 {
      background: var(--alert-success-bg);
      color: var(--alert-success);
    }
    &.user-avatar--type-4 {
      background: var(--alert-warning-bg);
      color: var(--alert-warning);
    }
    &.user-avatar--type-5 {
      background: var(--alert-info-bg);
      color: var(--primary-color);
    }
  }
}
</style>