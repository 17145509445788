import Vue from "vue"
import Vuex from "vuex"

import user from "./user"
import organizations from "./organizations"
import workspaces from "./workspaces"
import notifications from "./notifications"
import alerts from "./alerts"
import loader from "./loader"
import payouts from "./payouts"
import subscriptions from "./subscriptions"
import inetnums from "./inetnums"
import core from "./core"
import marketplace from "./marketplace"
import invoices from "./invoices"
import billing from "./billing"
import dashboard from "./dashboard"
import networks from "./networks"
import profile from "./profile"

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    user,
    organizations,
    workspaces,
    notifications,
    alerts,
    loader,
    payouts,
    subscriptions,
    inetnums,
    core,
    marketplace,
    invoices,
    billing,
    dashboard,
    networks,
    profile,
  },
})
Vue.prototype.$store = store
export default store
