/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ip-network-outline': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 20a1 1 0 00-1-1h-1v-2h4a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2h4v2h-1a1 1 0 00-1 1H2v2h7a1 1 0 001 1h4a1 1 0 001-1h7v-2h-7zm-8-5V5h10v10H7zm3-9H8v8h2V6zm4 0h-3v8h2v-2h1a2 2 0 002-2V8a2 2 0 00-2-2zm0 4h-1V8h1v2z" _fill="#2554FF"/>'
  }
})
