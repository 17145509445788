/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'auction': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M22.4242 20.869H14.5967C13.7321 20.869 13.0312 21.5699 13.0312 22.4345V23.2172C13.0312 23.6495 13.3817 24 13.814 24H23.2069C23.6392 24 23.9896 23.6495 23.9896 23.2172V22.4345C23.9896 21.5699 23.2888 20.869 22.4242 20.869Z" fill="#9090D9"/>\n' +
        '<path d="M13.6612 0.458578C13.0497 -0.152953 12.0591 -0.152672 11.4476 0.458297L6.88106 5.02453C6.26953 5.63606 6.26953 6.62723 6.88106 7.23848C7.49259 7.85002 8.48306 7.84988 9.09473 7.23877L13.6612 2.67253C14.2728 2.061 14.2728 1.06983 13.6612 0.458578Z" fill="#9090D9"/>\n' +
        '<path d="M22.516 9.31331C21.9045 8.70229 20.9138 8.70206 20.3023 9.31359L15.7363 13.8798C15.1248 14.4911 15.1248 15.4822 15.7363 16.0938C16.348 16.705 17.3385 16.705 17.95 16.0935L22.516 11.5273C23.1275 10.916 23.1275 9.92484 22.516 9.31331Z" fill="#9090D9"/>\n' +
        '<path d="M14.699 3.84862L10.2712 8.27615L14.6986 12.7036L19.1263 8.27568L14.699 3.84862Z" fill="#9090D9"/>\n' +
        '<path d="M9.71774 9.93666L0.69529 18.9591C-0.217929 19.8723 -0.217929 21.3647 0.69529 22.2779L0.700493 22.2831C1.6446 23.2164 3.12824 23.1739 4.01409 22.2779L13.0387 13.2577L9.71774 9.93666Z" fill="#9090D9"/>\n' +
        '</svg>'
  }
})
