import Vue from "vue";

export async function saveGuestToken (to, from, next) {
    const query = to.query;
    const isGuest = !localStorage.getItem('auth_token_default');
    if(!isGuest){
        return next();
    }
    if (!query.token && !query.type){
        return next({ name: 'login' });
    }
    if(isGuest){
        localStorage.setItem('token', query.token);
        localStorage.setItem('token_type', query.type);
        sessionStorage.setItem('redirect_to', to.path);
        return next({ name: 'login' });
    }
    return next();
}