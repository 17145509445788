<template>
  <div>
    <TFAModal
      :is-opened="opened"
      v-bind="tfaAttributes"
      :loading="loading"
      @confirmedTfa="confirmedTfa"
      @toggle="close"
    ></TFAModal>
  </div>
</template>

<script>
import TFAModal from "@/components/shared/TFAModal.vue"

export default {
  components: { TFAModal },
  data() {
    return {
      opened: false,
      tfaAttributes: null,
      loading: false,
    }
  },
  computed: {
    tfaOpened() {
      return this.$store.state.core.tfaModal.opened
    },
    tfaOptions() {
      return this.$store.state.core.tfaModal.configuration
    },
  },
  watch: {
    tfaOpened: function (isOpened) {
      this.opened = isOpened
    },
    tfaOptions: function (options) {
      this.tfaAttributes = options
    },
  },
  methods: {
    async confirmedTfa(data) {
      const tfaCallback = this.$store.state.core.tfaModal.tfaCallback
      await this.close()
      this.loading = true
      await tfaCallback(data)
      this.loading = false
    },
    async close() {
      await this.$store.dispatch("core/closeTFAModal", {})
    },
  },
}
</script>

<style scoped lang="scss">
</style>
