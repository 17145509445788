<script>
import DataPreloader from "@/components/ui/DataPreloader/DataPreloader"
import InfinityLoading from "./InfinityLoading"

export default {
  components: { DataPreloader, InfinityLoading },
  props: {
    fetchFunction: {
      type: Function,
      required: true,
    },
    update: {
      type: [String, Boolean],
      default: "",
    },
    infinity: {
      type: Boolean,
      default: false,
    },
    dataPreloaderProps: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      data: null,
      lastRequestId: 0,
      page: 0,
      isOver: false,
    }
  },
  watch: {
    update() {
      this.page = 0
      this.getData()
    },
  },
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      try {
        this.isLoading = true
        this.lastRequestId += 1
        const lastRequestId = this.lastRequestId
        const response = await this.fetchFunction({
          page: this.page,
        })
        if (this.lastRequestId === lastRequestId) {
          if (response) {
            this.isOver = !response.hasNext
            this.data = response.data
          }
          this.$emit("fetch", {
            page: this.page,
            data: this.data,
          })
        }
      } catch (e) {
        console.warn(e)
        this.error = e
      } finally {
        this.isLoading = false
      }
    },
    async nextPage() {
      this.page += 1
      await this.getData()
    },
  },
  render(h) {
    if (this.isLoading && (!this.infinity || this.page === 0)) {
      return (
        this.$slots.loader ||
        h("DataPreloader", {
          props: this.dataPreloaderProps,
        })
      )
    }
    if (this.error) {
      return (
        this.$slots.error ||
        h(
          "span",
          {
            props: {
              error: this.error,
            },
          },
          "Error"
        )
      )
    }
    const defaultSlot = this.$scopedSlots.default
      ? this.$scopedSlots.default({ data: this.data })
      : this.$slots.default
    if (this.infinity) {
      return h(
        "InfinityLoading",
        {
          props: {
            isOver: this.isOver,
            isLoading: this.isLoading,
          },
          on: {
            next: this.nextPage,
          },
        },
        defaultSlot
      )
    }
    return defaultSlot
  },
}
</script>

<style></style>
