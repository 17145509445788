<template>
  <div
    class="input"
    :class="{
      'input--disabled': disabled,
      'input--error': error,
      'input--verification': verification,
      'input--clear': clearStyles,
      [`input--size-${size}`]: size,
    }"
  >
    <div v-if="label || $slots.label" class="input__label-container">
      <label class="input__label" :for="id">
        <slot name="label">
          {{ label }}
        </slot>
      </label>
      <div v-if="$slots['label-append']" class="input__label-append">
        <slot name="label-append" />
      </div>
    </div>
    <div class="input__container">
      <slot
        v-bind="{
          id,
          elementClass: 'input__element',
          borderClass: 'input__inherit-border',
        }"
      />
    </div>
    <div
      v-if="(error && showErrorMessage) || verification || notice"
      class="input__notice-container"
    >
      <div v-if="verification" class="input__verification">
        <base-spinner type="dashed" class="input__verification-icon" />
        <div class="input__verification-message">{{ $t("Verification") }}...</div>
      </div>
      <validation-error v-else-if="error" :error="error" />
      <div v-if="notice" class="input__notice">
        <slot name="notice">
          <span v-if="!error && !verification">
            {{ notice }}
          </span>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSpinner from "@/components/ui/Spinner"
import ValidationError from "@/components/ui/forms/ValidationError"

export default {
  components: { BaseSpinner, ValidationError },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    error: {
      type: [String, Boolean],
      default: "",
    },
    disabled: {
      type: Boolean,
    },
    verification: {
      type: Boolean,
      default: false,
    },
    showErrorMessage: {
      type: Boolean,
      default: true,
    },
    notice: {
      type: [String, Boolean],
      default: "",
    },
    clearStyles: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      id: null,
    }
  },
  created() {
    this.id = this.$utils.generateId()
  },
}
</script>

<style scoped lang="scss">
.input {
  &__container {
    position: relative;
  }

  &__label-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
  }

  &__label {
    font-weight: 600;
    letter-spacing: 0.5px;
    color: var(--text-grey);
  }

  &--clear .input__element {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }

  &__element {
    background: #ffffff;
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 16px;
    min-height: 50px;
    transition: border-color var(--transition-fast-duration);
    font-family: var(--font-primary);
    font-size: 14px;
    font-weight: 600;
    color: var(--text-color);
    width: 100%;
    letter-spacing: 0.5px;
    outline: none;
    cursor: text;

    &::placeholder {
      color: rgba(42, 42, 75, 0.5);
    }

    @media (max-width: 575px) {
      padding: 0 10px;
      min-height: 40px;
    }

    &:focus {
      border-color: var(--primary-color);
      box-shadow: 0px 2px 9px rgba(37, 84, 255, 0.11);

      & + .input__inherit-border {
        border-color: var(--primary-color);
      }
    }
  }

  &:not(.input--disabled):not(.input--error)
    .input__element:not(:focus):not(.input__element--focused) {
    &:hover {
      border-color: rgba(42, 42, 75, 0.3);
    }
  }

  &--disabled .input__element {
    cursor: default;
    border-color: rgba(42, 42, 75, 0.3);
  }

  input.input__element,
  select.input__element {
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    line-height: 20px;
  }

  &--size-small input.input__element,
  &--size-small .select .input__element {
    padding: 5px 8px;
    min-height: unset;
  }

  &--size-medium input.input__element,
  &--size-medium .select .input__element {
    min-height: 40px;
    padding: 9px 16px;
  }

  &__inherit-border {
    transition: border-color var(--transition-fast-duration);
    border-color: var(--border-color);
  }

  textarea.input__element {
    min-height: 157px;
    padding: 16px;
  }

  &--error {
    .input__element,
    .input__inherit-border {
      border-color: var(--color-red);
    }
  }

  &--verification {
    .input__element,
    .input__inherit-border {
      border-color: var(--primary-color);
    }
  }

  &__notice-container {
    margin-top: 2px;
    transition: var(--transition);
    display: flex;
    align-items: center;
    font-family: var(--font-secondary);
    font-weight: 500;
  }

  &__verification,
  &__notice {
    opacity: 0.7;
  }

  &__verification {
    display: flex;
    color: var(--primary-color);

    &-icon {
      opacity: 0.3;
      margin-right: 5px;
    }
  }

  &__notice {
    color: var(--text-grey);
  }

  [type="password"] {
    padding-right: 40px !important;
  }
}
</style>
