const namespaced = true
import Vue from "vue"

const actions = {
  /**
   * Create payout
   * @param context
   * @param payload{Object<organization_uid:string>}
   * @returns {Promise<AxiosResponse<any>>}
   */
  async createPayout(context, payload) {
    return await Vue.axios.post(`/payout/method/${payload.organization_uid}`, payload)
  },
  /**
   * Get payouts lists
   * @param context
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getPayouts(context, payload) {
    return await Vue.axios.get(`/payout/methods-list/${payload.organization_uid}`)
  },

  /**
   * Calculate payout
   * @returns {Promise<void>}
   */
  async calculatePayouts(context, payload) {
    return await Vue.axios.get(`/payout/calculate/${payload.organization_uid}`)
  },
  /**
   * Change calculation for external account
   * @param commit
   * @param payload
   */
}
export default {
  namespaced,
  actions,
}
