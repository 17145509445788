import Vue from "vue"
import i18n from "@/i18n"
import { isToday, isYesterday } from "../../utils/datetime"
import {
  NOTIFICATIONS,
  GROUPED_NOTIFICATIONS,
  GET_NOTIFICATIONS,
  SET_UNREAD_NOTIFICATION,
  DELETE_NOTIFICATION,
  SET_UNREAD_NOTIFICATION_COUNT,
  UNREAD_NOTIFICATION_COUNT,
  SET_READ_NOTIFICATIONS,
  SET_NOTIFICATIONS,
} from "./types"

const actions = {
  async [GET_NOTIFICATIONS]({ commit }) {
    const { data } = await Vue.$http.get("/notification/list")
    commit(SET_NOTIFICATIONS, data.items)
    commit(SET_UNREAD_NOTIFICATION_COUNT, data.items.filter((item) => !item.viewed).length)
  },
  async [DELETE_NOTIFICATION]({ commit }, { id }) {
    await Vue.$http.post(`/notification/${id}/archive`)
    commit(DELETE_NOTIFICATION, id)
  },
  async [SET_READ_NOTIFICATIONS]({ commit }, { ids }) {
    const promises = []
    ids.map((id) => {
      promises.push(Vue.$http.post(`/notification/${id}/read`))
    })
    await Promise.allSettled(promises)
    commit(SET_READ_NOTIFICATIONS, ids)
  },
  async [SET_UNREAD_NOTIFICATION]({ commit }, { id }) {
    await Vue.$http.post(`/notification/${id}/unread`)
    commit(SET_UNREAD_NOTIFICATION, id)
  },
}
const mutations = {
  [SET_NOTIFICATIONS](state, notifications) {
    state[NOTIFICATIONS] = notifications
  },
  [DELETE_NOTIFICATION](state, notificationId) {
    let unreadNotificationRemovedCount = 0
    state[NOTIFICATIONS] = state[NOTIFICATIONS].filter((nt) => {
      if (nt.unique_id === notificationId && !nt.viewed) {
        unreadNotificationRemovedCount++
      }
      return nt.unique_id !== notificationId
    })
    if (unreadNotificationRemovedCount) {
      state[UNREAD_NOTIFICATION_COUNT] = Math.max(
        0,
        state[UNREAD_NOTIFICATION_COUNT] - unreadNotificationRemovedCount
      )
    }
  },
  [SET_UNREAD_NOTIFICATION_COUNT](state, notificationCount) {
    state[UNREAD_NOTIFICATION_COUNT] = notificationCount
  },
  [SET_READ_NOTIFICATIONS](state, notificationIds) {
    let readCount = 0
    state[NOTIFICATIONS].forEach((notification) => {
      if (notificationIds.includes(notification.unique_id)) {
        notification.viewed = true
        readCount++
      }
    })
    state[UNREAD_NOTIFICATION_COUNT] = Math.max(0, state[UNREAD_NOTIFICATION_COUNT] - readCount)
  },
  [SET_UNREAD_NOTIFICATION](state, notificationId) {
    const notificationToUpdate = state[NOTIFICATIONS].find(
      (notification) => notificationId === notification.unique_id
    )
    if (notificationToUpdate) {
      notificationToUpdate.viewed = false
      state[UNREAD_NOTIFICATION_COUNT] += 1
    }
  },
}
const state = {
  [NOTIFICATIONS]: [],
  [UNREAD_NOTIFICATION_COUNT]: 0,
}
const getters = {
  [GROUPED_NOTIFICATIONS](state) {
    return state[NOTIFICATIONS].reduce((acc, nt) => {
      const isTodayGroup = isToday(nt.dt_create)
      const isYesterdayGroup = isYesterday(nt.dt_create)
      if (isTodayGroup) {
        acc[i18n.t("Today")] = acc[i18n.t("Today")] || []
        acc[i18n.t("Today")].push(nt)
      } else if (isYesterdayGroup) {
        acc[i18n.t("Yesterday")] = acc[i18n.t("Yesterday")] || []
        acc[i18n.t("Yesterday")].push(nt)
      } else {
        acc[i18n.t("Older")] = acc[i18n.t("Older")] || []
        acc[i18n.t("Older")].push(nt)
      }
      return acc
    }, {})
  },
}
export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
}
