<template>
  <div class="error-message">
    <svgicon name="cross-red" class="error-message__icon" />
    <span class="error-message__text text-color-warning text-headline6">
      {{ error }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.error-message {
  color: var(--color-red);
  opacity: 0.7;
  &__icon {
    font-size: 13px;
    margin-right: 3px;
  }
}
</style>