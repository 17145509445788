export default {
  namespaced: true,
  state: {
    alerts: {
      auth: [],
      main: [],
    },
  },
  getters: {
    getAlertsByType: (state) => (type) => {
      type = type || "main"
      return state.alerts[type]
    },
  },
  mutations: {
    addAlert(state, { alert, type }) {
      if (!state.alerts[type]) {
        return
      }
      if (alert.stacked === false) {
        state.alerts[type] = [alert]
      } else {
        state.alerts[type] = [...state.alerts[type], alert]
        if (state.alerts[type].length > 3) {
          state.alerts[type].shift()
        }
      }
    },
    deleteAlert(state, { alertToDelete, type }) {
      if (!state.alerts[type]) {
        return
      }
      state.alerts[type] = state.alerts[type].filter((alert) => alert !== alertToDelete)
    },
    clearAlerts(state, type) {
      console.log(state.alerts, "alerts")
      if (!state.alerts[type]) {
        return
      }
      state.alerts[type] = []
    },
  },
  actions: {
    addAlert({ commit }, { alert, type }) {
      commit("addAlert", { alert, type })
    },
    deleteAlert({ commit }, { alertToDelete, type }) {
      commit("deleteAlert", { alertToDelete, type })
    },
    clearAlerts({ commit }, { type }) {
      commit("clearAlerts", type)
    },
  },
}
