import Vue from "vue"
import { commit } from "lodash/seq"

const actions = {
  /**
   * Send cancel request for code
   * @param app
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  async sendContractCancelRequest(app, data) {
    return await Vue.axios.post(
      `/marketplace/cancel_subscription/${data.organization_uid}/${data.order_uid}`,
      data
    )
  },
  /**
   * Confirm request
   * @param app
   * @param data
   * @returns {Promise<*>}
   */
  async confirmCancelRequest(app, data) {
    console.log(data, "Data for sending")
    return await Vue.axios.post(
      `/marketplace/cancel_subscription/confirmation/${data.organization_uid}/${data.order_uid}`,
      data
    )
  },
  /**
   * Set datetime when request sent
   * @param commit
   * @param order_uid
   */
  setExternalRequestTimeSent({ commit }, order_uid) {
    const payload = {
      dateTime: new Date(),
      orderUid: order_uid,
    }
    commit("setExternalRequest", payload)
  },
  /**
   * Clear datetime when request sent
   * @param commit
   * @param order_uid
   */
  clearExternalRequestTimeSent({ commit }, order_uid) {
    const payload = {
      orderUid: order_uid,
    }
    commit("clearExternalRequest", payload)
  },
  /**
   * Get contract detail
   * @param app
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getContract(app, data) {
    return await Vue.axios.get(`/marketplace/order/${data.organization_uid}/${data.order_uid}`)
  },
  /**
   * Get contract
   * @param app
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getSimpleContract(app, data) {
    return await Vue.axios.get(
      `/marketplace/order/simple/${data.organization_uid}/${data.order_uid}`
    )
  },
  /**
   * Confirm payment for IP-block
   * @param app
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  ipBlockPaymentConfirm(app, payload) {
    return Vue.$http.post(`/marketplace/order/`, payload)
  },
  /**
   * Load details for ipBlock order
   * @param app
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  offerPaymentDetails(app, data) {
    return Vue.$http
      .post(`/marketplace/offer_overview/${data.organization_uid}`, data)
      .then((response) => response.data)
  },
  /**
   * Sync sybscription after payment
   * @param app
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  syncSubscription(app, data) {
    return Vue.$http
      .post(`/marketplace/order/subscription_finish`, data)
      .then((response) => response.data)
  },
  syncCheckout(app, data) {
    return Vue.$http
      .post(`/marketplace/checkout/${data.organization_uid}/${data.order_uid}/${data.session_id}`)
      .then((response) => response.data)
  },
  /**
   * Add item to order
   * @param commit
   * @param network
   * @param organisation_uid
   */
  async addNetworkToOrder({ commit }, { network, organization_uid }) {
    const payload = {
      cidr: network,
    }
    const { data } = await Vue.$http.post(`/marketplace/basket/${organization_uid}`, payload)
    commit("setOrderItems", { items: data.items })
  },
  /**
   * Remove item from orders
   * @param commit
   * @param network
   * @param organisation_uid
   */
  async removeNetworkFromOrder({ commit }, { network, organization_uid }) {
    const payload = {
      cidr: network,
    }
    const { data } = await Vue.$http.delete(`/marketplace/basket/${organization_uid}`, {
      data: payload,
    })
    commit("setOrderItems", { items: data.items })
  },
  /**
   * Clear order
   * @param commit
   * @param organisation_uid
   * @returns {Promise<void>}
   */
  async clearOrder({ commit }, { organisation_uid }) {
    const { data } = await Vue.$http.delete(`/marketplace/basket/clear/${organisation_uid}`)
    commit("setOrderItems", { items: data.items })
  },
  /**
   * Load details for ipBlock multi order
   * @param app
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  orderOverview(app, data) {
    return Vue.$http.post(`/marketplace/multi_overview`, data).then((response) => response.data)
  },
  orderList(app, data) {
    let size = 20
    try {
      size = data.params.size
    } catch {}
    let url = "/marketplace/order_list"
    if (size < 100) {
      url = url + `?size=${size}`
    }

    return Vue.$http.post(url, data).then((response) => response.data)
  },
  orderSum(app, data) {
    return Vue.$http.post(`/marketplace/order_sum`, data).then((response) => response.data)
  },
  /**
   * Fetch order items
   * @param app
   * @param organisation_uid
   * @returns {Promise<void>}
   */
  async fetchOrderItems(app, { organisation_uid }) {
    const { data } = await Vue.$http.get(`/marketplace/basket/${organisation_uid}`)
    app.commit("setOrderItems", { items: data.items })
  },
  /**
   * Networks details
   * @param app
   * @param organisation_uid
   * @param network
   * @returns {Promise<AxiosResponse<any>>}
   */
  async getNetworkDetails(app, { organisation_uid, network }) {
    return await Vue.axios.get(`/marketplace/overview/${organisation_uid}?cidr=${network}`)
  },
  /**
   * Remove item from orders
   * @param commit
   * @param network<Array>
   * @param organisation_uid
   */
  async removeNetworksFromOrder({ commit }, { networks, organization_uid }) {
    const payload = {
      items: networks,
    }
    const { data } = await Vue.$http.delete(`/marketplace/basket/batch/${organization_uid}`, {
      data: payload,
    })
    commit("setOrderItems", { items: data.items })
  },
  addFilters({ commit }, { name, values }) {
    commit("setFilters", { name: name, values: values })
  },
  addGroupedMarketplace({ commit }, { values }) {
    commit("setGroupedMarketplace", { values: values })
  },
}
const mutations = {
  setExternalRequest(state, params) {
    state.confirmCancelRequestSent[params.orderUid] = params.dateTime
  },
  clearExternalRequest(state, params) {
    delete state.confirmCancelRequestSent[params.orderUid]
  },
  addNetworkToOrder({ commit }, { network, organisation_uid }) {
    const data = localStorage.getItem("networks_orders")
      ? JSON.parse(localStorage.getItem("networks_orders"))
      : []
    data.push(network)
    localStorage.setItem("networks_orders", JSON.stringify(data))
    state.networksOrder = localStorage.getItem("networks_orders")
  },
  removeNetworkFromOrder({ commit }, { network, organisation_uid }) {
    const data = localStorage.getItem("networks_orders")
      ? JSON.parse(localStorage.getItem("networks_orders"))
      : []
    localStorage.setItem("networks_orders", JSON.stringify(data.filter((e) => e !== network)))
    state.networksOrder = localStorage.getItem("networks_orders")
  },
  removeNetworksFromOrder({ commit }, { networks, organisation_uid }) {
    const data = localStorage.getItem("networks_orders")
      ? JSON.parse(localStorage.getItem("networks_orders"))
      : []
    localStorage.setItem(
      "networks_orders",
      JSON.stringify(
        data.filter((e) => {
          return networks.indexOf(e) === -1
        })
      )
    )
    state.networksOrder = localStorage.getItem("networks_orders")
  },
  clearOrder({ commit }, { organisation_uid }) {
    localStorage.setItem("networks_orders", JSON.stringify([]))
    state.networksOrder = localStorage.getItem("networks_orders")
  },
  setOrderItems({ commit }, { items }) {
    state.networksOrder = items
  },
  setFilters({ commit }, { name, values }) {
    state.filters[name] = values
  },
  setGroupedMarketplace({ commit }, { values }) {
    state.groupedMarketplace = values
  },
}
const state = {
  confirmCancelRequestSent: {},
  networksOrder: [],
  filters: {
    country_iso: [],
    rir: [],
    properties: [],
    suppliers: [],
  },
  groupedMarketplace: [],
}
const getters = {
  confirmCancelRequestSent(state) {
    return state["confirmCancelRequestSent"]
  },
  networksOrder(state) {
    return state["networksOrder"]
  },
  getFilters(state) {
    return state.filters
  },
  getGroupedMarketplace(state) {
    return state.groupedMarketplace
  },
}
export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}
