/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'menu-down': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M5.833 8.333L10 12.5l4.167-4.167H5.833z" _fill="#1E1E3F" opacity=".5"/>'
  }
})
