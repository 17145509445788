export default {
  namespaced: true,
  state: {
    loading: false,
  },
  getters: {
    getLoading: (state) => () => {
      return state.loading
    },
  },
  mutations: {
    setLoading(state, value) {
      state.loading = value
    },
  },
  actions: {
    startLoading({ commit }) {
      commit("setLoading", true)
    },
    doneLoading({ commit }) {
      commit("setLoading", false)
    },
  },
}
