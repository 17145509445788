<template>
  <div
    class="user-avatar"
    :class="{
      'user-avatar--bordered': bordered,
      'user-avatar--active': isActive,
    }"
  >
    <app-avatar :src="src" v-bind="$attrs" />
  </div>
</template>

<script>
import AppAvatar from "@/components/ui/Avatar"
import defaultAvatar from "@/assets/img/defaultAvatar.jpeg"

export default {
  components: { AppAvatar },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    user() {
      return this.$auth.user()
    },
    src() {
      return this.user && this.user.avatar ? this.user.avatar : defaultAvatar
    },
  },
}
</script>

<style scoped lang="scss">
.user-avatar {
  border-radius: 50%;
  transition: var(--transition-fast);
  &--bordered {
    border: 2px solid transparent;
    &:hover,
    &.user-avatar--active {
      border-color: var(--light-violet);
    }
  }
}
</style>