import Vue from "vue"

const actions = {
  /**
   * Enable TFA
   * @param app
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  async enableTFA(app, data) {
    return await Vue.axios.post(`/auth/tfa_activate`, {
      two_factor: data.tfa_code,
    })
  },
}
export default {
  namespaced: true,
  actions,
}
