import Vue from "vue"
import store from "@/store"
import utils from "@/utils/functions"

const plugin = {}

plugin.install = function () {
  const alert = function (notificationOptions) {
    const newNotification =
      typeof notificationOptions === "string"
        ? {
            type: "success",
            message: notificationOptions,
            stacked: true,
          }
        : {
            ...notificationOptions,
          }
    newNotification.id = utils.generateId()
    if (!newNotification.type) {
      newNotification.type = "success"
    }

    store.dispatch("alerts/addAlert", {
      alert: newNotification,
      type: notificationOptions.layout || "main",
    })
  }
  Vue.alert = alert
  Vue.prototype.$alert = alert
}
Vue.use(plugin)

export default plugin
