<template>
  <div class="accordion">
    <div class="accordion__header" @click="onClick">
      <slot name="header" v-bind="{ isActive: localIsActive }" />
    </div>
    <div ref="content" class="accordion__content">
      <slot />
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap/dist/gsap"

export default {
  model: {
    prop: "isActive",
    event: "toggle",
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localIsActive: this.isActive,
    }
  },
  methods: {
    onClick() {
      if (!this.$refs.content || this.disabled) {
        return
      }
      if (this.localIsActive) {
        gsap.to(this.$refs.content, 0.2, {
          height: 0,
        })
      } else {
        gsap.to(this.$refs.content, 0.2, {
          height: this.$refs.content.scrollHeight,
        })
      }
      this.localIsActive = !this.localIsActive
      this.$emit("toggle", this.localIsActive)
    },
  },
}
</script>

<style scoped lang="scss">
.accordion {
  &__header {
    cursor: pointer;
  }
  &__content {
    height: 0;
    overflow: hidden;
  }
}
</style>