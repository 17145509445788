<template>
  <ul class="oauth">
    <li :class="{ disabled: getIsDisabled }" class="oauth__provider">
      <a href="javascript:void(0)" class="oauth__link" @click="loginWith('google')">
        <img :src="require('@/assets/svg/google.svg')" :alt="'Login with google'" />
        <span class="oauth__title">Google</span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "OAuth",
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      facebook: {
        client_id: this.$store.state.core.variables.VUE_APP_FACEBOOK_CLIENT_ID,
      },
      google: {
        client_id: this.$store.state.core.variables.VUE_APP_GOOGLE_CLIENT_ID,
        scope: "email profile",
      },
    }
  },
  computed: {
    getIsDisabled() {
      console.log(this.disabled, "disabled")
      return this.disabled === true
    },
    providers() {
      return {
        facebook: {
          client_id: this.$store.state.core.variables.VUE_APP_FACEBOOK_CLIENT_ID,
        },
        google: {
          client_id: this.$store.state.core.variables.VUE_APP_GOOGLE_CLIENT_ID,
          scope: "email profile",
        },
      }
    },
  },
  methods: {
    async loginWith(provider) {
      if (this.getIsDisabled) {
        return this.$emit("onDisabledClicked")
      }
      await this.$auth.oauth2(provider, {
        params: this.providers[provider],
        staySignedIn: true,
        fetchUser: true,
      })
    },
  },
}
</script>
