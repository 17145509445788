/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'block_view': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<rect x="3" y="3" width="7" height="7" rx="2" stroke="#2554FF" stroke-width="2"/><rect x="14" y="3" width="7" height="7" rx="2" stroke="#2554FF" stroke-width="2"/><rect x="14" y="14" width="7" height="7" rx="2" stroke="#2554FF" stroke-width="2"/><rect x="3" y="14" width="7" height="7" rx="2" stroke="#2554FF" stroke-width="2"/> _fill="#fff" />'
  }
})
