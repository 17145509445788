<template>
  <component :is="layoutComponent" id="app">
    <router-view />
  </component>
</template>

<script>
import BlankLayout from "@/layouts/Blank"
import MainLayout from "@/layouts/MainLayout"

export default {
  name: "App",
  computed: {
    layoutComponent() {
      const layoutName = this.$route.meta.layout
      if (layoutName === "blank") {
        return BlankLayout
      }
      if (layoutName === "main") {
        return MainLayout
      }
      return "div"
    },
  },
  async beforeCreate() {
    await this.$store.dispatch("core/loadVariables")
  },
  mounted() {
    const access = localStorage.getItem("auth_token_default")
    if (!access) {
      return
    }
    const token = access.split(";")[0]
    this.$sse
      .create(process.env.VUE_APP_API + `/sse/stream?token=${token}`)
      .on("message", (msg) => {
        const data = JSON.parse(msg)
        this.$store.dispatch("user/addSseEvent", {
          message: data,
          type: data.event,
        })
      })
      .on("error", (err) => console.error("Failed to parse or lost connection:", err))
      .connect()
      .catch((err) => console.error("Failed make initial connection:", err))
  },
}
</script>
