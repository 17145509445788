/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.961 2C6.459 2 1.994 6.48 1.994 12s4.465 10 9.967 10c5.502 0 9.967-4.48 9.967-10S17.463 2 11.961 2zM9.968 17l-4.984-5 1.405-1.41 3.579 3.58 7.565-7.59L18.938 8l-8.97 9z" _fill="#00C64F"/>'
  }
})
