import Vue from "vue"
import GetTextPlugin from "vue-gettext"
import translations from "../../translations/translations.json"

const allowedLanguages = ["en", "de", "ru"]
let defaultLanguage = localStorage.getItem("lang")
if (!defaultLanguage || !allowedLanguages.includes(defaultLanguage)) {
  defaultLanguage = "en"
}
Vue.use(GetTextPlugin, {
  translations,
  defaultLanguage,
  availableLanguages: {
    en: "English",
    de: "Deutsche",
    ru: "Русский",
  },
  silent: true,
})
