/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'google': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<path pid="0" d="M25.44 11.715h-.94v-.048H14v4.667h6.593A6.997 6.997 0 017 14a7 7 0 017-7c1.784 0 3.408.673 4.644 1.773l3.3-3.3A11.613 11.613 0 0014 2.333C7.557 2.333 2.333 7.558 2.333 14S7.557 25.667 14 25.667 25.667 20.443 25.667 14c0-.782-.08-1.546-.227-2.285z" _fill="#FBC02D"/><path pid="1" d="M3.678 8.57l3.834 2.811A6.997 6.997 0 0114 7.001c1.784 0 3.408.672 4.644 1.772l3.3-3.3A11.613 11.613 0 0014 2.333c-4.481 0-8.367 2.53-10.322 6.237z" _fill="#E53935"/><path pid="2" d="M14 25.667c3.014 0 5.752-1.154 7.822-3.03l-3.61-3.055A6.947 6.947 0 0114 21a6.997 6.997 0 01-6.582-4.635l-3.804 2.931c1.93 3.778 5.852 6.37 10.386 6.37z" _fill="#4CAF50"/><path pid="3" d="M25.44 11.715l-.01-.049H14v4.667h6.593a7.023 7.023 0 01-2.384 3.25l.002-.001 3.61 3.055c-.255.232 3.846-2.804 3.846-8.637 0-.782-.08-1.546-.227-2.285z" _fill="#1565C0"/>'
  }
})
