<template>
  <component
    :is="component"
    :disabled="disabled || loading"
    :type="component === 'button' ? type : false"
    class="button"
    :class="className"
    v-bind="$attrs"
    @click="click"
  >
    <span v-if="icon" class="button__icon">
      <svgicon :name="icon" />
    </span>
    <slot />
    <loading v-if="loading" class="button--loader"></loading>
  </component>
</template>

<script>
import loader from "@/assets/svg/loading.svg"
import Loading from "@/components/shared/Loading.vue"

export default {
  name: "AppButton",
  components: { Loading },
  props: {
    type: {
      type: String,
      default: "button",
      validator(type) {
        return ["button", "link", "external-link", "submit", "-link"].includes(type)
      },
    },
    variant: {
      type: String,
      default: "primary",
      validator(v) {
        return ["outlined", "primary", "text", "outlined-invert"].includes(v)
      },
    },
    size: {
      type: String,
      default: "",
      validator(v) {
        return ["tiny", "small", "normal", "medium", "large", "wide", ""].includes(v)
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    textTransform: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    preventClick: {
      type: Boolean,
      default: true,
    },
    hoverAnimated: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loader,
    }
  },
  computed: {
    component() {
      return this.type === "link" ? "router-link" : this.type === "external-link" ? "a" : "button"
    },
    className() {
      return {
        "button--loading": this.loading,
        [`button--variant-${this.variant}`]: !!this.variant,
        "button--has-icon": !!this.icon,
        [`button--size-${this.size}`]: !!this.size,
        [`button--transform-${this.textTransform}`]: !!this.textTransform,
        "button--hover--animated": this.hoverAnimated,
      }
    },
  },
  methods: {
    click(e) {
      if (this.preventClick) {
        e.preventDefault()
        return this.$emit("click")
      }
      return e
    },
  },
}
</script>


<style scoped lang="scss">
.button {
  transition: var(--transition-fast);
  border: none;
  text-transform: uppercase;
  border-radius: 4px;
  text-align: center;
  font-family: var(--font-primary);
  font-weight: 600;
  padding: 11px 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:not(:disabled) {
    cursor: pointer;
  }
  &--variant-outlined {
    background: transparent;
    font-weight: 600;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    &:hover:not(:disabled):not(:focus) {
      background: rgba(37, 84, 255, 0.2);
    }
  }
  &--variant-outlined-invert {
    background: transparent;
    font-weight: 600;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    &:hover:not(:disabled):not(:focus) {
      color: var(--text-white);
      background: var(--primary-color);
      border-color: transparent;
    }
  }
  &--variant-primary {
    color: #fff;
    background: var(--primary-color);

    &:disabled {
      color: rgba(255, 255, 255, 0.3);
    }

    &:hover {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12),
        0px 1px 5px rgba(0, 0, 0, 0.2);
    }
  }
  &--variant-text {
    background: transparent;
    color: var(--primary-color);
  }
  &--transform-default {
    text-transform: none;
  }
  &--has-icon {
    &.button--size-small {
      padding-top: 2px;
      padding-bottom: 2px;
    }
    &.button--size-normal {
      padding: 6px 12px;
    }
    &.button--size-tiny {
      padding-top: 0;
      padding-bottom: 0;
      &.flat {
        padding: 3px 4px;
        .button__icon {
          margin: 0px;
        }
      }
      &.small-icon {
        .button__icon {
          font-size: 14px;
        }
      }
    }
    &.button--size-large,
    &.button--size-wide {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  &--size-small {
    padding: 8px;
  }
  &--size-tiny {
    padding: 6px 16px;
    font-size: 10px;
  }
  &--size-large {
    padding: 18px 60px;
  }
  &--size-medium {
    padding: 13px 46px;
  }
  &--size-wide {
    width: 100%;
    padding: 18px 0px;
  }
  &--loader {
    position: absolute;
    right: 8px;
  }
  &__icon {
    margin-right: 5px;
    font-size: 24px;
  }
  &--loading {
    color: #ffffff;
    background: var(--primary-dark-color);
    &:hover {
      box-shadow: none;
      background: var(--primary-dark-color);
    }
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
  &--hover--animated {
    overflow: hidden;
    .button__icon {
      transition: transform var(--transition-fast-duration);
      transform: translateX(-35px);
    }
    .button__icon + span {
      transition: transform var(--transition-fast-duration);
      transform: translateX(-15px);
      margin-right: 5px;
    }
    &:hover {
      .button__icon {
        transform: translateX(0px);
      }
      .button__icon + span {
        transform: translateX(0px);
      }
    }
  }
}
</style>
