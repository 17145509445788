/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'send': {
    width: 24,
    height: 25,
    viewBox: '0 0 24 25',
    data: '<path pid="0" d="M2.01 21.5l20.99-9-20.99-9-.01 7 15 2-15 2 .01 7z" _fill="#1E1E3F"/>'
  }
})
