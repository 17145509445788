import Vue from "vue"
import store from "@/store"

const plugin = {}

plugin.install = function () {
  /**
   *
   * @param tfaOptions
   * @param tfaCallback
   */
  const confirmTfa = async function (tfaOptions, tfaCallback) {
    return await store.dispatch("core/openTFA", {
      tfaOptions: tfaOptions,
      tfaCallback: tfaCallback,
    })
  }
  Vue.confirmTfa = confirmTfa
  Vue.prototype.$confirmTfa = confirmTfa
}
Vue.use(plugin)

export default plugin
