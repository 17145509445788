import Vue from "vue"
import { WORKSPACES, GET_WORKSPACES, SET_WORKSPACES, WORKSPACE_ID } from "./types"
import { ORGANIZATION } from "../organizations/types"
const actions = {
  async [GET_WORKSPACES]({ commit }) {
    const { data } = await Vue.$http.get("/workspaces/list/owned")
    commit(SET_WORKSPACES, data)
  },
}
const mutations = {
  [SET_WORKSPACES](state, workspaces) {
    state[WORKSPACES] = workspaces
  },
}
const state = {
  [WORKSPACES]: [],
}
const getters = {
  [WORKSPACE_ID](state, __, ___, rootGetters) {
    const organization = rootGetters[`organizations/${ORGANIZATION}`]
    if (organization) {
      const workspace = state[WORKSPACES].find((workspace) => {
        return workspace.organization_uuid === organization.unique_id
      })
      return workspace ? workspace.unique_id : null
    }
    return null
  },
}
export default {
  actions,
  mutations,
  state,
  namespaced: true,
  getters,
}
