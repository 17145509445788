<template>
  <router-link
    :to="{ name: 'createOrganization' }"
    class="selector-button text-button"
    :class="{ [`selector-button--theme-${theme}`]: theme }"
  >
    <svgicon name="add" />
    {{ $t("Add new business account") }}
  </router-link>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      validator(theme) {
        return ["", "dark"].includes(theme)
      },
      default: "",
    },
  },
}
</script>

<style scoped lang="scss">
.selector-button {
  background: none;
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid currentColor;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 6px 16px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  &--theme-dark {
    color: var(--color-text);
    border-color: rgba(0, 0, 0, 0.5);
  }
}
</style>
