import axios from "axios"
import VueAxios from "vue-axios"
import Vue from "vue"
import Functions from "@/utils/functions"

const api = axios.create({
  baseURL: process.env.VUE_APP_API,
})
axios.interceptors.request.use(
  function (config) {
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)
api.interceptors.response.use(
  (response) => {
    return response
  },
  (error, response) => {
    let message_text =
      "We can't finish your request properly, please contact us and we will solve your problem"
    try {
      const status = error.response ? error.response.status : null
      const customErrors = [423]
      message_text = Functions.getFirstServerErrorText(error, message_text)
      if (status >= 400 && status < 500 && !customErrors.includes(status)) {
        Vue.alert({
          message: Vue.prototype.$gettext(message_text),
          type: "error",
          timer: 6000,
        })
      }
    } catch (e) {
      console.log(e)
    }
    return Promise.reject(error)
  }
)

Vue.use(VueAxios, api)
