import required from "vuelidate/lib/validators/required"
import email from "vuelidate/lib/validators/email"
import i18n from "../i18n"

export default {
  validations: {
    form: {
      code: { required },
      password: { required },
      password_repeat: { required },
      email: { required, email },
    },
  },
  data() {
    return {
      form: {
        code: "",
        password: "",
        password_repeat: "",
        email: "",
      },
      errors: {
        code: "",
        password: "",
        password_repeat: "",
        email: "",
      },
    }
  },

  methods: {
    cleanErrors() {
      this.errors = {
        code: "",
        password: "",
        password_repeat: "",
        email: "",
      }
    },
    formValid() {
      this.cleanErrors()
      this.$v.$touch()
      const form = this.$v.form
      if (!form.code.required) {
        this.errors.code = i18n.t("Field is required")
      }
      if (!form.password.required) {
        this.errors.password = i18n.t("Field is required")
      }
      if (!form.password_repeat.required) {
        this.errors.password_repeat = i18n.t("Field is required")
      }
      const allFilled =
        form.password_repeat.required && form.password.required && form.code.required

      if (allFilled && form.password_repeat.$model !== form.password.$model) {
        this.errors.password_repeat = i18n.t("Passwords is not match")
      }
      if (allFilled && !form.email.email && !form.email.required) {
        this.errors.email = i18n.t("Please check email on previous step")
      }
      const passwordsMatch = form.password_repeat.$model === form.password.$model
      console.log(passwordsMatch, "password match")
      return passwordsMatch && allFilled && form.email.email && form.email.required
    },
  },
}
