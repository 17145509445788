/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dashboard': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" _fill="#9090D9"/>'
  }
})
