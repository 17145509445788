<template>
  <div>
    <app-modal :is-opened="isOpened" :max-width="674" v-on="$listeners">
      <template #header>
        <div class="modal-box">
          <h3 class="text-headline1">
            {{ $gettext(headerText) }}
          </h3>
          <div class="description">
            <p>
              {{ $gettext(description) }}
            </p>
          </div>
        </div>
      </template>
      <template #default>
        <text-field
          v-if="commentEnabled"
          v-model="form.comment"
          :error="errors.comment"
          :limit="255"
          :label="commentLabel"
          :type="'textarea'"
        />
        <div class="buttons">
          <app-button :loading="loading" variant="primary" size="medium" @click="confirmed">
            {{ $gettext(confirmButtonName) }}
          </app-button>
          <app-button v-if="isCancelButton" variant="outlined" size="medium" @click="close()">
            {{ $gettext(cancelButtonName) }}
          </app-button>
        </div>
      </template>
    </app-modal>
  </div>
</template>

<script>
import AppModal from "@/components/ui/AppModal"
import AppButton from "@/components/ui/AppButton"
import withCurrentOrganization from "@/mixins/withCurrentOrganization"
import TextField from "@/components/ui/forms/TextField"
import requiredIf from "vuelidate/lib/validators/requiredIf"

export default {
  validations: {
    form: {
      comment: {
        required: requiredIf(function () {
          return this.commentRequired
        }),
      },
    },
  },
  components: { AppModal, AppButton, TextField },
  mixins: [withCurrentOrganization],
  model: {
    prop: "isOpened",
    event: "toggle",
  },
  props: {
    confirmData: {
      type: Object,
      required: false,
      default: null,
    },
    headerText: {
      type: String,
      required: false,
      default: "Confirm action",
    },
    description: {
      type: String,
      required: false,
      default: "Do you confirm?",
    },
    confirmButtonName: {
      type: String,
      required: false,
      default: "Confirm",
    },
    isCancelButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    cancelButtonName: {
      type: String,
      required: false,
      default: "Cancel",
    },
    commentEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    commentRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isOpened: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        comment: "",
      },
      errors: {
        comment: null,
      },
    }
  },
  computed: {
    commentLabel() {
      return this.commentRequired ? this.$t("Comment(required)") : this.$t("Comment(optional)")
    },
  },
  watch: {
    isOpened: function (newVal, oldVal) {
      this.form.comment = ""
      this.errors = {
        comment: null,
      }
    },
  },
  mounted() {
    console.log("mounted")
    this.form.comment = ""
  },
  methods: {
    close() {
      return this.$emit("toggle", false)
    },
    confirmed() {
      this.$v.$touch()
      const form = this.$v.form
      const data = this.form
      if (form.$invalid) {
        return (this.errors = this.$utils.getClientValidationErrors(this.$v.form))
      }
      return this.$emit("confirmed", { ...this.confirmData, ...{ form: data } })
    },
  },
}
</script>

<style scoped lang="scss">
.buttons {
  display: flex;
  gap: 10px; /* Adjust this value to set the desired distance between buttons */
  margin-top: 20px;
}
.description {
  margin: 15px 0px;
  font-size: 16px;
  line-height: 1.2;
  p {
    margin-bottom: 15px;
  }
  .network-chip {
    background: var(--light-grey);
    padding: 3px 8px;
    border-radius: 3px;
    margin: 0px 5px 5px 0px;
    color: var(--text-color);
    font-size: 13px;
  }
}
</style>
