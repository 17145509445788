const namespaced = true
import Vue from "vue"

const actions = {
  /**
   * Change payment method for subscription
   * @param context
   * @param payload{Object<organization_uid:string>}
   * @returns {Promise<AxiosResponse<any>>}
   */
  async requestChangePayment(context, payload) {
    return await Vue.axios.post(
      `/marketplace/change_card/confirmation/${payload.organization_uid}/${payload.order_uid}`,
      payload
    )
  },
}
export default {
  namespaced,
  actions,
}
