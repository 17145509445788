import Vue from "vue"
import {
  ORGANIZATIONS,
  ORGANIZATION,
  GET_ORGANIZATIONS,
  SET_ORGANIZATIONS,
  SET_ORGANIZATION,
  ORGANIZATION_ID,
} from "./types"

const actions = {
  async [GET_ORGANIZATIONS]({ commit, state }) {
    const { data: organizations } = await Vue.axios.get("/organizations/")
    commit(SET_ORGANIZATIONS, organizations)
    if (!state[ORGANIZATION]) {
      const savedOrganizationId = localStorage.getItem(ORGANIZATION_ID)
      const hasSavedOrganizationInList = !!organizations.find(
        (org) => org.unique_id === savedOrganizationId
      )
      const firstOrganizationId = organizations[0] ? organizations[0].unique_id : null
      const selectedOrganizationId = hasSavedOrganizationInList
        ? savedOrganizationId
        : firstOrganizationId
      commit(SET_ORGANIZATION, selectedOrganizationId)
    }
    return organizations
  },
  [SET_ORGANIZATION]({ commit }, organizationId) {
    if (organizationId) {
      commit(SET_ORGANIZATION, organizationId)
      localStorage.setItem(ORGANIZATION_ID, organizationId)
    }
  },
  async getOrganization(app, uid) {
    return await Vue.axios.get(`/organizations/${uid}`)
  },
}
const mutations = {
  [SET_ORGANIZATIONS](state, organizations) {
    state[ORGANIZATIONS] = organizations
  },
  [SET_ORGANIZATION](state, organizationId) {
    state[ORGANIZATION_ID] = organizationId
  },
}
const state = {
  [ORGANIZATIONS]: null,
  [ORGANIZATION_ID]: null,
}
const getters = {
  [ORGANIZATION](state) {
    if (!state[ORGANIZATION_ID]) {
      return null
    }
    return state[ORGANIZATIONS].find((org) => org.unique_id === state[ORGANIZATION_ID])
  },
}
export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
}
