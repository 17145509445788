<template>
  <div>
    <content-loader
      view-box="0 0 300 200"
      :speed="1"
      primary-color="#f3f3f3"
      secondary-color="#ecebeb"
    >
      <rect
        v-for="i in contentLines"
        :key="'rect-content-loaded' + i"
        x="0"
        :y="(i - 1) * getGap"
        rx="3"
        ry="3"
        width="100%"
        :height="height"
      />
    </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader"
export default {
  name: "LinePreloader",
  components: { ContentLoader },
  props: {
    contentLines: {
      type: Number,
      default: 5,
    },
    height: {
      type: Number,
      default: 7,
    },
  },
  computed: {
    getGap() {
      return this.height * 2
    },
  },
}
</script>
