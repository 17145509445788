/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'linkedin': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M28 24.667A3.333 3.333 0 0124.667 28H7.333A3.333 3.333 0 014 24.667V7.333A3.333 3.333 0 017.333 4h17.334A3.333 3.333 0 0128 7.333v17.334z" _fill="#0288D1"/><path pid="1" d="M8 12.667h3.333V24H8V12.667zm1.657-1.334h-.019C8.643 11.333 8 10.592 8 9.666 8 8.72 8.663 8 9.676 8c1.014 0 1.639.72 1.657 1.666 0 .925-.643 1.667-1.676 1.667zM24 24h-3.333v-6.066c0-1.465-.817-2.465-2.128-2.465-1.001 0-1.542.674-1.805 1.326-.096.234-.067.879-.067 1.205v6h-3.334V12.667h3.334v1.744c.48-.744 1.233-1.744 3.158-1.744 2.386 0 4.174 1.5 4.174 4.849L24 24z" _fill="#fff"/>'
  }
})
