/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'list_view': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<rect x="3" y="3" width="18" height="4" rx="1" stroke="#2554FF" stroke-width="2"/><rect x="3" y="10" width="18" height="4" rx="1" stroke="#2554FF" stroke-width="2"/><rect x="3" y="17" width="18" height="4" rx="1" stroke="#2554FF" stroke-width="2"/>/>'
  }
})
