export default {
    getCountry(row, $route, source) {
      if (row.ipConfidence) {
        return row.ipConfidence
      }
      const geoFiltered = $route.query.country_iso
      const geo = row.ip_geo.filter((item) => source === item.source)
      if (geo) {
        row.ipConfidence = geoFiltered ? geo.filter((item) => geoFiltered === item.iso)[0] : geo[0]
        if (!row.ipConfidence) {
          row.ipConfidence = geo[0]
        }
        if (!row.ipConfidence) {
          return row.ipConfidence
        }
        const total = geo.reduce((partialSum, a) => partialSum + Number(a.ips_count), 0)
        row.ipConfidence.percentage = Math.round((100 / total) * row.ipConfidence.ips_count)
      }
      return row.ipConfidence
    },
}