import Vue from "vue"
import vClickOutside from "./v-click-outside"
import vRepeatClick from "./v-repeat-click"
import vPreventBackgroundScroll from "./v-prevent-background-scroll"
import { facade } from "vue-input-facade"

Vue.directive("mask", facade)
Vue.directive("click-outside", vClickOutside)
Vue.directive("repeat-click", vRepeatClick)
Vue.directive("prevent-background-scroll", vPreventBackgroundScroll)
