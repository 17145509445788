/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'warning_triangle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" _fill="#EE1B4B"/>'
  }
})
