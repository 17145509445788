<template>
  <div class="button-container">
    <app-dropdown :dropdown="subLinks.length > 0 || showDropdown">
      <template #default="{ isOpened }">
        <div
          class="button-container__holder-buttons"
          :class="{
            'button-container__holder-buttons--opened': isOpened,
            'button-container__holder-buttons--single': subLinks.length === 0,
          }"
        >
          <span class="button-container__holder-buttons-button-title" @click="buttonHandler">
            <svgicon :name="buttonIcon" class="icon" />
            <span class="title">
              {{ buttonTitle }}
            </span>
          </span>
          <span
            v-if="subLinks.length || showDropdown"
            class="button-container__holder-buttons-button-icon"
          >
            <svgicon name="arrow_drop_down" />
          </span>
        </div>
      </template>
      <template #content>
        <ul v-if="subLinks.length" class="button-container__holder-content">
          <li v-for="link in subLinks" :key="'key-to' + link.to">
            <router-link :to="{ name: link.to }">
              {{ link.title }}
            </router-link>
          </li>
        </ul>
      </template>
    </app-dropdown>
  </div>
</template>

<script>
import AppDropdown from "@/components/ui/Dropdown"

export default {
  name: "HeaderButton",
  components: {
    AppDropdown,
  },
  props: {
    buttonTitle: {
      type: String,
      required: true,
    },
    buttonIcon: {
      type: String,
      required: true,
    },
    subLinks: {
      type: Array,
      required: true,
    },
    rootLink: {
      type: [String, Object],
      default: null,
    },
    showDropdown: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    buttonHandler(e) {
      if (this.subLinks.length) {
        return e
      }
      return this.$router.push(this.rootLink)
    },
  },
}
</script>

<style scoped lang="scss">
.button-container {
  display: flex;
  &__holder-buttons {
    color: var(--text-white);
    background-color: rgba(46, 46, 99, 1);
    width: 180px;
    padding: 17px 0px 17px 0px;
    justify-content: center;
    display: flex;
    align-items: center;

    &--opened {
      .button-container__holder-buttons-button-icon {
        .svg-icon {
          transform: rotate(180deg);
        }
      }
    }
    &--single {
      padding: 20px 0px 20px 0px;
      .dropdown__content {
        display: none;
      }
    }

    &-button-title {
      .icon {
        color: var(--icons-color);
        font-size: 20px;
      }
    }

    &-button-icon {
      .svg-icon {
        transition: var(--transition-fast);
        color: rgba(255, 255, 255, 0.3);
        font-size: 26px;
        margin-left: 5px;
      }
    }
  }

  &__holder-content {
    background: var(--text-white);
    display: block;
    width: 200px;

    li {
      border-bottom: 1px solid var(--border-color);

      &:last-child {
        border-bottom-color: transparent;
      }

      a {
        padding: 10px 13px;
        display: block;
        border: none;
        color: var(--text-grey);
        font-family: var(--font-primary);
        font-size: 12px;

        &:hover {
          border: none;
          color: var(--text-color);
        }
      }
    }
  }
}
</style>
