/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'pencil': {
    width: 24,
    height: 25,
    viewBox: '0 0 24 25',
    data: '<path pid="0" d="M3 17.75v3.75h3.75l11.06-11.06-3.75-3.75L3 17.75zM20.71 7.54a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" _fill="#fff"/>'
  }
})
