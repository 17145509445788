import Vue from 'vue';
import VueRouter from 'vue-router';
import {routes} from "@/router/routes";

Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ selector: to.hash, behavior: 'smooth' })
        }, 500)
      })
    }
    return {
      x: 0,
      y: 0,
    };
  },
  mode: 'history',
  routes,
});
Vue.router = router;
export default router;
